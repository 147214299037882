import { HttpClient } from '@angular/common/http';
import { ConstantPool } from '@angular/compiler';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LocationService {

    constructor(private httpClient: HttpClient) {}

    getLocation(callback: (position) => void, errorCallback: (message: string) => void) {
        if (window.navigator && window.navigator.geolocation) {
            console.log('window.navigator && window.navigator.geolocation: true');
            window.navigator.geolocation.getCurrentPosition(
                (position) => {
                    callback(position);
                },
                (error) => {
                    alert('לשימוש במיקום העצמי יש להכנס להגדרות המכשיר ולאפשר את השימוש במיקום העצמי לדפדפן האינטרנט.'
                    + '\n'
                    + '\n'
                    + ' סיבת השגיאה:'
                    + '\n'
                    + error.message);
                    errorCallback(error.message);
                }
            );
        } else {
            errorCallback('Unsupported Browser');
        }
    }

    watchLocation(callback: (position) => void, errorCallback: (message: string) => void): number {
        if (window.navigator && window.navigator.geolocation) {
            return window.navigator.geolocation.watchPosition(
                (position) => {
                    callback(position);
                },
                (error) => errorCallback(error.message)
            );
        } else {
            errorCallback('Unsupported Browser');
            return -1;
        }
    }

    clearLocationWatcher(watcherId: number) {
        if (window?.navigator && window?.navigator?.geolocation && watcherId) {
            window.navigator.geolocation.clearWatch(watcherId);
        }
    }

    watchCompassHeading(callback: (angle: number) => void, errorCallback: (message: string) => void) {
        // compass listener (also ios13+ fallback at "compassPremissioniOS13" funtion)
        if (typeof DeviceOrientationEvent?.requestPermission !== 'function') {
            const deviceOrientationLestener = (event) => {
                // tslint:disable-next-line:no-string-literal
                if (this.isIOS() && event['webkitCompassHeading']) {
                    // tslint:disable-next-line:no-string-literal
                    callback(event['webkitCompassHeading']);
                } else {
                    if (event.absolute) {
                        callback(360 - event.alpha);
                    } else {
                        window.removeEventListener('deviceorientation', deviceOrientationLestener);
                        window.addEventListener('deviceorientationabsolute', (eventB) => {
                            callback(360 - eventB?.alpha);
                        }, true);
                    }
                }
            };
            window.addEventListener('deviceorientation', deviceOrientationLestener);
        }
        // if (window.DeviceOrientationEvent) {
        //     const compassListener = event => { callback(manageCompass(event)); };
        //     window.addEventListener('deviceorientation', compassListener);
        // }
    }

    compassPremissionIOS13(callback: (angle: number) => void, errorCallback: (message: string) => void) {
        if (navigator.geolocation) {
            if (typeof DeviceOrientationEvent?.requestPermission === 'function') {
                DeviceOrientationEvent?.requestPermission()
                    .then(permissionState => {
                        if (permissionState === 'granted') {
                            window.addEventListener('deviceorientation', (event) => {
                                // tslint:disable-next-line:no-string-literal
                                callback(event['webkitCompassHeading']);
                            });
                        } else {
                            errorCallback('User declined');
                        }
                    })
                    .catch(errorCallback);
            }
        } else {
            // alert('deviceorientation is not supported by this browser.');
            errorCallback('deviceorientation = null ("deviceorientation is not supported by this browser.")');
        }
    }

    isIOS() {
        return ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform)
            || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
    }

    isAndroid() {
        return ['Android'].includes(navigator.platform)
            || navigator.userAgent.includes('Android');
    }

}

function manageCompass(event): number {
    return event?.webkitCompassHeading ? event.webkitCompassHeading : 360 - event.alpha;
}
