<div id="wrapper">
  <button mat-icon-button id="export-nav" class="mat-elevation-z3 display-right"
          (click)="exportNav()">
    <img src="assets/img/exportNav@3x.png" class="button-image">
  </button>
  <div id="inner-wrapper" fxLayout="column" fxLayoutAlign="start start">
    
    <div id="title" fxFlex="43px" fxLayout="column" fxLayoutAlign="start center">
      <div id="title-line" fxFlexOffset="5px"></div>
      <div id="title-text"><div id="title-text-inner">{{(defi$ | async)?.locationName}}</div></div>
    </div>

    <div id="info-block" fxFlexOffset="12px" fxLayout>
      <!-- RIGHT COLUMN -->
      <div id="right-column" fxFlex="62%" fxFlexOffset="12px" fxLayout="column">
        <div id="step-box">
          <div class="title-text" *ngIf="!!(defi$ | async)?.locationDescription && (defi$ | async)?.locationDescription != null">תיאור מילולי של המיקום:</div>
          <div class="info-text" *ngIf="!!(defi$ | async)?.locationDescription && (defi$ | async)?.locationDescription != null">{{(defi$ | async)?.locationDescription}}</div>
          <div class="title-text" *ngIf="!!(isAddress$ | async)">כתובת:</div>
          <div class="info-text" *ngIf="!!(isAddress$ | async)">{{(addressStr$ | async) || ""}}</div>
          <div class="info-text" *ngIf="!!(floorStr$ | async) && (floorStr$ | async) !== null">
              קומה: {{(floorStr$ | async) || ""}}
          </div>
          <div class="title-text" *ngIf="!!(contactStr$ | async) && (contactStr$ | async) != '' ">איש קשר:</div>
          <div id="contact" class="info-text" *ngIf="!!(contactStr$ | async) && (contactStr$ | async) != '' ">
            <a [href]="'tel:' + (defi$ | async)?.contactPhone">{{(contactStr$ | async) || ""}}</a>
          </div>
          <div class="title-text" *ngIf="!!(locationHoursStr$ | async) && (locationHoursStr$ | async) != ''">שעות פתיחת המתחם:</div>
          <div class="info-text" *ngIf="!!(locationHoursStr$ | async) && (locationHoursStr$ | async) != ''">{{(locationHoursStr$ | async) || ""}}</div>
        </div>

      </div>

      <!-- LEFT COLUMN-->
      <div id="left-column" fxFlex="38%" fxFlexOffset="4px" fxLayout="column" fxLayoutAlign="start end">
        <div class="title-text" fxFlexAlign="end center">{{(idShorten$ | async) || ""}}   -   {{(defi$ | async)?.updatedAt?.seconds * 1000 | date : 'd.M.yy' }}</div>
        <div id="defi-image" (click)="openImage()" fxFlexOffset="5px">
          <div *ngIf="(defi$ | async)?.imageURL && (defi$ | async)?.imageURL!==''" style="height: 100%; width: 100%;">
            <img id="enlarge-icon" src="assets/img/enlarge.svg">
            <img id="logo-image" [src]="(defi$ | async)?.imageURL"><br>
          </div>
          <img *ngIf="!(defi$ | async)?.imageURL || (defi$ | async)?.imageURL===''" src="assets/img/mask-img.svg"
               class="Mask">

        </div>
      </div>
    </div>

  </div>
</div>
