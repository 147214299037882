import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector, Selector
} from '@ngrx/store';
import {MapState, MapStyle} from './map.state';
import {Message} from '../../../messages/message.model';
import {MAP_STORE_KEY} from '../consts/map.constants';
import {Defi} from '../../../core/firestore/interfaces/firestore.interfaces';
import {LngLatBounds} from 'mapbox-gl';

// map selectors helpers
const getMapStyle = (state: MapState): MapStyle => state.mapStyle;

const getMapBounds = (state: MapState): LngLatBounds => state.mapBounds;

const getMapPitch = (state: MapState): number => state.mapPitch;

const getMapBearing = (state: MapState): number => state.mapBearing;

const getMapZoom = (state: MapState): number => state.mapZoom;

const getMapPan = (state: MapState): number[] => state.mapPan;

const getSelectedDefi = (state: MapState): Defi => state.selectedDefi;

const getIsSidenamOpen = (state: MapState): boolean => state.isSidenavOpen;

const getIsOverlayOpen = (state: MapState): boolean => state.isOverlayOpen;

const getCentralizeCurrentLocation = (state: MapState): boolean => state.centralizeCurrentLocation;

const getIsMobile = (state: MapState): boolean => state.isMobile;

const getMapLocation = (state: MapState): { latitude: number; longitude: number} => state.mapLocation;

const getMapLoading = (state: MapState): boolean => state.mapLoading;

const getMapSuccess = (state: MapState): Message => state.mapSuccess;

const getMapError = (state: MapState): Message => state.mapError;

// map feature selectors
export const selectMapStateFeatureState: MemoizedSelector<
  object,
  MapState
  > = createFeatureSelector<MapState>(MAP_STORE_KEY);

// map selectors
export const selectSelectedDefi: MemoizedSelector<
    object,
    Defi
    > = createSelector(
    selectMapStateFeatureState,
    getSelectedDefi
);

export const selectIsSidenavOpen: Selector<
    object,
    boolean
    > = createSelector(
    selectMapStateFeatureState,
    getIsSidenamOpen
);

export const selectIsOverlayOpen: MemoizedSelector<
    object,
    boolean
    > = createSelector(
    selectMapStateFeatureState,
    getIsOverlayOpen
);

export const selectCentralizeCurrentLocation: MemoizedSelector<
    object,
    boolean
    > = createSelector(
    selectMapStateFeatureState,
    getCentralizeCurrentLocation
);

export const selectIsMobile: MemoizedSelector<
    object,
    boolean
    > = createSelector(
    selectMapStateFeatureState,
    getIsMobile
);

export const selectMapsStyle: MemoizedSelector<
    object,
    MapStyle
    > = createSelector(
    selectMapStateFeatureState,
    getMapStyle
);

export const selectMapBounds: MemoizedSelector<
    object,
    LngLatBounds
    > = createSelector(
    selectMapStateFeatureState,
    getMapBounds
);

export const selectMapPitch: MemoizedSelector<
    object,
    number
    > = createSelector(
    selectMapStateFeatureState,
    getMapPitch
);

export const selectMapBearing: MemoizedSelector<
    object,
    number
    > = createSelector(
    selectMapStateFeatureState,
    getMapBearing
);

export const selectMapZoom: MemoizedSelector<
    object,
    number
    > = createSelector(
    selectMapStateFeatureState,
    getMapZoom
);

export const selectMapPan: MemoizedSelector<
    object,
    number[]
    > = createSelector(
    selectMapStateFeatureState,
    getMapPan
);

export const selectMapLocation: MemoizedSelector<
    object,
    { latitude: number; longitude: number}
    > = createSelector(
    selectMapStateFeatureState,
    getMapLocation
);

export const selectMapLoading: MemoizedSelector<
    object,
    boolean
    > = createSelector(
    selectMapStateFeatureState,
    getMapLoading
);

export const selectMapSuccess: MemoizedSelector<
  object,
  Message
  > = createSelector(
  selectMapStateFeatureState,
  getMapSuccess
);

export const selectMapError: MemoizedSelector<
  object,
  Message
  > = createSelector(
  selectMapStateFeatureState,
  getMapError
);
