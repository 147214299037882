<div id="scroller" [ngClass]="{'desktop-scroller': !(isMobile$ | async)}">
  <div id="wrapper" fxLayout="column" [dir]="direction">

    <!-- TITLE -->
    <div id="title" fxLayout="column" fxLayoutAlign="center center">
      <!--close button-->
      <button
              mat-icon-button
              id="closeButton"
              class="mat-elevation-z1"
              (click)="router.navigate(['/map'])"
              *ngIf="(isMobile$ | async)">
        <img src="assets/img/close.svg" class="button-image">
      </button>
      <div id="title-text" [ngStyle]="{'font-size' : lang === 'he' ? '40px' : '30px'}" *ngIf="isMobile$ | async">
        {{titleText}}
      </div>
      <button mat-button id="lomda" (click)="openExternalLink('https://www.mdais.org/Files/Book/MADA_Defibrillator/story.html')" >
        <!-- <img src="assets/img/amitai.png" alt="" > -->
        לומדת שימוש בדפי
        <!-- <div id="newTag">חדש!</div> -->
      </button>
      <div id="lang-buttons" fxLayout dir="rtl">
        <button class="lang-button" [class.selectedBG]="lang === 'he'" mat-button (click)="setLang('he')">עב</button>
        <button class="lang-button" [class.selectedBG]="lang === 'en'" mat-button (click)="setLang('en')">En</button>
        <button class="lang-button" [class.selectedBG]="lang === 'ar'" mat-button (click)="setLang('ar')">ع</button>
        <button class="lang-button" [class.selectedBG]="lang === 'ru'" mat-button (click)="setLang('ru')">Py</button>
      </div>
    </div>

    <!-- GENERAL-->
    <div id="general">
      <div id="general-text">
        {{generalText}}
      </div>
    </div>

    <!-- WARNING -->
    <div id="warning">
      <div id="warning-text" fxLayout="column">
        <div>{{warningText1}}</div>
        <div>{{warningText2}}</div>
      </div>
    </div>

    <!-- INSTRUCTIONS -->
    <div id="instructions" fxLayout="column">
      <div id="instructions-text">{{instructionsTitle}}</div>

      <!-- Instructions 1-->
      <div id="instructions-1" fxLayout fxFlexOffset="10px">
        <div class="instruction-number" fxFlex="10%" fxLayout fxLayoutAlign="center center">
          <div>1</div>
        </div>
        <div class="instruction-detail" fxFlexOffset="12px" fxLayout="column" fxLayoutAlign="center start">
          <div fxFlexOffset="5px" class="instruction-detail-title">{{instructionDetailTitle1}}</div>
          <div fxFlexOffset="5px" class="instruction-line">{{instructionDetail1_line1}}</div>
          <div fxFlexOffset="6px" class="instruction-line">{{instructionDetail1_line2}}</div>
          <div fxFlexOffset="6px" class="instruction-line">{{instructionDetail1_line3}}</div>
          <div fxFlexOffset="5px"></div>
        </div>
        <span class="spacer" fxHide.lt-md></span>
        <img class="instruction-image" fxHide.lt-md src="assets/img/guide-1.png" alt="">

      </div>
      <span class="spacer"></span>
      <img class="instruction-image" fxFlexOffset="12px" fxHide.gt-sm fxFlex="100%" src="assets/img/guide-1.png" alt="">

      <!-- Instructions 2-->
      <div id="instructions-2" fxLayout fxFlexOffset="10px">
        <div class="instruction-number" fxFlex="12%" fxLayout fxLayoutAlign="center center">
          <div>2</div>
        </div>
        <div class="instruction-detail" fxFlexOffset="12px" fxLayout="column" fxLayoutAlign="center start">
          <div fxFlexOffset="5px" class="instruction-detail-title">{{instructionDetailTitle2}}</div>
          <div fxFlexOffset="5px" class="instruction-line">{{instructionDetail2_line1}}</div>
          <div fxFlexOffset="6px" class="instruction-line">{{instructionDetail2_line2}}</div>
          <div fxFlexOffset="6px" class="instruction-line">{{instructionDetail2_line3}}</div>
          <div fxFlexOffset="5px"></div>
        </div>
        <span class="spacer" fxHide.lt-md></span>
        <img class="instruction-image" fxHide.lt-md src="assets/img/guide-2.png" alt="">

      </div>
      <span class="spacer"></span>
      <img class="instruction-image" fxFlexOffset="12px" fxHide.gt-sm fxFlex="100%" src="assets/img/guide-2.png" alt="">

      <!-- Instructions 3-->
      <div id="instructions-3" fxLayout fxFlexOffset="10px">
        <div class="instruction-number" fxFlex="12%" fxLayout fxLayoutAlign="center center">
          <div>3</div>
        </div>
        <div class="instruction-detail" fxFlexOffset="12px" fxLayout="column" fxLayoutAlign="center start">
          <div fxFlexOffset="5px" class="instruction-detail-title">{{instructionDetailTitle3}}</div>
          <div fxFlexOffset="5px" class="instruction-line">{{instructionDetail3_line1}}</div>
          <div fxFlexOffset="6px" class="instruction-line">{{instructionDetail3_line2}}</div>
          <div fxFlexOffset="5px"></div>
        </div>
        <span class="spacer" fxHide.lt-md></span>
        <img class="instruction-image" fxHide.lt-md src="assets/img/guide-3.png" alt="">

      </div>
      <span class="spacer"></span>
      <img class="instruction-image" fxFlexOffset="12px" fxHide.gt-sm fxFlex="100%" src="assets/img/guide-3.png" alt="">

    </div>

    <!-- INFO Bottom -->
    <div id="info" fxFlexOffset="20px">
      <div id="info-text" fxLayout="column">
        <div>{{infoText}}</div>
      </div>
    </div>

    <!-- Safety -->
    <div id="safety" fxLayout="column" *ngIf="lang === 'he'"
      [ngClass]="{
        'safety-wrapper': !(isMobile$ | async),
        'safety-wrapper-mobile': (isMobile$ | async)
      }">
      <div id="safety-title" fxFlexOffset="12px">{{safetyTitle}}</div>
      <div id="safety-body" fxLayout fxFlexOffset="10px">
        <div class="safety-detail" fxFlexOffset="12px" fxLayout="column">
          <div fxFlexOffset="5px" class="safety-line">{{safety_line1}}</div>
          <div fxFlexOffset="6px" class="safety-line">{{safety_line2}}</div>
          <div fxFlexOffset="6px" class="safety-line">{{safety_line3}}</div>
          <div fxFlexOffset="6px" class="safety-line">{{safety_line4}}</div>
          <div fxFlexOffset="12px"></div>
        </div>
      </div>
    </div>

    <div id="footer" fxFlex="30px" fxLayout="row" fxLayoutAlign="start center" dir="rtl">
      <div id="footer-text" fxFlex="150px" fxFlexOffset="12px">איור: דור הלפרין</div>
      <div id="seperator" fxFlex="100"></div>
      <img id="footer-logo" fxFlex="100px" src="assets/img/defi-logo-small.svg">

    </div>
  </div>
</div>
