<div id="nav1-bar" class="mat-elevation-z3" dir="rtl" div fxLayout="row">

    <!-- call contact -->
    <div #callButton matRipple class="mat-icon-button button" fxFlex="70px" fxLayout="row" fxLayoutAlign="center center" dir="rtl"
        (click)="callNav()">
        <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">
            <g fill="none" fill-rule="evenodd">
                <g>
                    <path fill="#ddd" fill-rule="nonzero"
                        d="M34.294 33.622c-.026.858 1.274.897 1.3.026l.065-2.171c.007-.316.034-.564.128-.773l2.51 3.308c.482.65 1.457-.091.963-.741l-1.262-1.663c.669-.273 1.139-.585 1.3-1.821l.143-1.144c.104-.858-1.183-1.014-1.3-.156l-.064.403c-.075.607.004 1.863-.494 2.171l-2.172-2.86c-.48-.637-1.456.09-.962.74l.876 1.155c-.36.163-.935.416-.967 1.355l-.064 2.17zm-2.535-2.782c0 .87 1.3.87 1.3 0v-2.275c0-.871-1.3-.871-1.3 0v2.275zm-5.032 3.445c1.782.078 3.055-1.092 3.367-2.587l.65-2.99c.183-.832-1.066-1.105-1.235-.273l-.65 3.003h-.013c-.181.923-.91 1.768-2.067 1.716H25.57l-.12-1.39c.598.25 1.345.497 1.797-.196.235-.325.442-.845.794-2.886.155-.858-1.131-1.08-1.274-.234-.3 1.65-.403 2.119-.585 2.379 0 .013-.013.013-.013.026-.19.295-.51.256-.783.168l-.218-2.521c-.078-.858-1.378-.741-1.3.13l.455 5.005c.038.325.299.572.623.585l1.781.065zM13.701 35.78c0 .87 1.3.87 1.3 0v-6.656h2.691l-.35 3.263c-.066.546-.377.715-.793.949-.611.325-.117 1.235.494.91.754-.403 1.416-.663 1.56-1.69v-.026l.377-3.835c.038-.377-.274-.715-.65-.715h-3.978c-.364 0-.65.286-.65.65v7.15zm-5.148-1.495c1.781.078 3.055-1.092 3.367-2.587l.65-2.99c.182-.832-1.066-1.105-1.235-.273l-.65 3.003h-.013c-.181.923-.91 1.768-2.067 1.716H7.396l-.12-1.39c.598.25 1.345.497 1.797-.196.234-.325.442-.845.793-2.886.157-.858-1.13-1.08-1.274-.234-.298 1.65-.402 2.119-.584 2.379 0 .013-.013.013-.013.026-.19.295-.51.256-.783.168L6.994 28.5c-.079-.858-1.378-.741-1.3.13l.455 5.005c.038.325.298.572.623.585l1.781.065zm-5.538-.65c0 .87 1.3.87 1.3 0V28.63c0-.364-.286-.65-.65-.65H.819c-.753 0-.753 1.13 0 1.13h2.198v4.525z"
                        transform="translate(1 4)" />
                    <path stroke="#ddd" stroke-dasharray="17.107 388.8" stroke-linecap="round" stroke-width="2.333"
                        d="M30.32 11.317C30.213 5.222 25.149.367 19.007.475 12.867.582 7.974 5.61 8.08 11.705c.107 6.096 5.171 10.95 11.313 10.843 6.14-.107 11.033-5.135 10.927-11.23z"
                        transform="translate(1 4)" />
                    <path stroke="#ddd" stroke-dasharray="10.109 116.64" stroke-linecap="round" stroke-width="2.333"
                        d="M25.638 11.4c-.062-3.53-2.994-6.34-6.55-6.278-3.555.062-6.387 2.973-6.326 6.502.062 3.529 2.994 6.339 6.55 6.277 3.555-.062 6.387-2.973 6.326-6.502z"
                        transform="translate(1 4)" />
                    <path fill="#ddd"
                        d="M21.146 16.79l1.622-1.623c.607-.607 1.59-.608 2.197 0l3.23 3.228c.606.607.61 1.585-.002 2.197l-1.903 1.904c-.165.164-.358.285-.563.36-.22.167-.33.167-.33.167s-.968.39-1.743.39c-.775 0-5.229-.39-10.457-5.463-5.229-5.073-6.003-9.365-6.003-9.365s-.388-2.342 0-3.122c.19-.383.473-.766.712-1.056.05-.075.11-.146.178-.213l.026-.027c.098-.11.175-.192.215-.234l.03-.03.01.009L9.987 2.29c.607-.607 1.59-.608 2.197-.001l3.23 3.23c.606.605.61 1.584-.002 2.196l-1.903 1.903c-.043.043-.088.083-.134.12l.015.017s-.58.39-.58.586v.194c.075.164 1.224 2.594 3.098 4.293.857.777 1.676 1.401 2.322 1.856l.95.667.018.012c.002.002.583.392.777.392.193 0 .58-.39.58-.39l.582-.586.009.01z"
                        transform="translate(1 4)" />
                </g>
            </g>
        </svg>
    </div>

    <!-- heading -->
    <div id="heading" #heading matRipple class="mat-icon-button button" fxFlex fxLayout="column" fxLayoutAlign="start center" (click)="openDrawer()">
        <div fxFlexOffset="6px" id="openable-indication"></div>
        <div fxFlexOffset="7px" id="defi-name">
            <p>{{locationName || 'טוען...'}}</p>
        </div>
        <div id="nav-time">
            <p>{{timeStr || ''}}</p>
        </div>
    </div>

    <!-- start nav -->
    <div matRipple class="mat-icon-button button" fxFlex fxLayout="row" fxLayoutAlign="center center" dir="rtl" fxLayoutGap="9px"
        (click)="startNavFunc()">
        <p>התחל ניווט</p>
        <svg width="10px" height="18px" viewBox="0 0 10 18" version="1.1" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink">
            <title>Combined Shape</title>
            <g id="אתר" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Artboard" transform="translate(-16.000000, -623.000000)" fill="#FFFFFF">
                    <g id="Group-3" transform="translate(0.000000, 597.000000)">
                        <path
                            d="M25.6459707,26.8640777 L25.6777876,26.9109791 C25.881596,27.2243315 26,27.5983384 26,28 L26,42 C26,42.5704973 25.7611347,43.0852047 25.3779604,43.4495658 C24.7488075,43.5699364 24.0729141,43.3866226 23.5857864,42.8994949 L17.2218254,36.5355339 C16.4407768,35.7544853 16.4407768,34.4881554 17.2218254,33.7071068 L23.5857864,27.3431458 C24.1429963,26.7859359 24.9471938,26.6262465 25.6459707,26.8640777 Z"
                            id="Combined-Shape"></path>
                    </g>
                </g>
            </g>
        </svg>
        <div id="navProgress" #navProgress></div>
    </div>
</div>
