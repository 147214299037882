import {Message} from '../../../messages/message.model';
import {Defi} from '../interfaces/firestore.interfaces';

export interface FirestoreState {
  defis: Defi[];
  error: Message;
  success: Message;
  loading: boolean;
}

export const initialState: FirestoreState = {
  defis: [],
  error: null,
  success: null,
  loading: false
};
