import {Component, OnDestroy, OnInit, ViewChild, Renderer2, Injectable, ElementRef} from '@angular/core';
import { MatDialog} from '@angular/material/dialog';
import {MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {combineLatest, Observable, Subject} from 'rxjs';
import {map, take, withLatestFrom} from 'rxjs/operators';
import {Defi} from '../../../../core/firestore/interfaces/firestore.interfaces';
import {Store} from '@ngrx/store';
import {selectMapLocation, selectSelectedDefi} from '../../store/map.selectors';
import {ShareDialogComponent} from '../../../../components/share-dialog/share-dialog.component';
import {OpenImageComponent} from '../../../../components/open-image/open-image.component';
import {SetIsSidenavOpen} from '../../store/map.actions';


@Component({
    selector: 'app-bottom-drawer',
    templateUrl: './map-details-bottom.component.html',
    styleUrls: ['./map-details-bottom.component.scss']
})
export class MapDetailsBottomComponent implements OnInit , OnDestroy {

    constructor(private bottomDrawerRef: MatBottomSheetRef<MapDetailsBottomComponent>,
                public dialog: MatDialog,
                private renderer: Renderer2,
                private store: Store
    ) {

        this.defi$ = this.store.select(selectSelectedDefi);

        // id shorten
        this.idShorten$ = this.store.select(selectSelectedDefi).pipe(
            map( defi => {
                return defi?.id.replace(/\D/g, '') + '#';
            })
        );

        // address
        this.addressStr$ = this.store.select(selectSelectedDefi).pipe(
            map( defi => {
                const comma = !!defi?.locationStreet
                && defi?.locationStreet !== ''
                && !!defi?.locationCity
                && defi?.locationCity !== ''
                    ? ','
                    : '';
                return `${defi?.locationStreet || ''} ${defi?.locationNumber || ''}${comma} ${defi?.locationCity || ''}`;
            })
        );

        this.isAddress$ = this.store.select(selectSelectedDefi).pipe(
            withLatestFrom(this.addressStr$),
            map( ([defi, addressStr]) => {
                return addressStr.replace(/\s/g, '').length > 0;
            })
        );

        // floor
        this.floorStr$ = this.store.select(selectSelectedDefi).pipe(
            map( defi => {
                if (defi?.locationFloor) {
                    return `${defi?.locationFloor}`;
                } else {
                    return null;
                }
            })
        );

        // contact
        this.contactStr$ = this.store.select(selectSelectedDefi).pipe(
            map( defi => {
                const dash = !!defi?.contactName
                && defi?.contactName !== ''
                && !!defi?.contactPhone
                && defi?.contactPhone !== ''
                    ? ' - '
                    : '';
                return `${defi?.contactName || ''}${dash}${defi?.contactPhone || ''}`;
            })
        );

        // location  hours
        this.locationHoursStr$ = this.store.select(selectSelectedDefi).pipe(
            map( defi => {
                return (defi?.locationOpenAllDay || '') ? 'פתוח 24/7' : (defi?.locationOpenHours || '') ? (defi?.locationOpenHours || '') : '';
            })
        );

        // links
        this.wazeLink$ = this.store.select(selectSelectedDefi).pipe(
            map( defi => {
                return `https://www.waze.com/ul?ll=${defi?.coordinates.geopoint.latitude},${defi?.coordinates.geopoint.longitude}&navigate=yes&zoom=17`;
            })
        );

        this.googleMapsLink$ = combineLatest(
            [ this.store.select(selectSelectedDefi), this.store.select(selectMapLocation)]
        ).pipe(
            map( ([defi, currentLocation]) => {
                return `https://www.google.com/maps/dir/?api=1&origin=${currentLocation.latitude},${currentLocation.longitude}&destination=${defi?.coordinates.geopoint.latitude},${defi?.coordinates.geopoint.longitude}&travelmode=walking`;
            })
        );

        this.mapLocation$ = this.store.select(selectMapLocation);

    }

    destroy$: Subject<boolean> = new Subject<boolean>();


    defi$: Observable<Defi>;

    idShorten$: Observable<string>;

    isAddress$: Observable<boolean>;
    addressStr$: Observable<string>;

    contactStr$: Observable<string>;

    floorStr$: Observable<string>;

    locationHoursStr$: Observable<string>;

    wazeLink$: Observable<string>;
    googleMapsLink$: Observable<string>;

    mapLocation$: Observable<{
        latitude: number;
        longitude: number;
    }>;

    // bottom sheet
    drawerHeight = 200;
    panStart = -1;
    originalHeight = -1;
    @ViewChild('wrapper') wrapper: ElementRef;



    ngOnInit() {
        const wrapperElm = document.getElementById('wrapper');
        const mc = new Hammer(wrapperElm);
        mc.get('pan').set({ direction: Hammer.DIRECTION_ALL });

        mc.on('panmove', ( e ) => {
            if (this.panStart === -1) {
                this.panStart = e.center.y;
                this.originalHeight = Number(wrapperElm.offsetHeight);
            } else {
                const panDelta = this.panStart - e.center.y;
                const height = this.originalHeight + panDelta;
                if (height < this.originalHeight + 100) {
                    this.renderer.setStyle(wrapperElm, 'max-height', height + 'px');
                }
            }
        });
        mc.on('panend', ( e ) => {
            this.panStart = -1;
            const height = Number(wrapperElm.offsetHeight);
            if (e.velocityY >= 0.1) {
            this.bottomDrawerRef.dismiss();
            wrapperElm.className = 'animateOff';
            } else if (e.velocityY < -0.1) {
            wrapperElm.className = 'animateOn';
            setTimeout(() => {
                this.panStart = -1;
                this.originalHeight = -1;
                this.renderer.setStyle(wrapperElm, 'max-height', '');
                wrapperElm.classList.remove('animateOn');
            }, 300);
            } else if (height < this.originalHeight - 100) {
                // this.renderer.setStyle(wrapperElm, 'max-height', 90 + 'px');
                this.bottomDrawerRef.dismiss();
            } else {
            wrapperElm.className = 'animateOn';
            setTimeout(() => {
                this.panStart = -1;
                this.originalHeight = -1;
                this.renderer.setStyle(wrapperElm, 'max-height', '');
                wrapperElm.classList.remove('animateOn');
            }, 300);
            }
        });
    }


    ngOnDestroy() {
        this.store.dispatch(new SetIsSidenavOpen({ isSidenavOpen: false }));
        // force unsubscribe
        this.destroy$.next(true);
        // Now let's also unsubscribe from the subject itself:
        this.destroy$.unsubscribe();

    }

    exportNav() {
        this.defi$?.pipe(
            take(1),
            withLatestFrom(this.mapLocation$)
        ).subscribe( ([defi, mapLocation]) => {
            if (!!defi && !!mapLocation) {
                const confirmDialogRef = this.dialog.open(ShareDialogComponent, {
                    data: { defi, currentLocation: mapLocation},
                    width: '250px',
                    // height: '180px',
                    panelClass: 'paddingless-dialog-container'
                });
            }
        });
    }

    openImage() {
        this.defi$?.pipe(
            take(1)
        ).subscribe( defi => {
            if (!!defi && !!defi?.imageURL) {
                const dialogRef = this.dialog.open(OpenImageComponent, {
                    data: {url: defi?.imageURL},
                    width: '100vw',
                    height: 'calc(100vh - calc(100vh - 100%))',
                    maxWidth: '100vw',
                    panelClass: 'open-image-dialog-container'
                });
            }
        });
    }

    close(event): void {
        this.bottomDrawerRef.dismiss();
        event.preventDefault();
    }


}
