
<div class="invisible-scrollbar" 
*ngIf="router.url === '/map'">
<img id="mda-baner" src="assets/img/mda-bar.svg"> 
    <div id="pageOne" class="page" fxLayoutAlign="space-between stretch" fxLayout="column">
        <!--      fxLayout="column" fxLayoutAlign="space-around stretch">-->
        <div fxFlex class="top-section" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="30px">
            <!-- <div fxFlexOffset="10"></div> -->
            <div class="logo-container">
                <img class="main-logo" src="assets/img/defi-logo-small.svg">
            </div>
            <div class=" title-under-logo">
                מיפוי דפיברילטורים חברתי.
            </div>
            <app-google-places [isOverlay]="true" [addressType]="'(cities)'"></app-google-places>
            <div >
                <span class="header-content search-defis-btn" (click)="this.aroundMe()">
                    <img class="arround" src="assets/img/arround.svg">
                    חפש דפי׳ס בסביבתי
                </span><br>
                <span class="escNotice">(ESC להגיע למפה)</span>
            </div>
            <div fxFlexOffset="1"></div>
            <!-- <div id="samim" fxLayout="row" fxLayoutAlign="start center">
                <img class="icon" src="assets/img/Samim_Lev_defibratorim_logo.png">
                <div id="text" fxLayout="column" fxLayoutAlign="center center">
                    <span class="articleHeader">
                        איפה דפי? לוקח חלק במיזם חדש 🍻
                    </span>
                    <div style="width: 100%; margin-top: 15px" fxLayout="row" dir="ltr" fxLayoutAlign="space-between start">
                        <button matRipple [matRippleUnbounded]="false" mat-flat-button color="primary" dir="rtl" (click)="openExternalLink('https://israeldefi.org')">
                            לפרטים נוספים
                            <mat-icon fontSet="material-icons-round">chevron_left</mat-icon>
                        </button>
                    </div>
                </div>
            </div> -->
                    
        </div>
        <button fxFlex="10" class="scrollDownIndicator" (click)="scroll(pageTwo)">
            <p>╱╲</p>
        </button>
    </div>
    <div #pageTwo id="pageTwo" class="page"  fxLayout="column" fxLayoutAlign="start center">
        <div id="pageTwoContainer" fxLayout="column" fxLayoutAlign="center stretch">
            <div id="api" fxFlex="50" fxLayout="row" fxLayoutAlign="start center">
                <img class="icon" src="assets/img/open-door.svg">
                <div id="text" fxLayout="column" fxLayoutAlign="center start">
                    <span class="articleHeader">
                        מיפוי הדפיברילטורים של ״איפה דפי?״ פתוח לכולם.
                    </span>
                    <span class="articleContent">
                        הורד עותק מעודכן של מיפוי הדפיברילטורים הציבורי לשימוש ברישיון פתוח
                    </span>
                    <div style="width: 100%; margin: 10px" fxLayout="row" fxLayoutAlign="space-between start">
                        <a href="http://opendatacommons.org/licenses/by/1.0" target="_blank" rel="noopener noreferrer">
                            <img id="openData" src="assets/img/open-data.png">
                        </a>
                        <button mat-flat-button color="primary" (click)="openDownloadDialog()">
                            להורדה
                            <mat-icon fontSet="material-icons-round">chevron_left</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
            <div id="orgs" *ngIf="true" dir="ltr" fxFlex="50" fxLayout="row" fxLayoutAlign="start center">
                <img class="icon" src="assets/img/orgs.svg">
                <div id="text" dir="rtl" fxLayout="column" fxLayoutAlign="center start">
                    <span class="articleHeader">
                        ניהול פריסת דפיברילטורים ארגונית ב-״איפה דפי?״
                    </span>
                    <span class="articleContent">
                         פיתחנו במיוחד בשבילכם מערכת לניהול פריסת דפיברילטורים חינמית. כולל השלמת פרטים מתקדמת מהשטח, יצוא ויבוא של המיפוי בלחיצה ועוד...
                    </span>
                    <div style="width: 100%; margin: 10px" fxLayout="row" fxLayoutAlign="space-between start">
                        <button mat-flat-button color="primary" [disabled]="true">
                              בקרוב
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <!-- <div fxFlex style="background-color: white;"></div> -->
</div>
