import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { navigationReducer } from './store/navigation.reducer';
import {NavigationEffects} from './store/navigation.effects';
import {NavigationService} from './service/navigation.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('navigation', navigationReducer),
    EffectsModule.forFeature([NavigationEffects])
  ]
})
export class NavigationModule {}
