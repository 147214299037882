import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Observable, Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatRippleModule } from '@angular/material/core';
import {Store} from '@ngrx/store';
import * as fromAppState from '../../../../store/app.state';
import { selectIsSidenavOpen } from '../../store/map.selectors';


@Component({
  selector: 'app-map-overview-header',
  templateUrl: './map-overview-header.component.html',
  styleUrls: ['./map-overview-header.component.scss']
})
export class MapOverviewHeaderComponent implements OnInit {
  @ViewChild('navProgress', {static: false}) navProgress: ElementRef;
  @ViewChild('callButton', {static: false}) callButton: ElementRef;
  @ViewChild('heading', {static: false}) heading: ElementRef;
  @Output() startNav = new EventEmitter<boolean>();
  @Output() detailes = new EventEmitter<boolean>();
  @Output() callContact = new EventEmitter<boolean>();
  @Input() locationName: string;
  @Input() timeStr: string;
  @Input() contact: string = null;
  isSidenavOpen$: Observable<boolean>;

  timer$ = timer(10000);

  // destroy observables
  destroy$: Subject<boolean> = new Subject<boolean>();

  // stop auto nav
  stop$: Subject<boolean> = new Subject<boolean>();
  stop = false;

  constructor(      private store: Store<fromAppState.AppState>,
    ) { }

  ngOnInit(): void {
    this.isSidenavOpen$ = this.store.select(selectIsSidenavOpen);
    this.isSidenavOpen$.pipe(
      takeUntil(this.destroy$)
      ).subscribe( open => {
        if (open) {
          this.stopAutoNav();
        }
      });
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit() {
    const headingElm = document.getElementById('heading');

    const mc = new Hammer(headingElm);
    mc.get('swipe').set({ direction: Hammer.DIRECTION_ALL });
    mc.on('swipeup tap', ( e ) => {
      this.openDrawer();
     });
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnChanges(changes: SimpleChanges) {
    if (this.locationName && this.timeStr) {
      this.navProgress.nativeElement.classList.add('count');

      this.timer$.pipe(
        takeUntil(this.destroy$),
        takeUntil(this.stop$)
      ).subscribe(() => {
        if (!this.stop) {
          this.startNav.emit(true);
        }
      });
    }
    if (this.contact && this.callButton) {
      this.callButton.nativeElement.classList.add('enable');
    } else if (this.locationName && this.timeStr) {
      this.callButton.nativeElement.classList.remove('enable');
    }
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnDestroy() {
    // force unsubscribe
    this.destroy$.next(true);
    // Now let's also unsubscribe from the subject itself:
    this.destroy$.unsubscribe();
    this.stop$.unsubscribe();
  }

  startNavFunc() {
    this.startNav.emit(true);
    this.stopAutoNav();
  }

  callNav() {
    this.callContact.emit(true);
    this.stopAutoNav();
  }

  openDrawer() {
    this.detailes.emit(true);
  }

  stopAutoNav() {
    this.stop$.next(true);
    this.stop = true;
    this.navProgress.nativeElement.classList.add('forceStopCount');
  }
}
