import {Component, OnDestroy, OnInit, ViewChild, Renderer2} from '@angular/core';
import { MatDialog} from '@angular/material/dialog';
import {combineLatest, Observable, Subject} from 'rxjs';
import {map, take, withLatestFrom} from 'rxjs/operators';
import {Defi} from '../../../../core/firestore/interfaces/firestore.interfaces';
import {ShareDialogComponent} from '../../../../components/share-dialog/share-dialog.component';
import {OpenImageComponent} from '../../../../components/open-image/open-image.component';
import {CallDialogComponent} from '../../../../components/call-dialog/call-dialog.component';
import {Store} from '@ngrx/store';
import {selectIsSidenavOpen, selectMapLocation, selectSelectedDefi} from '../../store/map.selectors';
import {SetIsSidenavOpen, SetSelectedDefi} from '../../store/map.actions';
import {GoogleNavigationRoute, GoogleStep} from '../../../../core/navigation/interfaces/google-route.interfaces';
import {selectGoogleNavigationRoute} from '../../../../core/navigation/store/navigation.selectors';
import {ModalManager} from 'ngb-modal';

@Component({
    selector: 'app-map-details',
    templateUrl: './map-details.component.html',
    styleUrls: ['./map-details.component.scss']
})
export class MapDetailsComponent implements OnInit , OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();
    @ViewChild('wrapper', {static: false}) wrapper;

    defi$: Observable<Defi>;

    idShorten$: Observable<string>;

    isAddress$: Observable<boolean>;
    addressStr$: Observable<string>;
    floorStr$: Observable<string>;

    contactStr$: Observable<string>;

    locationHoursStr$: Observable<string>;

    wazeLink$: Observable<string>;
    googleMapsLink$: Observable<string>;

    mapLocation$: Observable<{
        latitude: number;
        longitude: number;
    }>;

    googleNavigationRoute$: Observable<GoogleNavigationRoute>;

    googleNavigationSteps$: Observable<GoogleStep[]>;

    isSidenavOpen$: Observable<boolean>;

    constructor(public dialog: MatDialog,
                private renderer: Renderer2,
                private store: Store,
                private modalService: ModalManager
    ) {

        this.defi$ = this.store.select(selectSelectedDefi);

        // id shorten
        this.idShorten$ = this.store.select(selectSelectedDefi).pipe(
            map( defi => {
                return defi?.id.replace(/\D/g, '') + '#';
            })
        );

        // address
        this.addressStr$ = this.store.select(selectSelectedDefi).pipe(
            map( defi => {
                const comma = !!defi?.locationStreet
                && defi?.locationStreet !== ''
                && !!defi?.locationCity
                && defi?.locationCity !== ''
                    ? ','
                    : '';
                return `${defi?.locationStreet || ''} ${defi?.locationNumber || ''}${comma} ${defi?.locationCity || ''}`;
            })
        );

        this.isAddress$ = this.store.select(selectSelectedDefi).pipe(
            withLatestFrom(this.addressStr$),
            map( ([defi, addressStr]) => {
                return addressStr.replace(/\s/g, '').length > 0;
            })
        );

        // floor
        this.floorStr$ = this.store.select(selectSelectedDefi).pipe(
            map( defi => {
                if (defi?.locationFloor) {
                    return `${defi?.locationFloor}`;
                } else {
                    return null;
                }
            })
        );

        // contact
        this.contactStr$ = this.store.select(selectSelectedDefi).pipe(
            map( defi => {
                const dash = !!defi?.contactName
                && defi?.contactName !== ''
                && !!defi?.contactPhone
                && defi?.contactPhone !== ''
                    ? ' - '
                    : '';
                return `${defi?.contactName || ''}${dash}${defi?.contactPhone || ''}`;
            })
        );

        // location  hours
        this.locationHoursStr$ = this.store.select(selectSelectedDefi).pipe(
            map( defi => {
                return (defi?.locationOpenAllDay || '') ? 'פתוח 24/7' : (defi?.locationOpenHours || '') ? (defi?.locationOpenHours || '') : '';
            })
        );

        // links
        this.wazeLink$ = this.store.select(selectSelectedDefi).pipe(
            map( defi => {
                return `https://www.waze.com/ul?ll=${defi?.coordinates.geopoint.latitude},${defi?.coordinates.geopoint.longitude}&navigate=yes&zoom=17`;
            })
        );

        this.googleMapsLink$ = combineLatest(
            [ this.store.select(selectSelectedDefi), this.store.select(selectMapLocation)]
        ).pipe(
            map( ([defi, currentLocation]) => {
                return `https://www.google.com/maps/dir/?api=1&origin=${currentLocation.latitude},${currentLocation.longitude}&destination=${defi?.coordinates.geopoint.latitude},${defi?.coordinates.geopoint.longitude}&travelmode=walking`;
            })
        );

        this.mapLocation$ = this.store.select(selectMapLocation);

        this.googleNavigationRoute$ = this.store.select(selectGoogleNavigationRoute);

        this.googleNavigationSteps$ = this.store.select(selectGoogleNavigationRoute).pipe(
            map( route => {
                if (route?.legs && route?.legs.length > 0) {
                    return route?.legs[0].steps;
                }
                return [];
            })
        );

        this.isSidenavOpen$ = this.store.select(selectIsSidenavOpen);

    }

    ngOnInit() {
    }

    exportNav() {
        this.defi$?.pipe(
            take(1),
            withLatestFrom(this.mapLocation$)
        ).subscribe( ([defi, mapLocation]) => {
            if (!!defi && !!mapLocation) {
                const confirmDialogRef = this.dialog.open(ShareDialogComponent, {
                    data: { defi, currentLocation: mapLocation},
                    width: '250px',
                    // height: '180px',
                    panelClass: 'paddingless-dialog-container'
                });
            }
        });
    }

    close(event): void {
        // TODO: close the sidenav
        event.preventDefault();
    }

    openImage() {
        this.store.select(selectSelectedDefi).pipe(
            take(1)
        ).subscribe(defi => {
            if (defi?.imageURL) {
                const dialogRef = this.dialog.open(OpenImageComponent, {
                    data: {url: defi?.imageURL},
                    width: '100vw',
                    height: 'calc(100vh - calc(100vh - 100%))',
                    maxWidth: '100vw',
                    panelClass: 'open-image-dialog-container'
                });
            }
        });
    }


    ngOnDestroy() {
        // force unsubscribe
        this.destroy$.next(true);
        // Now let's also unsubscribe from the subject itself:
        this.destroy$.unsubscribe();

        this.store.dispatch(new SetIsSidenavOpen({ isSidenavOpen: false }));
    }

    // close sidenav
    closeSidenav() {
        this.store.dispatch(new SetIsSidenavOpen({
            isSidenavOpen: false
        }));
    }

    toggleSidenav() {
        this.isSidenavOpen$.pipe(
            take(1)
        ).subscribe( isSidenavOpen => {
            this.store.dispatch(new SetIsSidenavOpen({isSidenavOpen: !isSidenavOpen}));
        });
    }
}
