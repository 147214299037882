<div class="overlay-bg" fxLayout="column" fxLayoutAlign="space-between center"
        *ngIf="router.url === '/map'"
        [style]="(isOverlayOpen$ | async)? 'z-index: 1000': null">

    <!-- שינויים של שמים לב (ולמעלה הסתרתי את הקופסא המקורית)  -->
    <div style="width: 100%">
        <div id="samim" fxLayout="row" *ngIf="false" fxLayoutAlign="start center" matRipple (click)="openExternalLink('https://israeldefi.org')">
            <img class="icon" src="assets/img/Samim_Lev_defibratorim_logo.png">
            <div id="text" fxLayout="column" fxLayoutAlign="center center">
                <span class="articleHeader">
                    איפה דפי? לוקח חלק במיזם חדש 🍻
                    <br>
                    לחצו לפרטים נוספים!
                </span>
                
            </div>
        </div>
    
        <!-- mda new -->
        <div fxLayout="row" id="mda-wraper" fxLayoutAlign="center center">
            <span fxFlex="50" id="mda-text">השירות מוגש בשיתוף:</span>
            <div fxFlex id="mda-baner" fxLayout="row" fxLayoutAlign="start center" >
                <img  src="assets/img/mda-baner-mobile.svg">
            </div>
        </div> 
        <!-- end mda -->

        <div fxLayout="column" *ngIf="true" fxLayoutAlign="space-around center" class="header-bubble milky">
            <div class="header-bubble-title">ברוכים הבאים!</div>
                <div class="title-content">
                    ״איפה דפי״ הינו פרוייקט מיפוי דפיברלטורים שיתופי חברתי שמטרתו להעלות את נגישות המכשירים הפרוסים בקהילה למען הצלת חיים. השימוש מהווה הסכמה לתנאי השימוש.
                </div>
        </div>    

        <div fxLayout="column" *ngIf="false" fxLayoutAlign="space-around center" class="header-bubble milky">
                <div class="title-content">
                    השימוש מהווה הסכמה לתנאי השימוש.
                </div>
        </div>
    </div>

    <!-- סוף שינויים של שמים לב -->

    <button mat-icon-button id="nav-icon" class="mat-elevation-z3 nav-btn-mobile" (click)="navToNearestDefi()"
            matTooltip="מצא את הדפי הקרוב ביותר"
            matTooltipPosition="left"
            matTooltipClass="pxwidth77">
        <img src="assets/img/start-nav-mobile.svg" class="button-image nav-button-img-mobile">
<!--        <div class="btn-txt">התחל ניווט לדפי <br>הקרוב</div>-->
    </button>
    <div>
        <div class="bottom-section p-4" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="col-10 bottom-section-download">
                רוצה לקחת חלק במאמץ להצלת חיים?
                הורד את האפליקציה ותתחיל למפות!
            </div>
            <button
                    (click)="openAppLink()"
                    class="download-icon mat-elevation-z3"
                    fxLayout="row" fxLayoutAlign="center center"
                    matRipple
                    mat-icon-button id="app-icon"
                    matTooltip="לחץ כאן להורדת האפליקציה"
                    matTooltipPosition="above"
                    matTooltipClass="pxwidth77">
                <mat-icon class="download-icon-arrow">keyboard_tab</mat-icon>
            </button>
        </div>
    </div>
</div>
