import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-mda-dialog',
  templateUrl: './mda-dialog.component.html',
  styleUrls: ['./mda-dialog.component.scss']
})
export class MdaDialogComponent implements OnInit, OnDestroy {
  @ViewChild('navProgress', {static: true}) navProgress: ElementRef;
  destroy$: Subject<boolean> = new Subject<boolean>();
  title = '';

  constructor(public dialogRef: MatDialogRef<MdaDialogComponent>) { }

  ngOnInit() {
    setTimeout(() => {
      this.navProgress.nativeElement.classList.add('count');
    }, 200);
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    // force unsubscribe
    this.destroy$.next(true);
    // Now let's also unsubscribe from the subject itself:
    this.destroy$.unsubscribe();
  }

}


