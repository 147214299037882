import {ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {combineLatest, interval, Observable, Subject} from 'rxjs';
import * as fromAppState from '../../../../store/app.state';
import * as fromFirestoreSelectors from '../../../../core/firestore/store/firestore.selectors';
import * as fromLocationSelectors from '../../../../core/location/store/location.selectors';
import * as fromMapSelectors from '../../../../modules/map/store/map.selectors';
import { MatRippleModule } from '@angular/material/core';
import {
  selectCentralizeCurrentLocation,
  selectIsMobile,
  selectIsOverlayOpen,
  selectIsSidenavOpen,
  selectMapBearing,
  selectMapBounds,
  selectMapLocation,
  selectMapPan,
  selectMapPitch,
  selectMapsStyle,
  selectMapZoom,
  selectSelectedDefi
} from '../../store/map.selectors';
import {FetchDefis, FetchNearestDefi} from '../../../../core/firestore/store/firestore.actions';
import {
  SetCentralizeCurrentLocation,
  SetIsSidenavOpen,
  SetMapBearing,
  SetMapBounds,
  SetMapLocation,
  SetMapPitch,
  SetMapStyle,
  SetSelectedDefi
} from '../../store/map.actions';
import {
  FetchLocation,
  SetCurrentLocation,
  StopWatchLocation,
  WatchCompass,
  WatchCompassIOS13,
  WatchLocation
} from '../../../../core/location/store/location.actions';
import {map, skip, take, takeUntil, withLatestFrom} from 'rxjs/operators';
import * as GeoJSON from 'geojson';
import {Feature, FeatureCollection, GeoJsonProperties, Geometry, Point} from 'geojson';
import {Defi} from '../../../../core/firestore/interfaces/firestore.interfaces';
import {Store} from '@ngrx/store';
import {
  selectGoogleNavigationRoute,
  selectNavigationRoute,
  selectNavigationStep,
  selectNavigationType
} from '../../../../core/navigation/store/navigation.selectors';
import {NavigationRoute} from '../../../../core/navigation/interfaces/navigation.interfaces';
import {NavigationStep, NavigationType} from '../../../../core/navigation/store/navigation.state';
import {
  FetchGoogleNavigationRoute,
  FetchNavigationRoute,
  SetGoogleNavigationRoute,
  SetNavigationRoute,
  SetNavigationStep,
  SetNavigationType
} from '../../../../core/navigation/store/navigation.actions';
import * as mapBox from 'mapbox-gl';
import {LngLatBounds, Marker, PointLike} from 'mapbox-gl';
import {MapStyle} from '../../store/map.state';
import {GoogleNavigationRoute} from '../../../../core/navigation/interfaces/google-route.interfaces';
import {CallDialogComponent} from '../../../../components/call-dialog/call-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {ShareDialogComponent} from '../../../../components/share-dialog/share-dialog.component';
import {MatTooltip} from '@angular/material/tooltip';
import {FirestoreService} from '../../../../core/firestore/service/firestore.service';
import {selectCurrentCompass, selectCurrentLocation} from '../../../../core/location/store/location.selectors';
import { CookieService } from 'ngx-cookie-service';
import { Timestamp } from 'rxjs/internal/operators/timestamp';
import { MdaDialogComponent } from 'src/app/components/mda-dialog/mda-dialog.component';


@Component({
  selector: 'app-ng-map',
  templateUrl: './ng-map.component.html',
  styleUrls: ['./ng-map.component.scss']
})
export class NgMapComponent implements OnInit, OnDestroy {

  maxInteger = Number.MAX_SAFE_INTEGER;

  SATELLITE_MAP_STYLE = 'mapbox://styles/arielhasidim/cjcn50j5a239n2smh5ybrtzi7';
  REGULAR_MAP_STYLE = 'mapbox://styles/arielhasidim/cjqtpoyr71vm22smd7me1uo8o';

  // types alias
  mapStyleAlias = MapStyle;
  navigationTypeAlias = NavigationType;
  navigationStepAlias = NavigationStep;
  navigationStep;

  // destroy observables
  destroy$: Subject<boolean> = new Subject<boolean>();

  // map box
  map: mapBox.Map;

  mapStyle$: Observable<MapStyle>;
  mapBounds$: Observable<LngLatBounds>;
  mapPitch$: Observable<number>;
  mapBearing$: Observable<number>;
  mapZoom$: Observable<number>;
  mapPan$: Observable<number[]>;

  defis$: Observable<Defi[]>;
  defisGEO$: Observable<GeoJSON.FeatureCollection<Geometry, GeoJsonProperties>>;

  selectedDefi$: Observable<Defi>;
  mapLocation$: Observable<{
    latitude: number;
    longitude: number;
  }>;

  isOverlayOpen$: Observable<boolean>;
  isSidenavOpen$: Observable<boolean>;
  currentLocation$: Observable<{ latitude: number; longitude: number; }>;
  isCurrentLocation = false;
  currentLocationCoordinates;
  currentCompass$: Observable<number>;
  isTrackUserLocation$: Observable<boolean>;
  centralizeCurrentLocation$: Observable<boolean>;
  navigationRoute$: Observable<NavigationRoute>;
  navigationRouteData$: Observable<GeoJSON.FeatureCollection<GeoJSON.LineString>>;
  navigationRouteTimeStr$: Observable<string>;
  googleNavigationRoute$: Observable<GoogleNavigationRoute>;
  googleNavigationRouteData$: Observable<GeoJSON.FeatureCollection<GeoJSON.LineString>>;
  googleNavigationRouteTimeStr$: Observable<string>;
  navigationType$: Observable<NavigationType>;
  navigationStep$: Observable<NavigationStep>;
  wazeLink$: Observable<string>;

  compassBearing = false;
  compass = 0;
  isMapNorth = true;
  selectedCluster: { geometry: GeoJSON.Point; properties: any };
  navigationStepEnum = NavigationStep;
  isMobile$: Observable<boolean>;
  isMobile: boolean;
  visibleDefisMain: Array<Defi> = [];
  selectedDefi = null;
  lastFetchLat = 0;
  lastFetchLng = 0;
  @ViewChild('tooltip') tooltip: MatTooltip;
  @ViewChild('license') license: ElementRef;

  constructor(
      public dialog: MatDialog,
      private store: Store<fromAppState.AppState>,
      private cd: ChangeDetectorRef,
      private firestoreService: FirestoreService,
      private renderer: Renderer2,
      private cookieService: CookieService
  ) { }

  ngOnInit(): void {
    window.addEventListener('orientationchange', () => {
      this.map.resize();
    }, false);

    this.isMobile$ = this.store.select(selectIsMobile);

    this.isSidenavOpen$ = this.store.select(selectIsSidenavOpen);
    this.isSidenavOpen$.pipe(
      takeUntil(this.destroy$)
      ).subscribe( open => {
        if (!open) {
          if (this.map) {
            setTimeout(() => {
              this.map.resize();
            }, 1000);
          }
        }
      });

    this.isOverlayOpen$ = this.store.select(selectIsOverlayOpen);

    this.currentLocation$ = this.store.select(fromLocationSelectors.selectCurrentLocation);

    this.currentCompass$ = this.store.select(fromLocationSelectors.selectCurrentCompass);
    this.currentCompass$
        .pipe(
            takeUntil(this.destroy$),
            withLatestFrom(
                this.store.select(selectNavigationStep),
                this.store.select(selectCurrentLocation),
                this.store.select(selectMapBearing)
                ))
        .subscribe(([compass, navigationStep, currentLocation, mapBearing]) => {
              if (navigationStep === NavigationStep.navigation && this.compassBearing) {
                this.map?.flyTo({
                  zoom: 16,
                  center: [
                    currentLocation?.longitude,
                    currentLocation?.latitude
                  ],
                  animate: false,
                  speed: 0,
                  bearing: (compass ?? mapBearing) ?? 0
                });
                this.map?.panBy([0, -120], {
                  animate: false
                });
              }
        });
    interval(500).pipe(takeUntil(this.destroy$))
        .subscribe( val => {
          this.store.select(selectCurrentCompass).pipe(take(1))
              .subscribe(currentCompass => {
                this.compass = currentCompass;
              });
        });

    this.isTrackUserLocation$ = this.store.select(fromLocationSelectors.selectLocationWatchers).pipe(
        map( locationWatchers => {
          return !!locationWatchers && locationWatchers.length > 0;
        })
    );

    this.centralizeCurrentLocation$ = this.store.select(selectCentralizeCurrentLocation);

    this.mapStyle$ = this.store.select(selectMapsStyle);

    this.mapBounds$ = this.store.select(selectMapBounds);
    // this.mapBounds$.pipe(
    //     takeUntil(this.destroy$)
    // ).subscribe( bounds => {
    //   this.map?.fitBounds(bounds);
    // });

    this.mapPitch$ = this.store.select(selectMapPitch);
    // this.mapPitch$.pipe(
    //     takeUntil(this.destroy$)
    // ).subscribe( pitch => {
    //   this.map?.setPitch(pitch);
    // });

    this.mapBearing$ = this.store.select(selectMapBearing);
    // this.mapBearing$.pipe(
    //     takeUntil(this.destroy$)
    // ).subscribe( bearing => {
    //   this.map?.setBearing(bearing);
    // });

    this.mapZoom$ = this.store.select(selectMapZoom);
    // this.mapZoom$.pipe(
    //     takeUntil(this.destroy$)
    // ).subscribe( zoom => {
    //   this.map?.setZoom(zoom);
    // });

    this.mapPan$ = this.store.select(selectMapPan);
    this.mapPan$.pipe(
        takeUntil(this.destroy$)
    ).subscribe( pan => {
      this.map?.panBy(pan as PointLike, {
        animate: true
      });
    });

    this.defis$ = this.store.select(fromFirestoreSelectors.selectDefis);

    this.selectedDefi$ = this.store.select(fromMapSelectors.selectSelectedDefi);

    this.mapLocation$ = this.store.select(fromMapSelectors.selectMapLocation);
    this.mapLocation$.pipe(
        takeUntil(this.destroy$),
        withLatestFrom(this.store.select(selectNavigationStep))
    ).subscribe( ([mapLocation, navigationStep]) => {
      if (navigationStep === NavigationStep.navigation) {
        this.map?.flyTo({
          zoom: 16,
          center: [
            mapLocation?.longitude,
            mapLocation?.latitude
          ],
          speed: 2
        });
        const timerSub = interval(50)
            .subscribe((val) => {
              this.store.select(selectMapLocation).pipe(take(1))
                  .subscribe(mapLocationFromStore => {
                    const distance = this.distanceInMetersBetweenEarthCoordinates(
                        mapLocationFromStore?.latitude,
                        mapLocationFromStore?.longitude,
                        this.map?.getCenter()?.lat,
                        this.map?.getCenter()?.lng
                    );
                    if (distance < 2) {
                      timerSub?.unsubscribe();
                      this.compassBearing = true;
                    }
                  });
            });
      }
    });



    this.wazeLink$ = this.store.select(selectSelectedDefi).pipe(
        map( defi => {
          return `https://www.waze.com/ul?ll=${defi?.coordinates.geopoint.latitude},${defi?.coordinates.geopoint.longitude}&navigate=yes&zoom=17`;
        })
    );
    // navigation route
    this.navigationRoute$ = this.store.select(selectNavigationRoute);

    this.navigationRouteData$ = this.store.select(selectNavigationRoute).pipe(
        withLatestFrom(this.store.select(selectMapLocation),
            this.store.select(fromMapSelectors.selectSelectedDefi)),
        map(([route, mapLocation, selectedDefi]) => {
          if (route?.geometry?.coordinates) {
            // return line
            return {
              type: 'FeatureCollection',
              features: [{
                type: 'Feature',
                geometry: {
                  type: 'LineString',
                  coordinates: [
                    [mapLocation?.longitude, mapLocation?.latitude],
                    ...route?.geometry?.coordinates,
                    [selectedDefi?.coordinates?.geopoint?.longitude, selectedDefi?.coordinates?.geopoint?.latitude]
                  ]
                }
              }]
            } as GeoJSON.FeatureCollection<GeoJSON.LineString>;
          }
          return null;
        })
    );

    this.navigationRouteTimeStr$ = this.store.select(selectNavigationRoute).pipe(
        withLatestFrom(this.store.select(selectNavigationType)),
        map(([route, navigationType]) => {
          if (route) {
            const time = Math.ceil((route?.duration || 0) / 60);
            return navigationType === NavigationType.walking ? `${time} דק׳ הליכה ` : `${time} דק׳ נסיעה `;
          }
          return null;
        })
    );

    // google navigation route
    this.googleNavigationRoute$ = this.store.select(selectGoogleNavigationRoute);

    this.googleNavigationRouteData$ = this.store.select(selectGoogleNavigationRoute).pipe(
        withLatestFrom(this.store.select(selectMapLocation),
            this.store.select(fromMapSelectors.selectSelectedDefi)),
        map(([route, mapLocation, selectedDefi]) => {
          if (route?.legs && route?.legs?.length > 0) {
            // coordinates
            const coordinates: number[][] = [];
            coordinates.push([mapLocation?.longitude, mapLocation?.latitude]);
            // @ts-ignore
            coordinates.push([route?.legs[0]?.start_location.lng(), route?.legs[0]?.start_location.lat()]);
            route?.legs[0]?.steps?.forEach(step => {
              // @ts-ignore
              coordinates.push([step?.start_location.lng(), step?.start_location.lat()]);
              // @ts-ignore
              coordinates.push([step?.end_location.lng(), step?.end_location.lat()]);
            });
            // @ts-ignore
            coordinates.push([route?.legs[0]?.end_location.lng(), route?.legs[0]?.end_location.lat()]);
            coordinates.push([selectedDefi?.coordinates?.geopoint?.longitude, selectedDefi?.coordinates?.geopoint?.latitude]);
            // return line
            return {
              type: 'FeatureCollection',
              features: [{
                type: 'Feature',
                geometry: {
                  type: 'LineString',
                  coordinates
                }
              }]
            } as GeoJSON.FeatureCollection<GeoJSON.LineString>;
          }
          return null;
        })
    );

    this.googleNavigationRouteTimeStr$ = this.store.select(selectGoogleNavigationRoute).pipe(
        withLatestFrom(this.store.select(selectNavigationType)),
        map(([route, navigationType]) => {
          if (route?.legs && route?.legs?.length > 0) {
            const time = Math.ceil((route?.legs[0].duration?.value || 0) / 60);
            return navigationType === NavigationType.walking ? `${time} דק׳ הליכה ` : `${time} דק׳ נסיעה `;
          }
          return null;
        })
    );

    this.defisGEO$ = this.defis$.pipe(
        map( defis => {
          return {features: defis.map( defi => {
              const point =
                  { type: 'Point',
                    coordinates: [defi.coordinates.geopoint.longitude,
                      defi.coordinates.geopoint.latitude] as GeoJSON.Position} as Point;
              return {
                geometry: point, type: 'Feature',
                properties: {
                  defi, defi_id: defi.id
                } as GeoJsonProperties
              } as Feature<Point, GeoJsonProperties>;
            })} as FeatureCollection;
        })
    );

    this.navigationType$ = this.store.select(selectNavigationType);

    this.navigationStep$ = this.store.select(selectNavigationStep);
    this.navigationStep$?.pipe(takeUntil(this.destroy$))
        .subscribe(navigationStep => {
          this.navigationStep = navigationStep;
          if (navigationStep !== NavigationStep.navigation) {
            this.compassBearing = false;
          }
          if (navigationStep !== NavigationStep.notNavigating) {
            this.store.dispatch(new SetCentralizeCurrentLocation({centralizeCurrentLocation: false}));
          }
        });

    // this.store.dispatch(new FetchDefis({ params: { }}));

    this.store.dispatch(new WatchCompass());

    this.currentLocationListener();

  }

  @HostListener('click') onClick(){
    this.tooltip?.hide();
  }

  ngOnDestroy() {
    // force unsubscribe
    this.destroy$.next(true);
    // Now let's also unsubscribe from the subject itself:
    this.destroy$.unsubscribe();
  }

  degreesToRadians(degrees) {
    return degrees * Math.PI / 180;
  }

  distanceInMetersBetweenEarthCoordinates(lat1, lon1, lat2, lon2) {
    const earthRadiusKm = 6371;

    const dLat = this.degreesToRadians(lat2 - lat1);
    const dLon = this.degreesToRadians(lon2 - lon1);

    lat1 = this.degreesToRadians(lat1);
    lat2 = this.degreesToRadians(lat2);

    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return earthRadiusKm * c * 1000;
  }

  currentLocationListener() {
    combineLatest(this.currentLocation$, this.centralizeCurrentLocation$).pipe(takeUntil(this.destroy$))
        .subscribe(([currentLocation, centralizeCurrentLocation]) => {
          if (!this.isCurrentLocation && (currentLocation.latitude !== 32.088279 && currentLocation.longitude !== 34.795283)) {
            this.isCurrentLocation = true;
            this.store.dispatch(new WatchLocation({
              success: (position: { latitude: number; longitude: number; }) => {
                // console.log(position.latitude + ', ' + position.longitude);
                this.currentLocationCoordinates = position;
              },
              error: (err) => {
                console.log(err);
              }
            }));
          } else if (currentLocation.latitude === 32.088279 && currentLocation.longitude === 34.795283) {
            this.isCurrentLocation = false;
          }
          if (!!centralizeCurrentLocation && !!currentLocation) {
            this.map.flyTo({
              zoom: 16,
              center: [currentLocation?.longitude, currentLocation?.latitude],
              speed: 2
            });
          }
        });
  }

  selectDefi(defi: Defi) {
    if (defi) {
      this.store.dispatch(new SetSelectedDefi({ defi }));
      this.store.dispatch(new SetIsSidenavOpen({ isSidenavOpen: true }));
      this.store.select(selectNavigationStep).pipe(
          take(1),
          withLatestFrom(this.store.select(selectMapLocation))
      ).subscribe( ([navigationStep, mapLocation]) => {
        if (mapLocation && navigationStep && navigationStep === NavigationStep.overview) {
          this.store.dispatch(new FetchGoogleNavigationRoute({
            origin: mapLocation,
            destination: defi?.coordinates?.geopoint
          }));
          this.store.dispatch(new FetchNavigationRoute({
            origin: mapLocation,
            destination: defi?.coordinates?.geopoint
          }));
          this.store.dispatch(new SetNavigationStep({ step: NavigationStep.overview }));
        }
      });
    }
  }

  resetMap() {
    this.store.select(selectMapLocation)
        .pipe(take(1))
        .subscribe(mapLocation => {
          this.compassBearing = false;
          // this.store.dispatch(new StopWatchLocation());
          this.store.dispatch(new SetSelectedDefi(({ defi: null })));
          this.store.dispatch(new SetMapBounds({ bounds: null }));
          this.store.dispatch(new SetNavigationStep({ step: NavigationStep.notNavigating }));
          this.store.dispatch(new SetNavigationType({ type: NavigationType.walking }));
          this.store.dispatch(new SetNavigationRoute({ route: null }));
          this.store.dispatch(new SetGoogleNavigationRoute({ route: null }));
          this.store.dispatch(new SetIsSidenavOpen({isSidenavOpen: false}));
          this.map.setCenter({
            lat: mapLocation?.latitude,
            lng: mapLocation?.longitude
          });
          this.store.dispatch(new SetMapPitch({ pitch: 0 }));
          this.store.dispatch(new SetMapBearing({bearing: 0}));
          this.map.setBearing(0);
          this.map.setPitch(0);
        });
  }

  startTrack() {
    this.store.dispatch(new FetchLocation({}));
    this.store.dispatch(new WatchLocation({}));
  }

  stopTrack() {
    this.store.dispatch(new StopWatchLocation());
  }

  centralizeCurrentLocation() {
    combineLatest(this.centralizeCurrentLocation$, this.isTrackUserLocation$, this.navigationStep$).pipe(take(1))
        .subscribe(([centralizeCurrentLocation, isTrackUserLocation, navigationStep]) => {
          if (!centralizeCurrentLocation && navigationStep !== NavigationStep.navigation) {
            this.store.dispatch(new SetCentralizeCurrentLocation({centralizeCurrentLocation: true}));
            if (!isTrackUserLocation) {
              this.store.dispatch(new WatchLocation({}));
            } else {
              this.store.dispatch(new FetchLocation({}));
            }
          } else if (!centralizeCurrentLocation && navigationStep === NavigationStep.navigation) {
            this.compassBearing = true;
            // this.store.dispatch(new SetCentralizeCurrentLocation({centralizeCurrentLocation: true}));
            this.map?.flyTo({
              zoom: 16,
              center: [
                this.currentLocationCoordinates.longitude,
                this.currentLocationCoordinates.latitude
              ],
              animate: false,
              speed: 0});
            this.map?.panBy([0, -120], {
              animate: false
            });
          }
        });
  }

  // onDragEnd(event) {
  //   this.store.dispatch(new FetchDefis({
  //     params: {
  //       position: {
  //         latitude: this.map?.getCenter()?.lat,
  //         longitude: this.map?.getCenter()?.lng,
  //       }
  //     }
  //   }));
  // }

  onDragStart(event) {
    this.store.dispatch(new SetCentralizeCurrentLocation({ centralizeCurrentLocation: false }));
    this.compassBearing = false;
  }

  onZoomStart(event) {
    if (!!event?.originalEvent) {
      this.store.dispatch(new SetCentralizeCurrentLocation({ centralizeCurrentLocation: false }));
    }
  }

  mobileCloseBottomSheet() {
    if (this.isMobile) {
      this.isSidenavOpen$.pipe(
        take(1)
      ).subscribe( isSidenavOpen => {
        if (isSidenavOpen) {
          this.store.dispatch(new SetIsSidenavOpen({isSidenavOpen: false}));
        }
      });
    }
  }

  onPinMarkerDrag(marker: Marker) {
    if (marker) {
      this.store.dispatch(new SetMapLocation({
        location: {
          longitude: marker?.getLngLat()?.lng,
          latitude: marker?.getLngLat()?.lat
        }
      }));
      this.store.dispatch(new SetIsSidenavOpen({ isSidenavOpen : true }));
      this.store.dispatch(new FetchNearestDefi({
          params: {
              position: {
                longitude: marker?.getLngLat()?.lng,
                latitude: marker?.getLngLat()?.lat
              }
          }
      }));
      this.store.select(selectSelectedDefi).pipe(take(1))
          .subscribe(selectedDefi => {
            if (!!selectedDefi && !!marker) {
              this.store.dispatch(new FetchGoogleNavigationRoute({
                origin: {
                  longitude: marker?.getLngLat()?.lng,
                  latitude: marker?.getLngLat()?.lat,
                },
                destination: selectedDefi?.coordinates?.geopoint
              }));
              this.store.dispatch(new FetchNavigationRoute({
                origin: {
                  longitude: marker?.getLngLat()?.lng,
                  latitude: marker?.getLngLat()?.lat,
                },
                destination: selectedDefi?.coordinates?.geopoint
              }));
            }
          });
      this.store.dispatch(new SetNavigationStep({ step: NavigationStep.overview }));
    }
  }

  toTheNorth() {
    this.compassBearing = false;
    this.store.dispatch(new SetMapPitch({ pitch: 0 }));
    this.store.dispatch(new SetMapBearing({bearing: 0}));
    this.map.setBearing(0);
    this.map.setPitch(0);
  }
  // // #TAKEN FROM OVERLAY MOBILE:
  // navToNearestDefi() {
  //   if (typeof DeviceOrientationEvent?.requestPermission === 'function' && !navigator.userAgent.match('CriOS')){
  //     this.store.dispatch(new WatchCompassIOS13());
  //   }
  //   this.store.dispatch(new FetchLocation({
  //     success: (position: { latitude: number; longitude: number; }) => {
  //       if (typeof DeviceOrientationEvent?.requestPermission === 'function'){
  //         this.store.dispatch(new WatchCompassIOS13());
  //       }

  //       this.store.dispatch(new SetCurrentLocation({
  //         location: position
  //       }));
  //       this.store.dispatch(new SetMapLocation({
  //         location: position
  //       }));
  //       this.store.dispatch(new SetNavigationStep({
  //         step: NavigationStep.overview
  //       }));
  //       this.store.dispatch(new FetchNearestDefi({
  //         params: { position }
  //       }));
  //     },
  //     error: () => {
  //       this.store.dispatch(new SetNavigationStep({
  //         step: NavigationStep.notNavigating
  //       }));
  //     }
  //   }));
  // }
// # OLD
  navToNearestDefi() {
    console.log('starting navToNearestDefi');
    if (this.isCurrentLocation) {
      console.log('this.isCurrentLocation is true');
      const position = {latitude: this.currentLocationCoordinates.latitude, longitude: this.currentLocationCoordinates.longitude};
      this.store.dispatch(new SetCurrentLocation({
        location: position
      }));
      this.store.dispatch(new SetMapLocation({
        location: position
      }));
      this.store.dispatch(new SetNavigationStep({
        step: NavigationStep.overview
      }));
      this.store.dispatch(new FetchNearestDefi({
        params: { position }
      }));
    } else {
      this.store.dispatch(new SetNavigationStep({
        step: NavigationStep.notNavigating
      }));
    }
    // this.store.dispatch(new FetchLocation({
    //   success: (position: { latitude: number; longitude: number; }) => {
    //     this.store.dispatch(new SetCurrentLocation({
    //       location: position
    //     }));
    //     this.store.dispatch(new SetMapLocation({
    //       location: position
    //     }));
    //     this.store.dispatch(new SetNavigationStep({
    //       step: NavigationStep.overview
    //     }));
    //     this.store.dispatch(new FetchNearestDefi({
    //       params: { position }
    //     }));
    //   },
    //   error: () => {
    //     this.store.dispatch(new SetNavigationStep({
    //       step: NavigationStep.notNavigating
    //     }));
    //   }
    // }));
  }

  changeStyle(){
    this.mapStyle$.pipe(take(1))
        .subscribe(mapStyle => {
          if (mapStyle === MapStyle.regular) {
            this.store.dispatch(new SetMapStyle(({ style: MapStyle.satellite })));
          } else {
            this.store.dispatch(new SetMapStyle(({ style: MapStyle.regular })));
          }
        });
  }

  call() {
    this.store.select(selectSelectedDefi).pipe(take(1))
        .subscribe( selectedDefi => {
          if (selectedDefi) {
            const confirmDialogRef = this.dialog.open(CallDialogComponent, {
              // width: '250px',
              // height: '150px',
              panelClass: 'paddingless-dialog-container'
            });
          }
        });
  }

  exportNav() {
    this.selectedDefi$?.pipe(
        take(1),
        withLatestFrom(this.mapLocation$)
    ).subscribe( ([selectedDefi, mapLocation]) => {
      if (!!selectedDefi && !!mapLocation) {
        const confirmDialogRef = this.dialog.open(ShareDialogComponent, {
          data: { selectedDefi, currentLocation: mapLocation},
          width: '250px',
          // height: '180px',
          panelClass: 'paddingless-dialog-container'
        });
      }
    });
  }

  whatsappBtn(){
    this.mapLocation$.pipe(take(1))
        .subscribe(location => this.sendToWhatsapp(location));
  }

  sendToWhatsapp(location: {
    latitude: number;
    longitude: number;
  }){
    let url = 'ניווט אל הדפיברילטור הקרוב ביותר אליך:';
    url = url.concat('\n\n').concat('https://med-man.co.il/?lat='
        + location?.latitude + 'lng='
        + location?.longitude);
    window.open('https://api.whatsapp.com/send?text=' + encodeURIComponent(url), '_blank');
  }

  changeNavigationType(navigationType: NavigationType) {
    this.store.dispatch(new SetNavigationType({
      type: navigationType
    }));
  }

  async startNavigation() {
    // this.currentLocation$.subscribe(currentLocation => {
    //   const zoomCorrection = Math.pow(2, this.map.getZoom()) / Math.pow(2, 16);

    //   const lat = currentLocation?.longitude;
    //   const lng = currentLocation?.latitude;
    //   const coordinates = this.map.project([lat, lng]);
    //   const offsetcoordinates = this.map.unproject([coordinates.x, coordinates.y - (100 * zoomCorrection)]);
    //   this.map.flyTo({
    //     animate: true,
    //     zoom: 16,
    //     center: [offsetcoordinates.lng, offsetcoordinates.lat]
    //   });
    // });

    if (typeof DeviceOrientationEvent?.requestPermission === 'function'){
      await this.store.dispatch(new WatchCompassIOS13());
    }

    await this.realEvent();

    this.store.dispatch(new SetNavigationStep({
      step: NavigationStep.navigation
    }));
  }

  toggleSidenav() {
    this.isSidenavOpen$.pipe(
        take(1)
    ).subscribe( isSidenavOpen => {
      this.store.dispatch(new SetIsSidenavOpen({isSidenavOpen: !isSidenavOpen}));
    });
  }

  realEvent() {
    console.log('realevent');

    return new Promise<void>((resolve, reject) => {
      const realEventDialogRef = this.dialog.open(MdaDialogComponent, {
        panelClass: 'paddingless-dialog-container'
      });
      setTimeout(() => {
        realEventDialogRef.close();
      }, 5200);
      try {
        realEventDialogRef.afterClosed().subscribe(() => {
          console.log('mda close');
          resolve();
        });
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });
  }

  onMapLoad(mapinstance) {

    this.map = mapinstance;
    this.map.resize();
    this.isMobile$.pipe(
      take(1)
    ).subscribe( isMobile => {
      this.isMobile = isMobile;
      if (!isMobile) {
        this.add3DbuildingLayer();
        document.querySelector('.mapboxgl-ctrl-attrib').setAttribute('style', 'display: block !important; padding: 0 !important;');
        document.querySelector('.mapboxgl-ctrl-bottom-right').setAttribute('style', 'margin: 0 !important;');
      } else {
        document.querySelector('.mapboxgl-ctrl-bottom-left > :last-child').setAttribute('style', 'height: 0 !important; padding: 0 !important;');
      }
    });
    // landing animation
    this.isOverlayOpen$.pipe(takeUntil(this.destroy$)).subscribe((open) => {
      if (open) {
        this.map.flyTo({
          animate: true,
          zoom: 13,
          center: [34.822685, 32.114651],
          speed: 0.007
        });
      } else {
        this.map.flyTo({
        });
        if (!this.cookieService.get('license')) {
          this.renderer.addClass(this.license.nativeElement, 'openLicense');
        }
      }
    });

    // webGL marker layer - fast and beautiful (Ariel)
    this.markersLayerOnMapLoad();
    combineLatest(this.isSidenavOpen$, this.isMobile$).pipe(
      skip(1),
      takeUntil(this.destroy$)
    ).subscribe(([isOpen, isMobile]) => {
      if (!isMobile) {
        if (isOpen) {
          this.map.panBy([200, 0], {animate: true});
        } else if (!isOpen) {
          this.map.panBy([-200, 0], {animate: true});
        }
      }
    });

  }

  markersLayerOnMapLoad() {
    this.updateDefis();
    this.map.on('moveend', (e) => {
      this.isMapNorth = this.map.getBearing() === 0;
      if (this.navigationStep !== NavigationStep.navigation) {
        // if (!!e?.originalEvent) {
          const lat = this.map.getCenter().lat;
          const lng = this.map.getCenter().lng;
          this.updateDefis();
      }
    });
    this.map.on('style.load', (ev) => {
      this.updateDefis();
    });

    this.firestoreService.getIsDefisArrLoaded$()
    .pipe(
      takeUntil(this.destroy$)
    ).subscribe(isLoaded => {
    if (isLoaded) {
      this.subscribeDefis();
    }
  });

    this.map.on('click', 'defiLayer', (e) => {
      e.preventDefault();
      const clickedID = e.features[0].properties.id;
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.visibleDefisMain.length; i++) {
        if (this.visibleDefisMain[i].id === clickedID) {
          // this.visibleDefisMain[i].selected = true;
          this.selectDefi(this.visibleDefisMain[i]);
          this.selectedDefi = this.visibleDefisMain[i];
          this.drawDefisLayer(this.visibleDefisMain);

          const zoomCorrection = Math.pow(2, this.map.getZoom()) / Math.pow(2, 16);

          const lng = this.selectedDefi.coordinates.geopoint.longitude;
          const lat = this.selectedDefi.coordinates.geopoint.latitude;
          const coordinates = this.map.project([lng, lat]);
          const offsetcoordinates = this.map.unproject(
            [
              coordinates.x + (this.isMobile ? 0 : (200 * zoomCorrection)),
              coordinates.y + (this.isMobile ? (90 * zoomCorrection) : 0 )
            ]);
          this.map.flyTo({
            animate: true,
            zoom: 16,
            center: [offsetcoordinates.lng, offsetcoordinates.lat]
          });

        } else {
          this.visibleDefisMain[i].selected = false;
        }
      }
      // this.isTrackUserLocation = false;
    });

    this.map.on('click', (e) => {
      if (this.isMobile && e.defaultPrevented === false) {
        this.isSidenavOpen$.pipe(
          take(1)
        ).subscribe( isSidenavOpen => {
          if (isSidenavOpen) {
            this.store.dispatch(new SetIsSidenavOpen({isSidenavOpen: false}));
          }
        });
      }
    });
  }

  subscribeDefis() {
    this.firestoreService.getDefisInRadiosArr$()
    .pipe(
      takeUntil(this.destroy$)
    ).subscribe(defis => {
      this.visibleDefisMain = defis;
      if (this.selectedDefi) {
        let found = false;
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.visibleDefisMain.length; i++) {
          if (this.visibleDefisMain[i].id === this.selectedDefi.id) {
            this.visibleDefisMain[i].selected = true;
            found = true;
          }
        }
        if (!found) {
          this.selectedDefi = null;
        }
      }
      this.drawDefisLayer(defis);
  });
  }

  updateDefis() {
    const lat = this.map.getCenter().lat;
    const lng = this.map.getCenter().lng;
    this.lastFetchLat = lat;
    this.lastFetchLng = lng;
    this.firestoreService.updateDefisByRadius(lat, lng);
  }

  drawDefisLayer(defis) {
    console.log('drawing');
    const defisArr = [];
    // create GEOJson
    for (const defi of defis) {
      // create geoJson item for each traffic
      const coordinates = [defi.coordinates.geopoint.longitude, defi.coordinates.geopoint.latitude];
      const point =
                  {geometry: { type: 'Point',
                    coordinates: coordinates as GeoJSON.Position
                  } as Point,
                  properties: {...defi}
                } as Feature;
      defisArr.push(point);
    }

    const featureCollection =  {
      type: 'FeatureCollection',
      features: defisArr
    } as FeatureCollection;

    if (this.map.getSource('defiSource') === undefined) {
      this.map.addSource('defiSource', {
        type: 'geojson',
        data: featureCollection
      });

      this.map.addLayer({
        id: 'defiLayer',
        type: 'symbol',
        source: 'defiSource',
        minzoom: 10.1,
        layout: {
          'icon-image': [
            'case',
            ['==', ['get', 'selected'], true /* conditiond */], [
              // if true
              'case',
              ['all', ['!=', ['get', 'contactPhone'], null], ['!=', ['get', 'contactPhone'], '' /* conditiond */]], [
                'case',
                ['all', ['!=', ['get', 'imageURL'], null], ['!=', ['get', 'imageURL'], '']],
                'marker-photoandphone-selected',
                'marker-phone-selected'
              ],
              [
                // if false
                'case',
                ['all', ['!=', ['get', 'imageURL'], null], ['!=', ['get', 'imageURL'], '']],
                'marker-photo-selected',
                'marker-red-selected'
              ]
            ],
            [
              // if false
              // if true
              'case',
              ['all', ['!=', ['get', 'contactPhone'], null], ['!=', ['get', 'contactPhone'], ''] /* conditiond */], [
              'case',
              ['all', ['!=', ['get', 'imageURL'], null], ['!=', ['get', 'imageURL'], '']],
              'marker-photoandphone',
              'marker-phone'
            ],
              [
                // if false
                'case',
                ['all', ['!=', ['get', 'imageURL'], null], ['!=', ['get', 'imageURL'], '']],
                'marker-photo',
                'marker-red'
              ]
            ]
          ],
          'icon-allow-overlap': true,
          'icon-offset': [0, 0],
          'icon-anchor': 'bottom',
          'icon-size': [
            'interpolate',
            ['linear'],
            ['zoom'],
            10, ['case', ['==', ['get', 'selected'], true], 0.8, 0.5],
            20, ['case', ['==', ['get', 'selected'], true], 2.4, 1.5]
          ]
        },
        paint: {}

      },
      // '3d-buildings'
      );
    } else {
      const source: mapboxgl.GeoJSONSource = this.map.getSource('defiSource') as mapboxgl.GeoJSONSource;
      source.setData(featureCollection);
    }
  }

  test(e) {
    window.onmouseup = (e2) => {
      const diff = e2.timeStamp - e.originalEvent.timeStamp;
      // console.log(diff);
      window.onmouseup = null;
    };
  }

  add3DbuildingLayer() {
    // 3D buildings
    this.map.addLayer(
      {
          id: '3d-buildings',
          source: 'composite',
          'source-layer': 'building',
          filter: ['==', 'extrude', 'true'],
          type: 'fill-extrusion',
          minzoom: 15,
          paint: {
              'fill-extrusion-color': '#aaa',

              // use an 'interpolate' expression to add a smooth transition effect to the
              // buildings as the user zooms in
              'fill-extrusion-height': [
                  'interpolate',
                  ['linear'],
                  ['zoom'],
                  15,
                  0,
                  15.05,
                  ['get', 'height']
              ],
              'fill-extrusion-base': [
                  'interpolate',
                  ['linear'],
                  ['zoom'],
                  15,
                  0,
                  15.05,
                  ['get', 'min_height']
              ],
              'fill-extrusion-opacity': 0.6
          }
      },
    );
  }

  getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    const R = 6371; // Radius of the earth in km
    const dLat = this.deg2rad(lat2 - lat1);  // deg2rad below
    const dLon = this.deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2)
      ;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    return d;
  }

  deg2rad(deg) {
    return deg * (Math.PI / 180);
  }

  closeLicense() {
    this.cookieService.set('license', Date.now().toString());
    this.renderer.removeClass(this.license.nativeElement, 'openLicense');
   }
}
