import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FirestoreEffects } from './store/firestore.effects';
import { firestoreReducer } from './store/firestore.reducer';
import {FirestoreService} from './service/firestore.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('firestore', firestoreReducer),
    EffectsModule.forFeature([FirestoreEffects]),
  ]
})
export class FirestoreModule {}
