<mat-drawer-container class="drawer-container" [ngClass]="{'mobile': (this.isMobile$ | async)}">
    <mat-drawer
        #drawer
        class="drawer"
        position="start"
        dir="rtl"
        [attr.role]="(isMobile$ | async) ? 'dialog' : 'navigation'"
        [mode]="(isMobile$ | async) ? 'over' : 'side'"
        >
        <app-map-details></app-map-details>
    </mat-drawer>
    <mat-drawer-content>
        <app-ng-map #map id="mapPage">
        </app-ng-map>
    </mat-drawer-content>
</mat-drawer-container>
