import {ActionTypes, MapActions, SetCentralizeCurrentLocation} from './map.actions';
import {initialState} from './map.state';


export function mapReducer(state = initialState, action: MapActions) {
  switch (action.type) {
    case ActionTypes.SET_MAP_STYLE:
      return {
        ...state,
        mapStyle: action.payload.style
      };
    case ActionTypes.SET_MAP_BOUNDS:
      return {
        ...state,
        mapBounds: action.payload.bounds
      };
    case ActionTypes.SET_MAP_PITCH:
      return {
        ...state,
        mapPitch: action.payload.pitch
      };
    case ActionTypes.SET_MAP_BEARING:
      return {
        ...state,
        mapBearing: action.payload.bearing
      };
    case ActionTypes.SET_MAP_ZOOM:
      return {
        ...state,
        mapZoom: action.payload.zoom
      };
    case ActionTypes.SET_MAP_PAN:
      return {
        ...state,
        mapPan: action.payload.pan
      };
    case ActionTypes.SET_SELECTED_DEFI:
      return {
        ...state,
        selectedDefi: action.payload.defi
      };
    case ActionTypes.SET_MAP_LOCATION:
      return {
        ...state,
        mapLocation: action.payload.location
      };
    case ActionTypes.MAP_FAILURE:
      return {
        ...state,
        mapLoading: false,
        mapError: action.payload.errorMessage,
        mapSuccess: null
      };
    case ActionTypes.MAP_SUCCESS:
      return {
        ...state,
        mapLoading: false,
        mapError: null,
        mapSuccess: action.payload.successMessage
      };
    case ActionTypes.MAP_CLEAN:
      return {
        ...state,
        mapLoading: false,
        mapError: null,
        mapSuccess: null
      };
    case ActionTypes.SET_IS_OVERLAY_OPEN:
      return {
        ...state,
        isOverlayOpen: action.payload.isOverlayOpen
      };
    case ActionTypes.SET_CENTRALIZE_CURRENT_LOCATION:
      return {
        ...state,
        centralizeCurrentLocation: action.payload.centralizeCurrentLocation
      };
    case ActionTypes.SET_IS_MOBILE:
      return {
        ...state,
        isMobile: action.payload.isMobile
      };
    case ActionTypes.SET_IS_SIDENAV_OPEN:
      return {
        ...state,
        isSidenavOpen: action.payload.isSidenavOpen
      };
    default:
      return state;
  }
}
