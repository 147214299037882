import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {selectIsMobile} from '../../modules/map/store/map.selectors';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';

@Component({
  selector: 'app-defi-guide',
  templateUrl: './guide.component.html',
  styleUrls: ['./guide.component.scss']
})
export class GuideComponent implements OnInit {

  isMobile$: Observable<boolean>;

  lang = 'he';
  direction = 'ltr';
  titleText = 'מדריך שימוש בדפיברילטור';
  generalText = 'מכשיר הדפיברילטור נועד להצלת חיים במקרה של אירוע לב. זהו למעשה הטיפול היעיל ביותר לאדם הלוקה באירוע קטלני והזמן עד מתן השוק הינו קריטי. עם זאת, השימוש במכשיר לא יכול להחליף את עבודתו של מטפל מוסמך.';
  warningText1 = '';
  warningText2 = '';
  instructionsTitle = '';

  instructionDetailTitle1 = '';
  instructionDetail1_line1 = '';
  instructionDetail1_line2 = '';
  instructionDetail1_line3 = '';

  instructionDetailTitle2 = '';
  instructionDetail2_line1 = '';
  instructionDetail2_line2 = '';
  instructionDetail2_line3 = '';

  instructionDetailTitle3 = '';
  instructionDetail3_line1 = '';
  instructionDetail3_line2 = '';

  infoText = '';

  safetyTitle = '';
  safety_line1 = '';
  safety_line2 = '';
  safety_line3 = '';
  safety_line4 = '';

  constructor(
      public router: Router,
      private store: Store
  ) {}

  ngOnInit() {
    this.setLang('he');
    this.isMobile$ = this.store.select(selectIsMobile);
  }


  setLang(lang) {
    if (lang === 'he') {
      this.lang = 'he';
      this.direction = 'rtl';
      this.titleText = 'מדריך שימוש בדפיברילטור';
      this.generalText = 'מכשיר הדפיברילטור נועד להצלת חיים במקרה של אירוע לב. זהו למעשה הטיפול היעיל ביותר לאדם הלוקה באירוע קטלני והזמן עד מתן השוק הינו קריטי. עם זאת, השימוש במכשיר לא יכול להחליף את עבודתו של מטפל מוסמך.';
      this.warningText1 = 'במקרה שאדם איבד הכרה:';
      this.warningText2 = 'לפני הכל - יש להתקשר למוקד החירום (101).';
      this.instructionsTitle = 'הוראות:';
      this.instructionDetailTitle1 = 'הכנת הסביבה';
      this.instructionDetail1_line1 = 'א. הצב את מכשיר הדפיברילטור בסמוך למטופל';
      this.instructionDetail1_line2 = 'ב. הדלק את המכשיר';
      this.instructionDetail1_line3 = 'ג. חשוף ויבש את בית החזה של המטופל';

      this.instructionDetailTitle2 = 'הדבקת הפדים';
      this.instructionDetail2_line1 = 'א. בחר את הפדים המתאימים למטופל - ילד או מבוגר';
      this.instructionDetail2_line2 = 'ב. הסר את כיסוי הדבק';
      this.instructionDetail2_line3 = 'ג. הדבק היטב את הפדים לעור חזה המטופל על פי האיור שבגב המדבקה, כך שהלב של המטופל יימצא בין הפדים';

      this.instructionDetailTitle3 = 'חיבור לדפיברילטור';
      this.instructionDetail3_line1 = 'א. חבר את התקע של הפדים לשקע המהבהב במכשיר';
      this.instructionDetail3_line2 = 'ב. עקוב אחר ההוראות המדוברות של המכשיר';

      this.infoText = 'לאחר שהמכשיר יעריך את מצב המטופל הוא עשוי להמליץ על מתן שוק. מתן שוק יתבצע בלחיצה על הכפתור המהבהב.';

      this.safetyTitle = 'דגשי בטיחות';
      this.safety_line1 = 'א. מתן שוק יתבצע רק לאדם חסר הכרה.';
      this.safety_line2 = 'ב. יש ליבש את חזה המטופל ולהדביק את המדבקות היטב לעור.';
      this.safety_line3 = 'ג. במקרה של ריבוי שיער במיקום המדבקה יש להסירו עם סכין הגילוח או לתלוש עם מדבקה.';
      this.safety_line4 = 'ד. יש להתרחק מהמטופל בעת מתן השוק החשמלי.';

    } else if (lang === 'en') {
      this.lang = 'en';
      this.direction = 'ltr';
      this.titleText = 'AED Usage Guide';
      this.generalText = 'The AED (automated external defibrillator) is designed to save lives in the case of a cardiac arrest and the time until the shock is delivered is critical. That being said, the AED is no substitute for the treatment of a certified attendant.';
      this.warningText1 = 'If a man losses consciousness:';
      this.warningText2 = 'Before anything - call the emergency call center (101).';
      this.instructionsTitle = 'Instructions:';
      this.instructionDetailTitle1 = 'Set up your surroundings';
      this.instructionDetail1_line1 = 'a. Place the AED in a safe place near the patient';
      this.instructionDetail1_line2 = 'b. Turn on the AED';
      this.instructionDetail1_line3 = 'c. Expose and dry the patient\'s chest';

      this.instructionDetailTitle2 = 'Placing the pads';
      this.instructionDetail2_line1 = 'a. Pick the appropriate pads for the patient: child or adult';
      this.instructionDetail2_line2 = 'b. Remove the pads\' adhesive backing material';
      this.instructionDetail2_line3 = 'c. Apply the pads firmly on the patient’s bare chest as illustrated on the back of the pads';

      this.instructionDetailTitle3 = 'Connecting the deffibrilator';
      this.instructionDetail3_line1 = 'a. Plug in the pads\' connector near the flashing light';
      this.instructionDetail3_line2 = 'b. Follow the AED voice instructions';

      this.infoText = 'After it assesses the patient’s condition, the AED may suggest delivering a controlled shock. Delivering a shock is done by pressing the flashing button.';

    } else if (lang === 'ar') {
      this.lang = 'ar';
      this.direction = 'rtl';
      this.titleText = 'دليل استعمال مزيل الرجفان الآلي';
      this.generalText = 'جهاز مزيل الرجفان يهدف إلى إنقاذ الأشخاص في حال حدوث نوبة قلبية. في الواقع هُو العلاج الأكثر فعالية لشخص يُعاني من نوبة قلبية، حيث أن الفترة الزمنية في حال بِداية النوبة وحتى الضربة الكهربائية هي مهمة جدا. ومع ذلك، استخدام الجهاز لا يمكن أن يستبدلعمل المعالجين المؤهلين.';
      this.warningText1 = 'في حال غاب الشخص عن الوعي:';
      this.warningText2 = 'أولاً - يرجى الاتصال بخط الطوارئ 101.';
      this.instructionsTitle = 'تعليمات:';
      this.instructionDetailTitle1 = 'إعداد البيئة';
      this.instructionDetail1_line1 = 'أ. ضع الجهاز في مكان آمن بالقرب من المريض.';
      this.instructionDetail1_line2 = 'شَغِّل الجِهاز.';
      this.instructionDetail1_line3 = 'إكشِف وجَفف صَدر ألمريض';

      this.instructionDetailTitle2 = 'إلصاق اللاصقات';
      this.instructionDetail2_line1 = 'أ. إختَر أللاصقات المُناسِبَة للمَريض - للأطفال أو للبالغين.';
      this.instructionDetail2_line2 = 'ب. أَزِل الغِلاف عنِ اللاصقات.';
      this.instructionDetail2_line3 = 'ج. ألصق اللاصقات بإِحكام على صَدرِ المريض وفقاً للرَسم المُرفَق في الجِهةِ الخَلفيةِ لِلاصق، بِحَيثُ يَكون قلبُ المريضِ بَينهما.';

      this.instructionDetailTitle3 = 'تَوصيل الجهاز';
      this.instructionDetail3_line1 = 'أ. قُّم بِتَوصيل قابص اللاصقات في مِقْبص الجهاز.';
      this.instructionDetail3_line2 = 'ب. إتْبَع التَعليمات الصوتية التي سَيَطلُبُها الجهاز.';

      this.infoText = 'بعد أن ي‍ُقَّيم الجِهاز حالة المريض من المُرَّجح أن يَطلب إعطاء ضربة كهربائية. عندها يَجِب الضَغط على الزِر الوامِض.';

    } else if (lang === 'ru') {
      this.lang = 'ru';
      this.direction = 'ltr';
      this.titleText = 'Руководство по Эксплуатации АВД';
      this.generalText = 'АВД.(автоматизированный внешний дефибриллятор) является средством для спасения жизни в случае остановки сердца, если время до шока не является критическим. Но, АВД не заменит работу сертифицированного сопровождающего.';
      this.warningText1 = 'Перед тем как что-либо предпринимать:';
      this.warningText2 = 'вызовите центр экстренной помощи (101).';
      this.instructionsTitle = 'Инструкции:';
      this.instructionDetailTitle1 = 'Настройка вашего окружающего пространства';
      this.instructionDetail1_line1 = 'a. Поместите АВД в безопасном месте рядом с пациентом';
      this.instructionDetail1_line2 = 'б. Включите АВД';
      this.instructionDetail1_line3 = 'в. Оголите и осушите грудь пациента';

      this.instructionDetailTitle2 = 'Поместите колодки';
      this.instructionDetail2_line1 = 'a. Выберите соответствующие колодки для пациента: ребенка или взрослого.';
      this.instructionDetail2_line2 = 'б. Удалите адгезивный материал основы колодки.';
      this.instructionDetail2_line3 = 'в. Нанесите колодки плотно на обнажённую грудь пациента, как показано на обратной стороне колодки.';

      this.instructionDetailTitle3 = 'Подключите колодки';
      this.instructionDetail3_line1 = 'a. Подключите разъём колодки рядом с мигающим светом.';
      this.instructionDetail3_line2 = 'б. Следуйте голосовым инструкциям АВД.';

      this.infoText = 'е того, как он оценит состояние пациента, АВД может предложить обеспечить контролируемый шок. Обеспечивание шока осуществляется нажатием на мигающую кнопку';

    }
  }


  openExternalLink(url) {
    window.open(url, '_system');
    return false;
  }
}
