<div id="linksContainer" fxLayout="column" fxLayoutAlign="center center">

    <!--PlayStore-->
    <a
            fxFlex fxLayoutAlign="center center"
        (click)="openExternalLink('https://play.google.com/store/apps/details?id=nhgh.com.medman')" class="link">
        <img class="link-image" src="assets/img/googleplay.png">
    </a>

    <!--AppStore-->
    <a  fxFlex fxLayoutAlign="center center"
        (click)="openExternalLink('https://apps.apple.com/il/app/%D7%90%D7%99%D7%A4%D7%94-%D7%93%D7%A4%D7%99/id1449991175')" class="link">
        <img class="link-image" src="assets/img/appstore.png">
    </a>

    <!--mail-->
    <a
            fxFlex fxLayoutAlign="center center"
            href="mailto:info@defi.co.il" class="link" fxFlex fxLayoutAlign="center center">
        <img class="link-image" src="assets/img/gmail.png">
    </a>

    <!--facebook-->
    <a
            fxFlex fxLayoutAlign="center center"
            (click)="openExternalLink('https://facebook.com/whereisdefi/')" class="link" fxFlex fxLayoutAlign="center center">
        <img class="link-image" src="assets/img/pneisefer.png">
    </a>

    <!--youtube-->
    <a
            (click)="openExternalLink('https://www.youtube.com/playlist?list=PLJPG5J7sCo4ZZKqFWOD6_0BXGDVuxLI-x')" class="link" fxFlex fxLayoutAlign="center center">
        <img class="link-image" src="assets/img/yt.png">
    </a>
</div>
