import { NgModule } from '@angular/core';

import {LocationModule} from './location/location.module';
import {FirestoreModule} from './firestore/firestore.module';
import {NavigationModule} from './navigation/navigation.module';

@NgModule({
    declarations: [],
    imports: [
        LocationModule,
        FirestoreModule,
        NavigationModule
    ]
})
export class CoreModule {}
