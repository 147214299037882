import {Message} from '../../../messages/message.model';
import {NavigationRoute} from '../interfaces/navigation.interfaces';
import {GoogleNavigationRoute} from '../interfaces/google-route.interfaces';

export enum NavigationType {
  walking = 'walking',
  driving = 'driving'
}

export enum NavigationStep {
  notNavigating = 'notNavigating',
  overview = 'overview',
  navigation = 'navigation'
}


export interface NavigationState {
  navigationRoute: NavigationRoute;
  googleNavigationRoute: GoogleNavigationRoute;
  navigationType: NavigationType;
  navigationStep: NavigationStep;
  error: Message;
  success: Message;
  loading: boolean;
}

export const initialState: NavigationState = {
  navigationRoute: null,
  googleNavigationRoute: null,
  navigationType: NavigationType.walking,
  navigationStep: NavigationStep.notNavigating,
  error: null,
  success: null,
  loading: false
};
