import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector
} from '@ngrx/store';
import { LocationState } from './location.state';
import {Message} from '../../../messages/message.model';

const getCurrentLocation = (state: LocationState): { latitude: number; longitude: number; } => state.currentLocation;

const getLocationWatchers = (state: LocationState): number[] => state.locationWatchers;

const getCurrentCompass = (state: LocationState): number => state.currentCompass;

const getError = (state: LocationState): Message => state.error;

const getSuccess = (state: LocationState): Message => state.success;

const getLoading = (state: LocationState): boolean => state.loading;

// selectors
export const selectLocationState: MemoizedSelector<
  object,
  LocationState
> = createFeatureSelector<LocationState>('location');

export const selectCurrentLocation: MemoizedSelector<
    object,
    { latitude: number; longitude: number; }
    > = createSelector(
    selectLocationState,
    getCurrentLocation
);

export const selectLocationWatchers: MemoizedSelector<
    object,
    number[]
    > = createSelector(
    selectLocationState,
    getLocationWatchers
);

export const selectCurrentCompass: MemoizedSelector<
    object,
    number
    > = createSelector(
    selectLocationState,
    getCurrentCompass
);

export const selectError: MemoizedSelector<
    object,
    Message
    > = createSelector(
    selectLocationState,
    getError
);

export const selectSuccess: MemoizedSelector<
    object,
    Message
    > = createSelector(
    selectLocationState,
    getSuccess
);

export const selectLoading: MemoizedSelector<
    object,
    boolean
    > = createSelector(
    selectLocationState,
    getLoading
);

