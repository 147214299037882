import { Component, OnInit } from '@angular/core';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {selectIsMobile} from '../../modules/map/store/map.selectors';
import {Router} from '@angular/router';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  isMobile$: Observable<boolean>;

  constructor(
      public router: Router,
      private store: Store
  ) {}

  ngOnInit() {
    this.isMobile$ = this.store.select(selectIsMobile);
  }

  openExternalLink(url) {
    window.open(url, '_system');
    return false;
  }

}
