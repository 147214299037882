<div id="container">
  <h2>הורדת עותק מעודכן של מיפוי הדפיברילטורים</h2>
  <mat-dialog-content class="mat-typography">
    <br>
    <h3>זכויות יוצרים © אריאל חסידים, 2021 </h3>
    
    <p>היתר שימוש חינם תחת רישיון (Open Data Commons Attribution License (ODC-By
       למידע המפורסם של <b>מסד נתוני הדפיברילטורים של פלטפורמת ״איפה דפי?״</b> ניתן בזאת
        לכל אדם המחזיק בעותק של המידע. היתר זה ניתן באופן בלתי מוגבל לשימוש במידע לצורך
         פרטי ו/או מסחרי, להעתקה, שינוי, הפצה, פרסום, איחוד או מכירה, כפי שמצויין ברישיון 
         המלא: <a href="http://opendatacommons.org/licenses/by/1.0" target="_blank" rel="noopener noreferrer">http://opendatacommons.org/licenses/by/1.0</a>.
    </p>

    <p>על כתב ההיתרים והתנאים הזה שנמצא בשורת ״זכויות יוצרים״ בעותק שאתה מחזיק להיכלל
      בכל עותק חלקי או מלא של המידע או נגזרותיו ואין למחוק אותו. בנוסף, במקרים של שימוש
       חיצוני ו/או הצגה ו/או הפצה של המידע, נגזרותיו או שימוש במידע, יש לעמוד במדיניות
        הייחוס (Attribution) של ״איפה דפי?״: <a href="https://defi.co.il/attribution" target="_blank" rel="noopener noreferrer">https://defi.co.il/attribution</a>. בעל הזכויות של
         ״איפה דפי?״ שומר לעצמו את הזכות לדרוש את הסרת הייחוס בכל עת. שימוש פרטי או
          פנים-ארגוני אינו דורש ייחוס.
   </p>

    <p>המידע מסופק כמות שהוא (AS IS). בעל זכויות היוצרים מסיר מעליו כל חבות או אחריות כפי
       שמצויין בתקנון תנאי השימוש והגבלות של ״איפה דפי?״: ​​<a href="https://defi.co.il/terms" target="_blank" rel="noopener noreferrer">https://defi.co.il/terms</a>.
  </p>
  </mat-dialog-content>
  <br>
  <mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center" >
    <mat-checkbox [(ngModel)]="enabled">אני מסכים</mat-checkbox>
    <div>
      <button mat-flat-button mat-dialog-close>ביטול</button>
      <button mat-flat-button  (click)="openStreamingOpt()" [disabled]="!enabled">
        <mat-icon fontSet="material-icons-round" >rss_feed</mat-icon>
        אפשרויות חיבור נוספות
      </button>
      <button mat-flat-button color="primary" [mat-dialog-close]="true" (click)="firestoreService.downloadDefis()" [disabled]="!enabled">
        <mat-icon fontSet="material-icons-round" >download</mat-icon>
        התחל הורדה
      </button>
    </div>
  </mat-dialog-actions>
  <br>
</div>

