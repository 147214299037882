import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector
} from '@ngrx/store';
import { FirestoreState } from './firestore.state';
import {Message} from '../../../messages/message.model';
import {Defi} from '../interfaces/firestore.interfaces';

const getDefis = (state: FirestoreState): Defi[] => state.defis;

const getError = (state: FirestoreState): Message => state.error;

const getSuccess = (state: FirestoreState): Message => state.success;

const getLoading = (state: FirestoreState): boolean => state.loading;

// selectors
export const selectFirestoreState: MemoizedSelector<
  object,
  FirestoreState
> = createFeatureSelector<FirestoreState>('firestore');

export const selectDefis: MemoizedSelector<
    object,
    Defi[]
    > = createSelector(
    selectFirestoreState,
    getDefis
);

export const selectError: MemoizedSelector<
    object,
    Message
    > = createSelector(
    selectFirestoreState,
    getError
);

export const selectSuccess: MemoizedSelector<
    object,
    Message
    > = createSelector(
    selectFirestoreState,
    getSuccess
);

export const selectLoading: MemoizedSelector<
    object,
    boolean
    > = createSelector(
    selectFirestoreState,
    getLoading
);

