import {Message} from '../../../messages/message.model';
import {Defi} from '../../../core/firestore/interfaces/firestore.interfaces';
import {defaultLocation} from '../../../core/location/consts/location.consts';
import {LngLat, LngLatBounds} from 'mapbox-gl';

export enum MapStyle {
  regular = 'regular',
  satellite = 'satellite'
}

export interface MapState {
  mapStyle: MapStyle;
  mapBounds: LngLatBounds;
  mapPitch: number;
  mapBearing: number;
  mapZoom: number;
  mapPan: number[];
  selectedDefi: Defi;
  isSidenavOpen: boolean;
  isOverlayOpen: boolean;
  centralizeCurrentLocation: boolean;
  isMobile: boolean;
  mapLocation: {
    latitude: number,
    longitude: number
  };
  mapLoading: boolean;
  mapError: Message;
  mapSuccess: Message;
}

export const initialState: MapState = {
  mapStyle: MapStyle.regular,
  mapBounds: null,
  mapPitch: 0,
  mapBearing: 0,
  mapZoom: 13,
  mapPan: [0, 0],
  selectedDefi: null,
  isSidenavOpen: false,
  isOverlayOpen: false,
  centralizeCurrentLocation: false,
  isMobile: false,
  mapLocation: {
    latitude: defaultLocation.latitude,
    longitude: defaultLocation.longitude
  },
  mapLoading: false,
  mapError: null,
  mapSuccess: null
};
