import {AfterViewInit, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Store} from '@ngrx/store';
import * as fromAppState from '../../../../../store/app.state';
import {Observable, Subject} from 'rxjs';
import {SetIsOverlayOpen, SetMapLocation} from '../../../store/map.actions';
import {selectIsMobile} from '../../../store/map.selectors';
import {take} from 'rxjs/operators';
import * as fromMapSelectors from '../../../store/map.selectors';
import * as fromLocationSelectors from '../../../../../core/location/store/location.selectors';
// import {MatTooltip} from '@angular/material/tooltip';
import {WatchLocation} from '../../../../../core/location/store/location.actions';
import { Router } from '@angular/router';
import { FirestoreService } from 'src/app/core/firestore/service/firestore.service';
import { MatDialog } from '@angular/material/dialog';

declare var google: any;

@Component({
  selector: 'app-map-overlay',
  templateUrl: './map-overlay.component.html',
  styleUrls: ['./map-overlay.component.scss']
})
export class MapOverlayComponent implements OnInit, OnDestroy, AfterViewInit {
  searchTerm = new FormControl('');
  searchTerm$ = new Subject<string>();
  searchResults: Array<any> = [];
  destroy$: Subject<boolean> = new Subject<boolean>();
  geocoder: any;
  currentLocation$: Observable<{ latitude: number; longitude: number; }>;

  mapLocation$: Observable<{
    latitude: number;
    longitude: number;
  }>;
  isMobile$: Observable<boolean>;
  // @ViewChild('whatsappTooltip') tooltip: MatTooltip;

  constructor(private store: Store<fromAppState.AppState>,
              private cd: ChangeDetectorRef,
              public router: Router,
              public firestoreService: FirestoreService,
              public dialog: MatDialog) { }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  ngOnInit(): void {
    this.isMobile$ = this.store.select(selectIsMobile);
    this.currentLocation$ = this.store.select(fromLocationSelectors.selectCurrentLocation);
    this.mapLocation$ = this.store.select(fromMapSelectors.selectMapLocation);

    this.store.dispatch(new SetIsOverlayOpen({
      isOverlayOpen: true
    }));
  }

  @HostListener('click') onClick(){
    // this.tooltip.hide();
  }
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (this.dialog.openDialogs.length === 0) {
      this.close();
    }
  }

  ngAfterViewInit() {
    this.cd.detectChanges();
    // setTimeout(() => this.tooltip.show(2000));
  }

  close(){
    this.store.dispatch(new SetIsOverlayOpen({
      isOverlayOpen: false
    }));
    this.store.dispatch(new WatchLocation({}));
  }

  aroundMe(){
    // navigator.geolocation.getCurrentPosition(this.setCurrentLocation);
    this.currentLocation$.subscribe(currentLocation => {
      this.setCurrentLocation(currentLocation);
    });
    this.close();
  }

  setCurrentLocation(position){
    this.store.dispatch(new SetMapLocation({
      location: {
        latitude: position.latitude,
        longitude: position.longitude
      }
    }));
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({behavior: 'smooth'});
  }

  openDownloadDialog() {
    const dialogRef = this.dialog.open(OpenUseDialogComponent);
  }

  openExternalLink(url) {
    window.open(url, '_system');
    return false;
  }

}

@Component({
  selector: 'app-open-use-dialog',
  templateUrl: './open-use-dialog.component.html',
  styleUrls: ['./open-use-dialog.component.scss']
})

export class OpenUseDialogComponent {
  enabled = false;
  constructor(public firestoreService: FirestoreService) { }
  openExternalLink(url) {
    window.open(url, '_system');
    return false;
  }

  public openStreamingOpt() {
    this.openExternalLink('https://docs.google.com/document/d/1Y2AM2LYyViFtScLBa_p081xoCsdk88YtkZ8lhMDYA7E/edit')
  }
}
