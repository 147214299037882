import { NgModule } from '@angular/core';

import {MapRoutingModule} from './map.routing.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {SharedModule} from '../../shared/shared.module';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {MapEffects} from './store/map.effects';
import {mapReducer} from './store/map.reducer';
import {MAP_STORE_KEY} from './consts/map.constants';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import {CoreModule} from '../../core/core.module';
import {ShareDialogComponent} from '../../components/share-dialog/share-dialog.component';
import {CallDialogComponent} from '../../components/call-dialog/call-dialog.component';
import {OverlayModule} from '@angular/cdk/overlay';
import {MapPageComponent} from './components/map-page/map-page.component';
import {MapDetailsComponent} from './components/map-details/map-details.component';
import {OpenImageComponent} from '../../components/open-image/open-image.component';
import {MapOverlayComponent, OpenUseDialogComponent} from './components/map-overlay/map-overlay-desktop/map-overlay.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {GooglePlacesComponent} from '../../components/google-places/google-places.component';
import {NgMapComponent} from './components/ng-map/ng-map.component';
import {MapOverlayMobileComponent} from './components/map-overlay/map-overlay-mobile/map-overlay-mobile.component';
import { MapDetailsBottomComponent} from './components/map-details-bottom/map-details-bottom.component';
import {ModalModule} from 'ngb-modal';
import {HAMMER_GESTURE_CONFIG, HammerModule} from '@angular/platform-browser';
import {LocationStrategy, PathLocationStrategy} from '@angular/common';
import {AppModule} from '../../app.module';
import {LinksFabComponent} from '../../components/links-fab/links-fab.component';
import { MapOverviewHeaderComponent } from './components/map-overview-header/map-overview-header.component';
import { MatRippleModule } from '@angular/material/core';

@NgModule({
    imports: [
        MatRippleModule,
        CoreModule,
        SharedModule,
        FlexLayoutModule,
        OverlayModule,
        ReactiveFormsModule,
        FormsModule,
        MapRoutingModule,
        HammerModule,
        ModalModule,
        StoreModule.forFeature(MAP_STORE_KEY, mapReducer),
        EffectsModule.forFeature([MapEffects]),
        NgxMapboxGLModule.withConfig({
            accessToken: 'pk.eyJ1IjoiYXJpZWxoYXNpZGltIiwiYSI6ImNqY240eXM3bDB1NWsycW85Z2E3MTh0eXQifQ.9E-yO08LtuRnrGNFCDc5yw',
        }),
    ],
    providers: [
        ],
    exports: [
        MapDetailsComponent,
        MapDetailsBottomComponent,
        MapPageComponent,
        NgMapComponent
    ],
    declarations: [
        MapPageComponent,
        MapDetailsComponent,
        ShareDialogComponent,
        CallDialogComponent,
        MapOverlayComponent,
        OpenUseDialogComponent,
        GooglePlacesComponent,
        NgMapComponent,
        OpenImageComponent,
        MapDetailsBottomComponent,
        MapOverlayMobileComponent,
        LinksFabComponent,
        GooglePlacesComponent,
        MapOverviewHeaderComponent
    ],
    entryComponents: [
        OpenImageComponent,
        OpenUseDialogComponent
    ]
})

export class MapModule {}
