// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  domain: {
    name: 'http://localhost:4200'
  },
  firebaseConfig: {
    apiKey: 'AIzaSyA0Ga3FRWBNGAopotNFnSzO2MFELoIu6mM',
    authDomain: 'eifo-defi.firebaseapp.com',
    databaseURL: 'https://eifo-defi.firebaseio.com',
    projectId: 'eifo-defi',
    storageBucket: 'eifo-defi.appspot.com',
    messagingSenderId: '67586744458'
  },
  mapbox: {
    accessToken: 'pk.eyJ1IjoiYXJpZWxoYXNpZGltIiwiYSI6ImNqcXRvd3UyMDBheWc0M255YjlxbnFoZ28ifQ.bC6bbF-ANoJUwz_lU8gVJA'
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
import 'zone.js/dist/zone-error';  // Included with Angular CLI.
