import { ActionReducerMap } from '@ngrx/store';
import * as fromAppState from './app.state';

import {MAP_STORE_KEY} from '../modules/map/consts/map.constants';
import * as fromMap from '../modules/map/store/map.reducer';


export const appReducer: ActionReducerMap<fromAppState.AppState> = {
  [MAP_STORE_KEY]: fromMap.mapReducer
};
