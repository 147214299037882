import { NavigationActions, ActionTypes } from './navigation.actions';
import { initialState, NavigationState } from './navigation.state';

export function navigationReducer(state = initialState, action: NavigationActions): NavigationState {
  switch (action.type) {
    case ActionTypes.SET_NAVIGATION_ROUTE: {
      return {
        ...state,
        navigationRoute: action.payload.route,
      };
    }
    case ActionTypes.SET_GOOGLE_NAVIGATION_ROUTE: {
      return {
        ...state,
        googleNavigationRoute: action.payload.route,
      };
    }
    case ActionTypes.SET_NAVIGATION_TYPE: {
      return {
        ...state,
        navigationType: action.payload.type,
      };
    }
    case ActionTypes.SET_NAVIGATION_STEP: {
      return {
        ...state,
        navigationStep: action.payload.step,
      };
    }
    case ActionTypes.NAVIGATION_SUCCESS: {
      return {
        ...state,
        error: null,
        success: action.payload.message,
        loading: false
      };
    }
    case ActionTypes.NAVIGATION_FAILURE: {
      return {
        ...state,
        error: action.payload.message,
        success: null,
        loading: false
      };
    }
    case ActionTypes.CLEAR_NAVIGATION: {
      return {
        ...state,
        error: null,
        success: null,
        loading: false
      };
    }
    default: {
      return state;
    }
  }
}
