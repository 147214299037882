import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Defi} from '../../core/firestore/interfaces/firestore.interfaces';
import {Store} from '@ngrx/store';
import {selectSelectedDefi} from '../../modules/map/store/map.selectors';
import {map, take} from 'rxjs/operators';

@Component({
    selector: 'app-call-dialog',
    templateUrl: './call-dialog.component.html',
    styleUrls: ['./call-dialog.component.scss']
})
export class CallDialogComponent implements OnInit , OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();
    title$: Observable<string>;
    selectedDefi$: Observable<Defi>;
    contactName$: Observable<string>;
    contactPhone$: Observable<string>;
    descriptionString$: Observable<string>;

    constructor(
        private store: Store,
        public dialogRef: MatDialogRef<CallDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit() {

        this.selectedDefi$ = this.store.select(selectSelectedDefi);

        this.title$ = this.store.select(selectSelectedDefi).pipe(
            map( defi => {
                return 'האם לחייג ל' + defi?.contactName + ' ?';
            })
        );

        this.contactPhone$ = this.store.select(selectSelectedDefi).pipe(
            map( defi => {
                return this.data.defi.contactPhone;
            })
        );

        this.contactName$ = this.store.select(selectSelectedDefi).pipe(
            map( defi => {
                return defi?.contactName;
            })
        );

        this.contactName$ = this.store.select(selectSelectedDefi).pipe(
            map( defi => {
                return defi?.contactName;
            })
        );

        this.descriptionString$ = this.store.select(selectSelectedDefi).pipe(
            map( defi => {
                return 'האם להתקשר לאיש הקשר של הדיפריבילטור ב' + defi?.locationName + '?';
            })
        );
    }

    call() {
        this.selectedDefi$.pipe(take(1))
            .subscribe( defi => {
                if (defi) {
                    window.location.href = 'tel:' + ('' + defi?.contactPhone).replace(/-/, '');
                }
            });
    }

    close() {
        this.dialogRef.close();
    }


    ngOnDestroy() {
        // force unsubscribe
        this.destroy$.next(true);
        // Now let's also unsubscribe from the subject itself:
        this.destroy$.unsubscribe();
    }
}
