<ng-container *ngIf="!this.isOverlay; else overlay">
    <div fxLayout="row" fxLayoutAlign="end center">
<!--         *ngIf="!(this.isMobile$ | async) || (this.navigationMode$ | async) === this.navigationSteps.notNavigating"-->
<!--    >-->
        <input id="pac-input" class="input"
               type="text"
               [placeholder]="(this.isMobile$ | async) ? 'הקלד כתובת או מיקום לחיפוש' : 'בחר נקודת מוצא לניווט'"
               (keyup.enter)="onEnter($event)"
               [(ngModel)]="autocompleteInput"
               [ngClass]="{
                'desktopsearch': !(this.isMobile$ | async)
               }"
               #addresstext1
        >
        <ng-container *ngIf="!(this.isMobile$ | async) && (this.navigationMode$ | async) !== this.navigationSteps.notNavigating ">
            <button mat-button class="clean-search"
                        (click)="this.cleanNavigation()">
                     נקה ניווט
            </button>
        </ng-container>
    </div>
</ng-container>
<ng-template #overlay>
    <input id="input-field-search" class="input"
           type="text"
           placeholder="פריסת דפי׳ס בעיר שלך..."
           [(ngModel)]="autocompleteInput"
           #addresstext2
    >
</ng-template>

