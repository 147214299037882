import { Action } from '@ngrx/store';
import {Message} from '../../../messages/message.model';
import {ErrorCallback, LocationCallback} from '../../../types/callbacks';

export enum ActionTypes {
  FETCH_LOCATION = '[Location] Fetch Location',
  WATCH_LOCATION = '[Location] Watch Location',
  STOP_WATCH_LOCATION = '[Location] Stop Watch Location',
  SET_CURRENT_LOCATION = '[Location] Set Current Location',
  ADD_LOCATION_WATCHER = '[Location] Add Location Watcher',
  CLEAR_LOCATION_WATCHERS = '[Location] Clear Location Watchers',
  LOCATION_SUCCESS = '[Location] Location Success',
  LOCATION_FAILURE = '[Location] Location Failure',
  CLEAR_LOCATION = '[Location] Clear Location',
  WATCH_COMPASS = '[Location] Watch Compass',
  WATCH_COMPASS_IOS_13 = '[Location] Watch Compass iOS 13',
  STOP_WATCH_COMPASS = '[Location] Stop Watch Compass',
  SET_CURRENT_COMPASS = '[Location] Set Current Compass',
  DUMMY = '[Location] Dummy'
}

export class FetchLocation implements Action {
  readonly type = ActionTypes.FETCH_LOCATION;
  constructor(public payload: { success?: LocationCallback, error?: ErrorCallback }) {}
}

export class WatchLocation implements Action {
  readonly type = ActionTypes.WATCH_LOCATION;
  constructor(public payload: { success?: LocationCallback, error?: ErrorCallback }) {}
}

export class StopWatchLocation implements Action {
  readonly type = ActionTypes.STOP_WATCH_LOCATION;
}

export class SetCurrentLocation implements Action {
  readonly type = ActionTypes.SET_CURRENT_LOCATION;
  constructor(public payload: { location: { latitude: number; longitude: number; } }) {}
}

export class AddLocationWatcher implements Action {
  readonly type = ActionTypes.ADD_LOCATION_WATCHER;
  constructor(public payload: { locationWatcherId: number}) {}
}

export class ClearLocationWatcher implements Action {
  readonly type = ActionTypes.CLEAR_LOCATION_WATCHERS;
}

export class LocationSuccess implements Action {
  readonly type = ActionTypes.LOCATION_SUCCESS;
  constructor(public payload: { message: Message }) {}
}

export class LocationFailure implements Action {
  readonly type = ActionTypes.LOCATION_FAILURE;
  constructor(public payload: { message: Message }) {}
}

export class ClearLocation implements Action {
  readonly type = ActionTypes.CLEAR_LOCATION;
}

export class Dummy implements Action {
  readonly type = ActionTypes.DUMMY;
}

export class WatchCompass implements Action {
  readonly type = ActionTypes.WATCH_COMPASS;
}

export class WatchCompassIOS13 implements Action {
  readonly type = ActionTypes.WATCH_COMPASS_IOS_13;
}

export class SetCurrentCompass implements Action {
  readonly type = ActionTypes.SET_CURRENT_COMPASS;
  constructor(public payload: { compass: number }) {}
}

export type LocationActions =
    | FetchLocation
    | WatchLocation
    | StopWatchLocation
    | SetCurrentLocation
    | AddLocationWatcher
    | ClearLocationWatcher
    | LocationSuccess
    | LocationFailure
    | ClearLocation
    | WatchCompass
    | WatchCompassIOS13
    | SetCurrentCompass
    | Dummy;
