import {HttpEvent, HttpEventType, HttpHeaderResponse, HttpParams, HttpResponse} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {Observable, of, throwError} from 'rxjs';
import {catchError, concatMap, map, switchMap, take, takeUntil, withLatestFrom} from 'rxjs/operators';
import * as fromFirestoreActions from './firestore.actions';
import {FirestoreService} from '../service/firestore.service';
import {
    FirestoreActions,
    ActionTypes,
    FetchDefis, FirestoreSuccess, SetDefis, FirestoreFailure
} from './firestore.actions';
import {Message} from '../../../messages/message.model';
import {MessageType} from '../../../messages/message-type.enum';
import * as fromAppState from '../../../store/app.state';
import * as fromFirebaseState from './firestore.state';
import {FETCH_DEFIS_FAILURE_MESSAGE} from '../consts/firestore.consts';
import {GeoQueryDocument} from 'geofirex';
import {Defi} from '../interfaces/firestore.interfaces';
import {SetMapBounds, SetSelectedDefi} from '../../../modules/map/store/map.actions';
import {FetchGoogleNavigationRoute, FetchNavigationRoute} from '../../navigation/store/navigation.actions';
import {selectMapLocation} from '../../../modules/map/store/map.selectors';
import {LngLatBounds} from 'mapbox-gl';
import {selectNavigationStep} from '../../navigation/store/navigation.selectors';

@Injectable()
export class FirestoreEffects {

    @Effect()
    fetchDefis = this.actions$.pipe(
        ofType(ActionTypes.FETCH_DEFIS),
        withLatestFrom(this.store.select(selectMapLocation)),
        switchMap(async ([action, mapLocationFromStore]) => {
            const radius = action.payload.params.radius || 5;
            console.log('!!fetch defis in firestore effects');
            return (await this.firestoreService.getDefisInRadius(
                radius,
                action.payload.params.position?.latitude || mapLocationFromStore?.latitude,
                action.payload.params.position?.longitude || mapLocationFromStore?.longitude
            )).pipe(take(1)).subscribe( defis => {
                if (defis) {
                    return of(new SetDefis({defis: defis as Defi[]}));
                } else {
                    return throwError(FETCH_DEFIS_FAILURE_MESSAGE);
                }
            });
        })
    );

    @Effect()
    fetchNearestDefi = this.actions$.pipe(
        ofType(ActionTypes.FETCH_NEAREST_DEFI),
        switchMap(action => {
            console.log('!!fetch nearest defi in firestore effects');
            return (this.firestoreService.getDefisInRadius(
                5,
                action.payload.params.position?.latitude,
                action.payload.params.position?.longitude
            )).pipe(
                switchMap((defis: Defi[]) => {
                    if (defis && defis.length > 0) {
                        console.log(defis);
                        const bounds = new LngLatBounds(
                            {
                                lat: action.payload.params.position?.latitude,
                                lng: action.payload.params.position?.longitude
                            },
                            {
                                lat: (defis[0] as Defi)?.coordinates?.geopoint.latitude,
                                lng: (defis[0] as Defi)?.coordinates?.geopoint.longitude
                            });
                        return [
                            new SetSelectedDefi({ defi: defis[0] as Defi}),
                            new FetchGoogleNavigationRoute({
                                origin: {
                                    longitude: action.payload.params.position?.longitude,
                                    latitude: action.payload.params.position?.latitude
                                },
                                destination: (defis[0] as Defi)?.coordinates?.geopoint
                            }),
                            new FetchNavigationRoute({
                                origin: {
                                    longitude: action.payload.params.position?.longitude,
                                    latitude: action.payload.params.position?.latitude
                                },
                                destination: (defis[0] as Defi)?.coordinates?.geopoint
                            }),
                            new SetMapBounds({ bounds })
                    ];
                    } else {
                        return throwError(FETCH_DEFIS_FAILURE_MESSAGE);
                    }
                }),
                catchError(errorRes => {
                    let errorMessage = FETCH_DEFIS_FAILURE_MESSAGE;
                    if (errorRes.error && errorRes.error.error && errorRes.error.error.message) {
                        errorMessage = errorRes.error.error.message;
                    }
                    return of(new FirestoreSuccess({
                        message: new Message(ActionTypes.FETCH_DEFIS, MessageType.ERROR)
                    }));
                })
            );
        })
    );


    constructor(
    private store: Store<fromAppState.AppState>,
    private firebaseStore: Store<fromFirebaseState.FirestoreState>,
    private firestoreService: FirestoreService,
    private actions$: Actions<fromFirestoreActions.FirestoreActions>
  ) {}

}
