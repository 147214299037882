import { LocationActions, ActionTypes } from './location.actions';
import { initialState, LocationState } from './location.state';
import {MessageType} from '../../../messages/message-type.enum';
import {Message} from '../../../messages/message.model';

export function locationReducer(state = initialState, action: LocationActions): LocationState {
  switch (action.type) {
    case ActionTypes.FETCH_LOCATION: {
      return {
        ...state,
        error: null,
        success: null,
        loading: false
      };
    }
    case ActionTypes.WATCH_LOCATION: {
      return {
        ...state,
        error: null,
        success: null,
        loading: false
      };
    }
    case ActionTypes.STOP_WATCH_LOCATION: {
      return {
        ...state,
        error: null,
        success: null,
        loading: false
      };
    }
    case ActionTypes.SET_CURRENT_LOCATION: {
      return {
        ...state,
        currentLocation: action.payload.location,
        error: null,
        success: new Message(ActionTypes.SET_CURRENT_LOCATION, MessageType.SUCCESS),
        loading: false
      };
    }
    case ActionTypes.ADD_LOCATION_WATCHER: {
      return {
        ...state,
        locationWatchers: Array.from(new Set([...state.locationWatchers, action.payload.locationWatcherId])),
        error: null,
        success: new Message(ActionTypes.ADD_LOCATION_WATCHER, MessageType.SUCCESS),
        loading: false
      };
    }
    case ActionTypes.CLEAR_LOCATION_WATCHERS: {
      return {
        ...state,
        locationWatchers: [],
        error: null,
        success: new Message(ActionTypes.CLEAR_LOCATION_WATCHERS, MessageType.SUCCESS),
        loading: false
      };
    }
    case ActionTypes.SET_CURRENT_COMPASS: {
      return {
        ...state,
        currentCompass: action.payload.compass,
        error: null,
        success: new Message(ActionTypes.SET_CURRENT_LOCATION, MessageType.SUCCESS),
        loading: false
      };
    }
    case ActionTypes.LOCATION_SUCCESS: {
      return {
        ...state,
        error: null,
        success: action.payload.message,
        loading: false
      };
    }
    case ActionTypes.LOCATION_FAILURE: {
      return {
        ...state,
        error: action.payload.message,
        success: null,
        loading: false
      };
    }
    case ActionTypes.CLEAR_LOCATION: {
      return {
        ...state,
        currentLocation: null,
        locationWatchers: [],
        error: null,
        success: null,
        loading: false
      };
    }
    default: {
      return state;
    }
  }
}
