import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector
} from '@ngrx/store';
import {NavigationState, NavigationStep, NavigationType} from './navigation.state';
import {Message} from '../../../messages/message.model';
import {NavigationRoute} from '../interfaces/navigation.interfaces';
import {GoogleNavigationRoute} from '../interfaces/google-route.interfaces';

const getNavigationRoute = (state: NavigationState): NavigationRoute => state.navigationRoute;

const getGoogleNavigationRoute = (state: NavigationState): GoogleNavigationRoute => state.googleNavigationRoute;

const getNavigationType = (state: NavigationState): NavigationType => state.navigationType;

const getNavigationStep = (state: NavigationState): NavigationStep => state.navigationStep;

const getError = (state: NavigationState): Message => state.error;

const getSuccess = (state: NavigationState): Message => state.success;

const getLoading = (state: NavigationState): boolean => state.loading;

// selectors
export const selectNavigationState: MemoizedSelector<
  object,
  NavigationState
> = createFeatureSelector<NavigationState>('navigation');

export const selectNavigationRoute: MemoizedSelector<
    object,
    NavigationRoute
    > = createSelector(
    selectNavigationState,
    getNavigationRoute
);

export const selectGoogleNavigationRoute: MemoizedSelector<
    object,
    GoogleNavigationRoute
    > = createSelector(
    selectNavigationState,
    getGoogleNavigationRoute
);

export const selectNavigationType: MemoizedSelector<
    object,
    NavigationType
    > = createSelector(
    selectNavigationState,
    getNavigationType
);

export const selectNavigationStep: MemoizedSelector<
    object,
    NavigationStep
    > = createSelector(
    selectNavigationState,
    getNavigationStep
);

export const selectError: MemoizedSelector<
    object,
    Message
    > = createSelector(
    selectNavigationState,
    getError
);

export const selectSuccess: MemoizedSelector<
    object,
    Message
    > = createSelector(
    selectNavigationState,
    getSuccess
);

export const selectLoading: MemoizedSelector<
    object,
    boolean
    > = createSelector(
    selectNavigationState,
    getLoading
);

