import {Injectable} from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {MapOverlayComponent} from '../components/map-overlay/map-overlay-desktop/map-overlay.component';
import {Store} from '@ngrx/store';
import {takeLast} from 'rxjs/operators';
import {MapOverlayMobileComponent} from '../components/map-overlay/map-overlay-mobile/map-overlay-mobile.component';
import {SetIsOverlayOpen} from '../store/map.actions';
import {Subscription} from 'rxjs';
import {selectIsOverlayOpen} from '../store/map.selectors';

@Injectable({
    providedIn: 'root'
})
export class MapOverlayService<T= any>{
    // destroy observables
    openSubscription: Subscription;
    overlayRef: OverlayRef;
    constructor(private overlay: Overlay,
                private store: Store) { }

    close(data?: T) {
        this._close();
    }

    private _close() {
        if (this.overlayRef) {
            this.overlayRef.dispose();
        }
        this.openSubscription?.unsubscribe();
    }

    open(overlayConfig: OverlayConfig, isMobile: boolean) {
        // Returns an OverlayRef (which is a PortalHost)
        this.overlayRef = this.overlay.create(overlayConfig);
        // Create ComponentPortal that can be attached to a PortalHost
        let mapOverlayPortal;

        if (isMobile){
            mapOverlayPortal = new ComponentPortal(MapOverlayMobileComponent);
        }else{
            mapOverlayPortal = new ComponentPortal(MapOverlayComponent);
        }
        // Attach ComponentPortal to PortalHost
        const componentRef = this.overlayRef.attach(mapOverlayPortal);
        this.store.dispatch(new SetIsOverlayOpen({isOverlayOpen: true}));
        // this.openSubscription = this.overlayStore.pipe(
        //     select(fromOverlaySelectors.selectClosedData)).subscribe(closedData => {
        //         if (closedData.type === 'search' && closedData.searchTerm){
        //             this._close();
        //         }
        // });
    }

    isOverlayOpen(){
        return this.store.select(selectIsOverlayOpen).pipe(takeLast(1));
    }
}
