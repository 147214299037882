import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {environment} from '../../../../environments/environment';
import {NavigationRoute, NavigationWaypoint} from '../interfaces/navigation.interfaces';
import {Observable} from 'rxjs';
import {GoogleGeocodedWaypoint, GoogleNavigationRoute} from '../interfaces/google-route.interfaces';
import {GooglePlaceResponse, GooglePlaceResult} from '../interfaces/google-places.interfaces';
import PlaceDetailsRequest = google.maps.places.PlaceDetailsRequest;

declare const google: any;

@Injectable({
    providedIn: 'root'
})
export class NavigationService {


    googleKey = 'AIzaSyAAf-1c5jU-RHt-oi8d8zeF3a64fkTsYJs';
    directionsService = new google.maps.DirectionsService();
    placesService = new google.maps.places.PlacesService(new google.maps.Map(document.createElement('div')));

    constructor(private httpClient: HttpClient) {}

    getPlaceByPlaceId(placeId: string): Observable<GooglePlaceResult> {
        const request: PlaceDetailsRequest = {
            placeId
        };
        return new Observable(subscriber => {
            this.placesService.getDetails(request, (response, status) => {
                subscriber.next(response);
            });
        });
    }

    getRoute(origin: { latitude: number; longitude: number; },
             destination: { latitude: number; longitude: number; },
             type: string = 'walking'): Observable<{
        routes?: NavigationRoute[];
        waypoints?: NavigationWaypoint[];
    }> {
        return this.httpClient.get<{
            routes?: NavigationRoute[];
            waypoints?: NavigationWaypoint[];
        }>(`https://api.mapbox.com/directions/v5/mapbox/${type}/${origin.longitude},${origin.latitude};${destination.longitude},${destination.latitude}?access_token=${environment.mapbox.accessToken}&geometries=geojson&language=he&overview=full&steps=true`
        );
    }

    getGoogleRouteHttp(origin: { latitude: number; longitude: number; },
                       destination: { latitude: number; longitude: number; },
                       type: string = 'WALKING'): Observable<{
        geocoded_waypoints?: GoogleGeocodedWaypoint[];
        routes?: GoogleNavigationRoute[];
    }> {
        return this.httpClient.get(
            `https://maps.googleapis.com/maps/api/directions/json?origin=${origin.latitude},${origin.longitude}&destination=${destination.latitude},${destination.longitude}&travelMode=${type}&language=he&key=${this.googleKey}`
        );
    }

    getGoogleRoute(origin: { latitude: number; longitude: number; },
                   destination: { latitude: number; longitude: number; },
                   type: string = 'WALKING'): Observable<{
        geocoded_waypoints?: GoogleGeocodedWaypoint[];
        routes?: GoogleNavigationRoute[];
    }> {
        const request = {
            origin: new google.maps.LatLng(origin?.latitude, origin?.longitude),
            destination: new google.maps.LatLng(destination?.latitude, destination?.longitude),
            travelMode: type
        };
        return new Observable(subscriber => {
            this.directionsService.route(request, (response, status) => {
                subscriber.next(response);
            });
        });
    }

}
