import { Action } from '@ngrx/store';
import {Message} from '../../../messages/message.model';
import {Defi, FetchDefisParams, FetchNearestDefiParams} from '../interfaces/firestore.interfaces';

export enum ActionTypes {
  FETCH_DEFIS = '[Firestore] Fetch Defis',
  SET_DEFIS = '[Firestore] Set Defis',
  FETCH_NEAREST_DEFI = '[Firestore] Fetch Nearest Defi',
  FIRESTORE_SUCCESS = '[Firestore] Firestore Success',
  FIRESTORE_FAILURE = '[Firestore] Firestore Failure',
  CLEAR_FIRESTOE = '[Firestore] Clear Firestore',
  DUMMY = '[Firestore] Dummy'
}

export class FetchDefis implements Action {
  readonly type = ActionTypes.FETCH_DEFIS;
  constructor(public payload: { params: FetchDefisParams }) {}
}

export class SetDefis implements Action {
  readonly type = ActionTypes.SET_DEFIS;
  constructor(public payload: { defis: Defi[]}) {}
}

export class FetchNearestDefi implements Action {
  readonly type = ActionTypes.FETCH_NEAREST_DEFI;
  constructor(public payload: { params: FetchNearestDefiParams }) {}
}

export class FirestoreSuccess implements Action {
  readonly type = ActionTypes.FIRESTORE_SUCCESS;
  constructor(public payload: { message: Message }) {}
}

export class FirestoreFailure implements Action {
  readonly type = ActionTypes.FIRESTORE_FAILURE;
  constructor(public payload: { message: Message }) {}
}

export class ClearFirestore implements Action {
  readonly type = ActionTypes.CLEAR_FIRESTOE;
}

export class Dummy implements Action {
  readonly type = ActionTypes.DUMMY;
}


export type FirestoreActions =
  | FetchDefis
  | SetDefis
  | FetchNearestDefi
  | FirestoreSuccess
  | FirestoreFailure
  | ClearFirestore
  | Dummy;
