import { Action } from '@ngrx/store';
import {Message} from '../../../messages/message.model';
import {Defi} from '../../../core/firestore/interfaces/firestore.interfaces';
import {LngLatBounds} from 'mapbox-gl';
import {MapStyle} from './map.state';

// map
export enum ActionTypes {
  SET_MAP_STYLE = '[Map] Set Map Style',
  SET_MAP_BOUNDS = '[Map] Set Map Bounds',
  SET_MAP_PITCH = '[Map] Set Map Pitch',
  SET_MAP_BEARING = '[Map] Set Map Bearing',
  SET_MAP_ZOOM = '[Map] Set Map Zoom',
  SET_MAP_PAN = '[Map] Set Map Pan',
  SET_IS_SIDENAV_OPEN = '[Map] Set Is Sidenav Open',
  SET_IS_OVERLAY_OPEN = '[Map] Set Is Overlay Open',
  SET_CENTRALIZE_CURRENT_LOCATION = '[Map] Centralize Current Location',
  SET_IS_MOBILE = '[Map] Set Is Mobile',
  SET_SELECTED_DEFI = '[Map] Set Selected Defi',
  SET_MAP_LOCATION = '[Map] Set Map Location',
  MAP_FAILURE = '[Map] Map Failure',
  MAP_SUCCESS = '[Map] Map Success',
  MAP_CLEAN = '[Map] Map Clean',
  MAP_DUMMY = '[Map] Map Dummy'
}

export class SetMapStyle implements Action {
  readonly type = ActionTypes.SET_MAP_STYLE;

  constructor(public payload: {style: MapStyle}) {}
}

export class SetMapBounds implements Action {
  readonly type = ActionTypes.SET_MAP_BOUNDS;

  constructor(public payload: {bounds: LngLatBounds}) {}
}

export class SetMapPitch implements Action {
  readonly type = ActionTypes.SET_MAP_PITCH;

  constructor(public payload: {pitch: number}) {}
}

export class SetMapBearing implements Action {
  readonly type = ActionTypes.SET_MAP_BEARING;

  constructor(public payload: {bearing: number}) {}
}

export class SetMapZoom implements Action {
  readonly type = ActionTypes.SET_MAP_ZOOM;

  constructor(public payload: {zoom: number}) {}
}

export class SetMapPan implements Action {
  readonly type = ActionTypes.SET_MAP_PAN;

  constructor(public payload: {pan: number[]}) {}
}

export class SetSelectedDefi implements Action {
  readonly type = ActionTypes.SET_SELECTED_DEFI;

  constructor(public payload: {defi: Defi}) {}
}

export class SetIsSidenavOpen implements Action {
  readonly type = ActionTypes.SET_IS_SIDENAV_OPEN;

  constructor(public payload: {isSidenavOpen: boolean}) {}
}

export class SetCentralizeCurrentLocation implements Action {
  readonly type = ActionTypes.SET_CENTRALIZE_CURRENT_LOCATION;

  constructor(public payload: {centralizeCurrentLocation: boolean}) {}
}

export class SetIsOverlayOpen implements Action {
  readonly type = ActionTypes.SET_IS_OVERLAY_OPEN;

  constructor(public payload: {isOverlayOpen: boolean}) {}
}

export class SetIsMobile implements Action {
  readonly type = ActionTypes.SET_IS_MOBILE;

  constructor(public payload: {isMobile: boolean}) {}
}

export class SetMapLocation implements Action {
  readonly type = ActionTypes.SET_MAP_LOCATION;

  constructor(public payload: {location: {
      latitude: number,
      longitude: number
    }}) {}
}


export class MapFailure implements Action {
  readonly type = ActionTypes.MAP_FAILURE;

  constructor(public payload: {errorMessage: Message}) {}
}

export class MapSuccess implements Action {
  readonly type = ActionTypes.MAP_SUCCESS;

  constructor(public payload: {successMessage: Message}) {}
}

export class MapClean implements Action {
  readonly type = ActionTypes.MAP_CLEAN;
}

// dummy
export class MapDummy implements Action {
  readonly type = ActionTypes.MAP_DUMMY;
}

export type MapActions =
    | MapFailure
    | MapSuccess
    | MapClean
    | MapDummy
    | SetSelectedDefi
    | SetMapLocation
    | SetMapBounds
    | SetMapStyle
    | SetMapPitch
    | SetMapBearing
    | SetMapZoom
    | SetMapPan
    | SetIsMobile
    | SetIsOverlayOpen
    | SetCentralizeCurrentLocation
    | SetIsSidenavOpen;
