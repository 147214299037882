import { MatTooltip } from '@angular/material/tooltip';
import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2, TemplateRef, ViewChild} from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {map, shareReplay, take} from 'rxjs/operators';
import {MatDrawer, MatSidenav, MatSidenavContent} from '@angular/material/sidenav';
import {MatToolbar} from '@angular/material/toolbar';
import {Store} from '@ngrx/store';
import {SetIsOverlayOpen, SetIsSidenavOpen} from '../../modules/map/store/map.actions';
import {selectIsMobile, selectMapLocation} from '../../modules/map/store/map.selectors';
import {NavigationEnd, Router} from '@angular/router';
import { FirestoreService } from 'src/app/core/firestore/service/firestore.service';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: [
    './header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit{
  isSideNavOpen = false;
  @ViewChild('headerToolbar') toolbarElement: MatToolbar;
  @ViewChild('header') header: MatSidenavContent;
  @ViewChild('sidenav') public sidenav: MatSidenav;
  @ViewChild('whatsappTooltip') public whatsappTooltip: MatTooltip;
  @ViewChild('count') count: ElementRef;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
      .pipe(
          map(result => result.matches),
          shareReplay()
      );

  isMobile$: Observable<boolean>;
  isInfoPage$ = new BehaviorSubject<boolean>(false);
  isMapPage$ = new BehaviorSubject<boolean>(false);
  isAboutPage$ = new BehaviorSubject<boolean>(false);
  isGuidePage$ = new BehaviorSubject<boolean>(false);
  mapLocation$: Observable<{
    latitude: number;
    longitude: number;
  }>;
  defiCount$: Subject<number>;

  constructor(
      public router: Router,
      private breakpointObserver: BreakpointObserver,
      private store: Store,
      public firestore: AngularFirestore,
      private renderer: Renderer2
      ) {}

  ngAfterViewInit() {
    setTimeout(() => {this.whatsappTooltip?.show(); }, 2000);
    // setTimeout(() => {this.whatsappTooltip.hide(); }, 10000);

    // defi counter
    this.firestore.firestore.collection('publicDefis').get()
    .then( querySnapshot => {
      console.log(querySnapshot);
      
      const txtx = querySnapshot.size.toLocaleString() + ' דפיברילטורים משותפים במאגר';
      this.renderer.setProperty(this.count.nativeElement, 'innerHTML', txtx);
    });
  }

  ngOnInit(): void {
    this.isMobile$ = this.store.select(selectIsMobile);
    this.store.select(selectMapLocation);

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isInfoPage$.next(this.router.url === '/about' || this.router.url === '/usage');
        this.isAboutPage$.next(this.router.url === '/about');
        this.isGuidePage$.next(this.router.url === '/usage');
        this.isMapPage$.next(this.router.url === '/map');
        if (this.router.url === '/about' || this.router.url === '/usage') {
          this.sidenav?.close();
        }
      }
    });
  }

  closeOverlay(){
    this.store.dispatch(new SetIsOverlayOpen({isOverlayOpen: false}));
  }

  openOverlay(){
    this.store.dispatch(new SetIsOverlayOpen({isOverlayOpen: true}));
  }

  onMenuButtonClicked() {
    this.isSideNavOpen = !this.isSideNavOpen;
    // this.store.dispatch(new SetIsOverlayOpen({ isOverlayOpen: true}));
  }

  sendToWhatsapp(){
    const text = 'whatsapp://send?text=מצא%20את%20הדפיברילטור%20הקרוב%20אליך%3A%0Awww%2Edefi%2Eco%2Eil%2F';
    window.open(text);
    // this.mapLocation$.pipe(take(1))
    //     .subscribe(location => {
    //       if (location) {
    //         let url = 'ניווט אל הדפיברילטור הקרוב ביותר אליך:';
    //         url = url.concat('\n\n').concat('https://med-man.co.il/?lat='
    //             + location?.latitude + 'lng='
    //             + location?.longitude);
    //       }
    //     });
  }

  showOverlay() {
    this.store.dispatch(new SetIsOverlayOpen({
      isOverlayOpen: true
    }));
  }

  isIOS() {
    return ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform)
        || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
  }

  isAndroid() {
    return ['Android'].includes(navigator.platform)
        || navigator.userAgent.includes('Android');
  }

  getAppLinkByPlatform() {
    if (this.isIOS()) {
      return 'https://apps.apple.com/il/app/%D7%90%D7%99%D7%A4%D7%94-%D7%93%D7%A4%D7%99/id1449991175';
    } else {
      return 'https://play.google.com/store/apps/details?id=nhgh.com.medman';
    }
    return null;
  }

  openAppLink() {
    window.open(this.getAppLinkByPlatform(), '_system');
    return false;
  }

  sendEmail() {
    window.open('mailto:info@defi.co.il');
  }

  oldWebsite() {
    window.open('https://defi.co.il/old');
  }

}
