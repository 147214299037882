<div id="whatsapp-floater" *ngIf="!(this.isMobile$ | async) && router.url === '/map'">
    <img src="assets/img/whatsapp.svg" class="button-image-whatsapp" (click)="this.sendToWhatsapp()"
        #whatsappTooltip="matTooltip" matTooltip="שלח לינק לניווט טלפוני בוואטסאפ" matTooltipClass="tooltip-whatsapp"
        [matTooltipPosition]="'right'">
</div>

<mat-sidenav-container class="sidenav-container">
    <!--[ngStyle]="{'max-width.px': (isMobile$ | async) ? 10 : 600}"-->
    <mat-sidenav #sidenav class="sidenav" fixedInViewport="true" position="start" dir="rtl"
        [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'over' : 'side'"
        [opened]="!(isHandset$ | async)"
        [ngClass]="{'hidden': !(isHandset$ | async) || ((isHandset$ | async) && (isInfoPage$ | async))}">
        <mat-nav-list fxLayout="column" class="mat-side-nav-mobile">
            <!--mobile-->
            <div id="mat-sidenav-header" fxFlex fxLayout="column" fxLayoutAlign="center center">
                <img src="assets/img/defiIcon.png" alt="" class="nav-bar-logo__mobile">
                <div class="subtitle">מיפוי דפיברילטורים חברתי</div>
                <div #count class="small-title">מרענן שכבת דפיברטורים...</div>
            </div>
            <mat-list-item class="nav-item-mobile" fxFlex="70px" fxLayout="row" fxLayoutAlign="center center"
                (click)="this.closeOverlay()">
                <a class="side-nav-bar-item__mobile" fxLayout="row" fxLayoutAlign="center center"
                    [routerLinkActive]="['side-nav-bar-item-active']" routerLink="/usage">
                    <img src="assets/img/sidemenu/defiGuide.svg">
                    מדריך דפי
                </a>
            </mat-list-item>
            <!-- <mat-list-item class="nav-item-mobile" (click)="this.closeOverlay()">
                    <img src="assets/img/sidemenu/cprGuide.svg">
                    <a class="side-nav-bar-item__mobile"
                       [routerLinkActive]="['side-nav-bar-item-active']"
                       routerLink="/usage">
                        מדריך החייאה
                    </a>
                </mat-list-item> -->
            <mat-list-item fxFlex="70px" class="nav-item-mobile">
                <a class="side-nav-bar-item__mobile" fxLayout="row" fxLayoutAlign="center center"
                    [routerLinkActive]="['side-nav-bar-item-active']" routerLink="/about">
                    <img src="assets/img/sidemenu/about.svg" (click)="this.closeOverlay()">
                    אודות
                </a>
            </mat-list-item>
            <!-- <mat-list-item fxFlex fxLayout="row" fxLayoutAlign="center center" class="nav-item-mobile" (click)="this.closeOverlay()">
                    <a class="side-nav-bar-item__mobile" fxLayout="row" fxLayoutAlign="center center"
                       href="mailto:info@defi.co.il">
                       <img src="assets/img/sidemenu/contact.svg">
                        צור קשר
                    </a>
                </mat-list-item> -->
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content class="hide-overflow">
        <mat-toolbar id="appToolbar" class="p-0" color="primary" dir="rtl"
            *ngIf="!(isHandset$ | async) || ((isHandset$ | async) && !(isInfoPage$ | async))"
            [ngStyle]="{'height.px': (isMobile$ | async) ? 48 : 60}" [ngClass]="{'padding15': !(isMobile$ | async)}">
            <button id="hamburger" type="button" class="outline-unset" aria-label="Toggle sidenav" mat-icon-button
                (click)="onMenuButtonClicked(); sidenav.toggle()"
                *ngIf="(isHandset$ | async) && !(isInfoPage$ | async)">
                <!--                <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>-->
            </button>
            <img *ngIf="!(isHandset$ | async) || ((isHandset$ | async) && !(isInfoPage$ | async))"
                (click)="showOverlay()" routerLink="/map" src="../../assets/img/defiIcon.png" alt=""
                class="nav-bar-logo">
            <div [ngClass]="{'hidden': !!(isHandset$ | async)}" class="fit-width height-full">
                <span class="height-full" fxLayout="row" fxLayoutAlign="space-between center">
                    <mat-nav-list class="height-full" fxLayout="row">
                        <mat-list-item class="height-full nav-bar-item" [routerLinkActive]="['nav-bar-item-active']"
                            routerLink="/map" (click)="this.closeOverlay()"
                            [ngStyle]="{'margin-right.px': (isMobile$ | async) ? 0 : 30}">
                            <a class="nav-bar-item">
                                מפה
                            </a>
                        </mat-list-item>
                        <mat-list-item class="height-full nav-bar-item" [routerLinkActive]="['nav-bar-item-active']"
                            routerLink="/usage" (click)="this.closeOverlay()">
                            <a class="nav-bar-item">
                                שימוש בדפי
                            </a>
                        </mat-list-item>
                        <mat-list-item class="height-full nav-bar-item" [routerLinkActive]="['nav-bar-item-active']"
                            routerLink="/about" (click)="this.closeOverlay()">
                            <a class="nav-bar-item">
                                אודות
                            </a>
                        </mat-list-item>
                    </mat-nav-list>
                    <mat-nav-list *ngIf="false" fxLayout="row" class="height-full"
                        [routerLinkActive]="['nav-bar-item-active']">
                        <mat-list-item class="height-full bg-color-white bg-color-hover" routerLink="/dogma"
                            (click)="this.closeOverlay()">
                            <a class="nav-bar-item">
                                כניסה
                            </a>
                        </mat-list-item>
                    </mat-nav-list>
                </span>
            </div>
            <div class="fit-width height-full" fxLayoutAlign="center center">
                <span class="spacer"></span>
                <button style="height: 48px; width: 48px;" mat-icon-button [matMenuTriggerFor]="menu"
                    aria-label="Options menu">
                    <mat-icon *ngIf="(isHandset$ | async)">more_horiz</mat-icon>
                    <mat-icon *ngIf="!(isHandset$ | async)">expand_circle_down</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <a href="https://buymeacoffee.com/eifo.defi" target="_blank" rel="noopener noreferrer">
                        <button mat-menu-item style="text-align: center;">
                            <!-- <mat-icon fontSet="material-icons-round">mail</mat-icon> -->
                            <span>🤝 לתמיכה בפרוייקט</span>
                        </button>
                    </a>
                    <a href="https://defi.co.il/terms.html" target="_blank" rel="noopener noreferrer">
                        <button mat-menu-item>
                            <mat-icon fontSet="material-icons-round">gavel</mat-icon>
                            <span>תנאי שימוש</span>
                        </button>
                    </a>
                    <a href="https://defi.co.il/privacy.html" target="_blank" rel="noopener noreferrer">
                        <button mat-menu-item>
                            <mat-icon fontSet="material-icons-round">policy</mat-icon>
                            <span>מדיניות פרטיות</span>
                        </button>
                    </a>
                    <a href="mailto:info@defi.co.il" target="_blank" rel="noopener noreferrer">
                        <button mat-menu-item>
                            <mat-icon fontSet="material-icons-round">mail</mat-icon>
                            <span>צור קשר</span>
                        </button>
                    </a>
                    <!-- <button mat-menu-item (click)="oldWebsite()">
                        <mat-icon fontSet="material-icons-round">history</mat-icon>
                        <span>לאתר הישן</span>
                    </button> -->
                    <button *ngIf="(isHandset$ | async)" mat-menu-item (click)="openAppLink()">
                        <mat-icon fontSet="material-icons-round">get_app</mat-icon>
                        <span>הורד את האפליקציה</span>
                    </button>
                </mat-menu>
            </div>
        </mat-toolbar>
        <ng-content></ng-content>
    </mat-sidenav-content>
</mat-sidenav-container>
