import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-links-fab',
  templateUrl: './links-fab.component.html',
  styleUrls: ['./links-fab.component.scss']
})
export class LinksFabComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  openExternalLink(url) {
    window.open(url, '_system');
    return false;
  }
}
