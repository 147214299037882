<div #DialogWrapper id="shareBS" fxLayoutAlign="center center" (click)="close()">
    <div id="shareDialog" class="milky" fxLayout="column" fxLayoutAlign="start center">
        <div (click)="openWaze()" id="wazeBTN" >
          <a><p>נווט בוויז</p></a>
        </div>
        <div (click)="openGoogleMaps()" id="gglmpBTN">
          <a><p>עבור לגוגל מפות</p></a>
        </div>
        <div (click)="close()" id="cancel">
          <a><p>ביטול</p></a>
        </div>
    </div>
</div>
