import {Message} from '../../../messages/message.model';
import {defaultLocation} from '../consts/location.consts';

export interface LocationState {
  currentLocation: { latitude: number; longitude: number; };
  locationWatchers: number[];
  currentCompass: number;
  error: Message;
  success: Message;
  loading: boolean;
}

export const initialState: LocationState = {
  currentLocation: {
      latitude: defaultLocation.latitude,
      longitude: defaultLocation.longitude,
  },
  currentCompass: null,
  locationWatchers: [],
  error: null,
  success: null,
  loading: false
};
