import {AfterViewInit, Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, Subject} from 'rxjs';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {map, shareReplay, take, takeLast, takeUntil} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {selectIsMobile, selectIsOverlayOpen, selectIsSidenavOpen, selectSelectedDefi} from '../../store/map.selectors';
import {MatDrawer} from '@angular/material/sidenav';
import {Defi} from '../../../../core/firestore/interfaces/firestore.interfaces';
import {OverlayConfig} from '@angular/cdk/overlay/overlay-config';
import {ConnectedPosition, Overlay} from '@angular/cdk/overlay';
import {MapOverlayService} from '../../service/map-overlay.service';
import {NgMapComponent} from '../ng-map/ng-map.component';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {MapDetailsBottomComponent} from '../map-details-bottom/map-details-bottom.component';
import {Router} from '@angular/router';

@Component({
  selector: 'app-map-page',
  templateUrl: './map-page.component.html',
  styleUrls: ['./map-page.component.scss']
})
export class MapPageComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('map') map: NgMapComponent;

  destroy$: Subject<boolean> = new Subject<boolean>();

  isHandset$: Observable<boolean>;
  isMobile$: Observable<boolean> = this.store.select(selectIsMobile);

  @ViewChild('drawer') public drawer: MatDrawer;

  defi$: Observable<Defi>;

  constructor(private breakpointObserver: BreakpointObserver,
              private store: Store,
              private overlayService: MapOverlayService,
              private overlay: Overlay,
              private router: Router,
              private bottomSheet: MatBottomSheet) {

    this.isHandset$ = this.breakpointObserver.observe(Breakpoints.Handset)
        .pipe(
            map(result => result.matches),
            shareReplay()
        );

    this.defi$ = this.store.select(selectSelectedDefi);
  }

  ngOnInit(): void {
    this.store.select(selectIsOverlayOpen).subscribe(isOpen => {
      if (!isOpen){
        this.overlayService.close();
      } else {
        this.router.navigate(['/map']);
        this.store.select(selectIsMobile).pipe(take(1))
            .subscribe(isMobile => {
              const overlayConfig: OverlayConfig = {
                positionStrategy: this.overlay.position()
                    .global()
                    .centerHorizontally()
                    // .centerVertically()
                    .top(isMobile ? '48px' : '60px'),
                width: '100vw',
                height: isMobile ? 'calc(100vh - calc(100vh - 100%) -48px)' : 'calc(100vh - calc(100vh - 100%) -64px)'
              };
              this.overlayService.close();
              if (isMobile) {
                this.overlayService.open(overlayConfig, true);
              } else {
                this.overlayService.open(overlayConfig, false);
              }
            });
      }
    });
  }

  ngOnDestroy() {
    // force unsubscribe
    this.destroy$.next(true);
    // Now let's also unsubscribe from the subject itself:
    this.destroy$.unsubscribe();
  }

  showMapOverlay() {
    const mapElementSize = document.getElementById('mapPage').getBoundingClientRect();
    this.store.select(selectIsMobile).pipe(takeUntil(this.destroy$))
        .subscribe(isMobile => {
          const overlayConfig: OverlayConfig = {
            positionStrategy: this.overlay.position()
                .global()
                .centerHorizontally()
                // .centerVertically()
                .top(isMobile ? '48px' : '60px'),
            width: '100vw',
            height: isMobile ? 'calc(100vh - calc(100vh - 100%) -48px)' : 'calc(100vh - calc(100vh - 100%) -64px)'
          };
          this.overlayService.close();
          if (isMobile) {
            this.overlayService.open(overlayConfig, true);
          } else {
            this.overlayService.open(overlayConfig, false);
          }
        });
  }

  ngAfterViewInit(): void {
    this.showMapOverlay();
    combineLatest(this.store.select(selectIsSidenavOpen), this.store.select(selectIsMobile))
        .pipe(
        takeUntil(this.destroy$)
    ).subscribe(([isSidenavOpen, isMobile]) => {
      if (!isSidenavOpen){
        this.bottomSheet?.dismiss();
        this.drawer?.close();
      }else{
        if (isMobile) {
          this.drawer?.close();
          this.bottomSheet?.open(MapDetailsBottomComponent, {
            disableClose: false,
            hasBackdrop: false
          });
        } else {
          this.bottomSheet?.dismiss();
          this.drawer?.open();
        }
      }
    });
  }

}
