import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MaterialModule} from '../app-material.module';
import {VarDirective} from './ng-var.directive';


@NgModule({
  declarations: [
    VarDirective
  ],
  imports: [
    CommonModule,
    MaterialModule
  ],
  exports: [
    CommonModule,
    MaterialModule,
    VarDirective
  ]
})
export class SharedModule {}
