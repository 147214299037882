<div id="wrapper"fxLayoutAlign="center center" >
  <div id="main-card" class="milky" fxLayout="column" fxLayoutAlign="start center">
    <!-- header-->
    <div id="card-header" fxFlex="40px" fxLayout fxLayoutAlign="start center">
      <div id="title" fxFlex="fxFill">
        <div id="title-text">{{(title$ | async)}}</div>
      </div>
    </div>

    <div id="card">
      <div id="description">{{(descriptionString$ | async)}}</div>
    </div>

    <div id="buttons" fxLayout fxLayoutAlign="end center">
      <button id="btn-cancel" fxFlexOffset="10px" (click)="close()">בטל</button>
      <button id="btn-call" fxFlexOffset="10px" (click)="call()">חיוג</button>
    </div>
  </div>
</div>
