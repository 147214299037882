import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './components/app/app.component';
import { environment } from '../environments/environment';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {StoreRouterConnectingModule} from '@ngrx/router-store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import * as fromApp from './store/app.reducer';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule} from '@angular/common/http';
import { MaterialModule } from './app-material.module';
import {SharedModule} from './shared/shared.module';
import {MapModule} from './modules/map/map.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import {HeaderComponent} from './components/header/header.component';
import {AngularFireModule} from '@angular/fire';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {AngularFireStorageModule} from '@angular/fire/storage';
import {AngularFireDatabaseModule} from '@angular/fire/database';
import {GuideComponent} from './components/guide/guide.component';
import {AboutComponent} from './components/about/about.component';
import {CoreModule} from './core/core.module';
import {SizeDetectorComponent} from './components/size-detector/size-detector.component';
import {ResizeService} from './components/size-detector/resize.service';
import {LinksFabComponent} from './components/links-fab/links-fab.component';
import {CustomReuseStrategy} from './routing/route-reuse-strategy';
import {RouteReuseStrategy} from '@angular/router';
import {ModalModule} from 'ngb-modal';
import {OpenImageComponent} from './components/open-image/open-image.component';
import {OverlayContainer} from '@angular/cdk/overlay';
import {InAppRootOverlayContainer} from './modules/map/components/map-overlay/in-app-overlay-container';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { TermsComponent } from './components/terms/terms.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { CookieService } from 'ngx-cookie-service';
import { APP_INITIALIZER } from '@angular/core';
import { FirestoreService } from './core/firestore/service/firestore.service';
import { MdaDialogComponent } from './components/mda-dialog/mda-dialog.component';
import { registerLocaleData } from '@angular/common';
import localeES from '@angular/common/locales/he';
registerLocaleData(localeES, 'he');

@NgModule({
    declarations: [
        HeaderComponent,
        AppComponent,
        GuideComponent,
        AboutComponent,
        SizeDetectorComponent,
        TermsComponent,
        PrivacyComponent,
        MdaDialogComponent
    ],
    imports: [
        CoreModule,
        MapModule,
        SharedModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FormsModule,
        MaterialModule,
        ReactiveFormsModule,
        HttpClientModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireDatabaseModule,
        AngularFirestoreModule,
        AngularFireStorageModule,
        StoreModule.forRoot(fromApp.appReducer),
        EffectsModule.forRoot([]),
        StoreDevtoolsModule.instrument({logOnly: environment.production}),
        StoreRouterConnectingModule.forRoot(),
        FlexLayoutModule,
        ModalModule,
        NgxGoogleAnalyticsModule.forRoot('G-1BN90R6LY1'),
        NgxGoogleAnalyticsRouterModule
    ],
    providers: [
        ResizeService,
        { provide: LOCALE_ID, useValue: 'he-IL'},
        {provide: RouteReuseStrategy, useClass: CustomReuseStrategy},
        { provide: OverlayContainer, useClass: InAppRootOverlayContainer },
        CookieService,
        FirestoreService,
        {
            provide: APP_INITIALIZER,
            useFactory: (service: FirestoreService) => () => service.init(),
            deps: [FirestoreService],
            multi: true
          }
    ],
    entryComponents: [
        OpenImageComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
