import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Observable, Subject} from 'rxjs';
import {Store} from '@ngrx/store';
import * as fromAppState from '../../../../../store/app.state';
import {SetIsOverlayOpen, SetMapLocation} from '../../../store/map.actions';
import {FetchNearestDefi} from '../../../../../core/firestore/store/firestore.actions';
import {SetNavigationStep} from '../../../../../core/navigation/store/navigation.actions';
import {NavigationStep} from '../../../../../core/navigation/store/navigation.state';
import {selectIsOverlayOpen} from '../../../store/map.selectors';
import {FetchLocation, SetCurrentLocation, WatchCompassIOS13, WatchLocation} from '../../../../../core/location/store/location.actions';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-map-overlay-mobile',
  templateUrl: './map-overlay-mobile.component.html',
  styleUrls: ['./map-overlay-mobile.component.scss']
})
export class MapOverlayMobileComponent implements OnInit, OnDestroy {
  searchTerm = new FormControl('');
  searchTerm$ = new Subject<string>();
  searchResults: Array<any> = [];
  destroy$: Subject<boolean> = new Subject<boolean>();
  isOverlayOpen$: Observable<boolean>;
  googleService: any;
  geocoder: any;

  constructor(private store: Store<fromAppState.AppState>,
              public router: Router) { }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  ngOnInit(): void {
    this.isOverlayOpen$ = this.store.select(selectIsOverlayOpen);
    this.store.dispatch(new SetIsOverlayOpen({
      isOverlayOpen: true
    }));
  }

  close(){
    this.store.dispatch(new SetIsOverlayOpen({
      isOverlayOpen: false
    }));
  }

  navToNearestDefi() {
    if (typeof DeviceOrientationEvent?.requestPermission === 'function' && !navigator.userAgent.match('CriOS')){
      this.store.dispatch(new WatchCompassIOS13());
    }
    this.store.dispatch(new FetchLocation({
      success: (position: { latitude: number; longitude: number; }) => {
        if (typeof DeviceOrientationEvent?.requestPermission === 'function'){
          this.store.dispatch(new WatchCompassIOS13());
        }

        this.store.dispatch(new SetCurrentLocation({
          location: position
        }));
        this.store.dispatch(new SetMapLocation({
          location: position
        }));
        this.store.dispatch(new SetNavigationStep({
          step: NavigationStep.overview
        }));
        this.store.dispatch(new FetchNearestDefi({
          params: { position }
        }));
      },
      error: () => {
        this.store.dispatch(new SetNavigationStep({
          step: NavigationStep.notNavigating
        }));
      }
    }));
    this.close();
  }

  isIOS() {
    return ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform)
        || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
  }

  isAndroid() {
    return ['Android'].includes(navigator.platform)
        || navigator.userAgent.includes('Android');
  }

  getAppLinkByPlatform() {
    if (this.isIOS()) {
      return 'https://apps.apple.com/il/app/%D7%90%D7%99%D7%A4%D7%94-%D7%93%D7%A4%D7%99/id1449991175';
    } else {
      return 'https://play.google.com/store/apps/details?id=nhgh.com.medman';
    }
    return null;
  }

  openAppLink() {
    window.open(this.getAppLinkByPlatform(), '_system');
    return false;
  }

  openExternalLink(url) {
    window.open(url, '_system');
    return false;
  }

}
