import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-open-image',
  templateUrl: './open-image.component.html',
  styleUrls: ['./open-image.component.scss']
})
export class OpenImageComponent implements OnInit {
  url: string;
  mobile: boolean = navigator.userAgent.toLowerCase().indexOf('mobile') > -1;
  iphone: boolean = navigator.userAgent.toLowerCase().indexOf('iphone') > -1;
  android: boolean = navigator.userAgent.toLowerCase().indexOf('android') > -1;
  iphoneFull: boolean = window.screen.height * (window.devicePixelRatio || 1) > 1334 &&
      window.screen.height * (window.devicePixelRatio || 1) !== 2208 &&
      navigator.userAgent.toLowerCase().indexOf('iphone') > -1;

  @ViewChild('ImgWrapper', {static: false}) wrapper;
  constructor(public dialogRef: MatDialogRef<OpenImageComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.url = this.data.url;
    // this.wrapper.nativeElement.addEventListener('touchstart', () => { event.stopPropagation(); });


  }

  close() {
    this.dialogRef.close();
  }

  scroll(e) {
    e.preventDefault();
    e.stopPropagation();

  }

}
