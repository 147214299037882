import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {environment} from '../../../environments/environment';
import * as mapBox from 'mapbox-gl';
import {ResizeService} from '../size-detector/resize.service';
import {SCREEN_SIZE} from '../size-detector/size-detector.component';
import {SetIsMobile} from '../../modules/map/store/map.actions';
import {Observable, Subject} from 'rxjs';
import {selectIsMobile} from '../../modules/map/store/map.selectors';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  destroy$: Subject<boolean> = new Subject<boolean>();
  isMobile$: Observable<boolean>;

  constructor(private resizeService: ResizeService,
              private store: Store) {
    Object.getOwnPropertyDescriptor(mapBox, 'accessToken').set(environment.mapbox.accessToken);
    try {
      mapBox.setRTLTextPlugin('https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.1/mapbox-gl-rtl-text.js', () => {
      });
    } catch (all) {
      // console.log(all);
    }
  }

  ngOnInit(): void {
    this.resizeService.onResize$.pipe(
        takeUntil(this.destroy$)
    ).subscribe(size => {
      if (size === SCREEN_SIZE.XS || size === SCREEN_SIZE.SM){
        this.store.dispatch(new SetIsMobile({isMobile: true}));
      }else{
        this.store.dispatch(new SetIsMobile({isMobile: false}));
      }
    });

    this.isMobile$ = this.store.select(selectIsMobile);
    this.consoleDebbuger();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  consoleDebbuger() {
    // // console debbuger
    // const log = document.querySelector('#console');
    // ['log', 'debug', 'info', 'warn', 'error'].forEach(verb => {
    //     // tslint:disable-next-line:no-shadowed-variable
    //     console[verb] = ((method, verb, log) => {
    //         // tslint:disable-next-line:only-arrow-functions
    //         return function() {
    //             method.apply(console, arguments);
    //             let msg = document.createElement('div');
    //             msg.classList.add(verb);
    //             msg.textContent = '> ' + verb + ': ' + Array.prototype.slice.call(arguments).join(' ');
    //             log.appendChild(msg);
    //         };
    //     })(console[verb], verb, log);
    // });
  }

}
