<div id="scroller" [ngClass]="{'desktop-scroller': !(isMobile$ | async)}">
    <div id="wrapper" fxLayout.xs="column">

        <!--logo-->
        <div id="logoContainer" fxLayout.xs="column" fxLayoutAlign="center center" *ngIf="isMobile$ | async">
            <!--close button-->
            <button
                    mat-icon-button
                    id="closeButton"
                    class="mat-elevation-z1"
                    (click)="router.navigate(['/map'])"
                    *ngIf="(isMobile$ | async)">
                <img src="assets/img/close.svg" class="button-image">
            </button>
            <img id="logo" src="assets/img/logo.svg">
        </div>

        <!--subtitle-->
        <div id="subtitleContainer" fxLayout.xs="column">
            <p>״איפה דפי?״ הוא מיזם שהוקם במטרה למפות את פריסת הדפיברילטורים הארצית ולהגדיל את הנגישות שלהם לציבור ולמוקדי
                החירום למען הצלת חיים.</p>
        </div>

        <!--1-->
        <div class="whiteContainer" fxLayout.xs="column">
            <h1><u><b>המצב בישראל כיום</b></u></h1>
            <p>
                <b>במדינת ישראל מדווחים כ-8,000 מקרי מוות בשנה כתוצאה מדום לב. מתוכם כ-70% מתרחשים מחוץ לכותלי בית החולים. זוהי
                    סיבת המוות השניה בשכיחותה במדינה!
                </b><br>
                האפשרות היחידה להשבת קצב לב תקין היא באמצעות מכת חשמל אשר ניתנת ע״י דפיברילטור. AED הוא סוג של דפיברילטור נייד
                המסוגל לנטר פעילות לבבית-חשמלית, לאבחן פרפור חדרים והפרעות קצב נוספות, להמליץ ולתת מכת חשמל למטופל במקרה הצורך.
                ההפעלה מלווה בהנחיות קוליות וחזותיות בעברית, המאפשרות באופן עקרוני שימוש ללא ידע מקדים. בשנת 2014 נחקק ״חוק
                הדפיברילטורים״, החוק מגדיר כי כל בית עסק אשר עוברים בו ביום מעל 500 איש במצטבר, חובה להציב מכשיר AED במתחם בית
                העסק.
            </p>
            <!--external links-->
            <div id="linksContainer" fxLayout.xs="column">
                <div  fxLayout="row" fxLayoutAlign="space-around center">
                    <!--youtube-->
                    <a (click)="openExternalLink('https://www.youtube.com/playlist?list=PLJPG5J7sCo4ZZKqFWOD6_0BXGDVuxLI-x')" class="link">
                        <img class="link-image" src="assets/img/yt.png">
                    </a>

                    <a (click)="openExternalLink('https://play.google.com/store/apps/details?id=nhgh.com.medman')" class="link">
                        <img class="link-image" src="assets/img/googleplay.png">
                    </a>

                    <!--AppStore-->
                    <a (click)="openExternalLink('https://apps.apple.com/il/app/%D7%90%D7%99%D7%A4%D7%94-%D7%93%D7%A4%D7%99/id1449991175')" class="link">
                        <img class="link-image" src="assets/img/appstore.png">
                    </a>

                    <!--mail-->
                    <a class="link" href="mailto:info@defi.co.il">
                        <img class="link-image" src="assets/img/gmail.png">
                    </a>

                    <!--facebook-->
                    <a class="link" (click)="openExternalLink('https://facebook.com/whereisdefi/')" >
                        <img class="link-image" src="assets/img/pneisefer.png">
                    </a>
                </div>
            </div>
        </div>

        <!--2-->
        <div class="greyContainer" fxLayout.xs="column">
            <h1><u><b>הבעיה</b></u></h1>
            <p>
                הזמן עד למתן השוק החשמלי הוא הגורם המכריע. במידה והפרפור מופסק בתוך דקה מרגע ההתמוטטות, שיעור ההחייאות המוצלחות
                הוא כ-90%, והוא יורד בכ-­10% בכל דקה שלאחר מכן. לכן,
                <b>
                    קיימים מעל 5,000 מכשירי דפי בקהילה בפריסה ארצית, אך מנגד, השימושים השנתיים במכשירים אלו ספורים
                    ונדירים בלבד.
                </b>
                למעשה, כיום כלל לא קיים מיפוי של המכשירים בארץ בשום מקום - לא במוסדות המדינה ולא אצל גופי ההצלה, ועל כן, הנגישות
                שלהם מוגבלת מאוד.
            </p>
        </div>

        <!--3-->
        <div class="whiteContainer" fxLayout.xs="column">
            <h1><u><b>חזון ועקרונות יסוד</b></u></h1>
            <p>
                אנו מאמינים כי
                <b>
                    ניתן להקטין את כמות הנפטרים השנתית מאירועי לב בעזרת הנגשה והעלאת המודעות החברתית לקיומם וחשיבותם של מכשירי
                    הדפי.
                </b>
                לשם כך נדרש מיפוי שלהם מצד אחד, והנגשה לציבור בעת חירום מצד שני.
            </p>

            <ul>
                <li>
                    הצלת חיים עומדת לנגד עינינו תמיד, והיא שמנחה את כלל אנשי הצוות שעומלים על פיתוח המיזם
                </li>
                <li>
                    הפרויקט פועל ברוח התנדבותית, ללא מטרות רווח ומציע שירותים חינמיים
                </li>
                <li>
                    אנו אנשים שמאמינים בקוד פתוח, ועל כן, כל השירותים שאנו מציעים פתוחים ומונגשים לכל אדם, גוף ומגזר
                </li>
            </ul>

        </div>

        <!--4-->
        <div class="greyContainer" fxLayout.xs="column">
            <h1><u><b>איסוף המידע ויצירת מיפוי</b></u></h1>
            <p>
                <b>
                    איסוף המידע לטובת יצירת המיפוי מבוצע בשני מישורים במקביל:
                </b>
            </p>

            <ul>
                <li>
                    <b>
                        ״מיקור המונים״ על בסיס חברתי
                    </b>
                </li>
                <li>
                    <b>
                        פלטפורמה לניהול ואחזקה של מכשירי דפיברילטור
                    </b>
                </li>
            </ul>

            <p>
                מיקור ההמונים נאסף ע״י אפליקציית מובייל חינמית בה כל משתמש יכול להוסיף מכשיר דפי חדש למפה ע״י שיתוף מיקום של
                מכשיר שהוא נתקל בו, לצלם אותו ולהשלים פרטים נוספים. פלטופרמת הניהול והאחזקה מיועדת לבעלי עסק המחזיקים מכשירי דפי
                ברשותם, ומאפשרת להם לנהל את הפריסה, הנגישות והכשירות שלהם, ובו בזמן להנגיש את המכשירים לציבור.
            </p>
        </div>

        <!--5-->
        <div class="whiteContainer" fxLayout.xs="column">
            <h1><u><b>הנגשת המידע</b></u></h1>
            <p>
                הנגשת מכשירי דפי היא קריטית על מנת להציל חיים בשעת חירום, ועל כן עליה להיות מכוונת לסגירת מעגל בין המכשיר למטופל
                בדקות בודדות. שיטת ההנגשה מחולקת לשני שלבים:
            </p>

            <ul>
                <li>
                    הנגשת המיפוי לכל צד שלישי, ובפרט לארגוני ההצלה אשר מקבלים את הדיווחים בזמן אמת. במצב זה המוקדן או המוקדנית
                    יוכלו לדעת האם קיים מכשיר דפי באזור האירוע (יש לנו API פתוח, צרו קשר איתנו לפרטים).
                </li>
                <li>
                    חבירה של מכשיר הדפי למטופל ע״י אפליקציית ניווט לדפדפן שנשלחת לעובר אורח בווטסאפ/סמס, או ע״י ״איש הקשר״ של
                    המכשיר.
                </li>
            </ul>
        </div>

        <!--working model scheme-->
        <div class="schemeContainer" fxLayout.xs="column">
            <img id="scheme" src="assets/img/scheme.png"/>
        </div>

        <!--who-->
        <div class="redContainer" fxLayout.xs="column">
            <h1><u><b>מי?</b></u></h1>
            <p style="text-align: center;">איפה דפי? - מיפוי דפיברילטורים חברתי הוקם ב-2015. כל הזכויות שמורות לאריאל חסידים.</p>
            <br>
            <p style="text-align: justify;">
                המיזם הוקם במקור ע״י אריאל חסידים, נעם הדר, רותם טל ואסיל מחאמיד, ארבעה סטודנטים לרפואה באוניברסיטת בן-גוריון, במסגרת תחרות ״חדשנות במגזר הציבורי 2.0״ של גוגל ואוניברסיטת בן-גוריון. המשך פיתוח תחילה במסגרת מלגת מו״פ על שם אביאל רון ז"ל תחת המדען הראשי במרכז למיפוי ישראל בסיועו של זיו לוי, ולאחר מכן תחת קרן קקטוס של אוניבסיטת בן גוריון בסיועם של תומר רחמים ורועי פרץ. המשך פיתוח ואחזקה ע״י אריאל חסידים.
            </p>
        </div>

        <!--collaborations-->
        <div [ngClass]="{
        'coGrid': !(isMobile$ | async),
        'coGridMobile': (isMobile$ | async)
        }" fxLayout.xs="column">

            <!--bgu-->
            <div class="coImg">
                <img src="assets/img/bgu.png">
            </div>

            <!--mapi-->
            <div class="coImg">
                <img src="assets/img/mapi.png">
            </div>

            <!--google-->
            <div class="coImg">
                <img src="assets/img/ggl.svg">
            </div>

            <!--ihud-->
            <div class="coImg">
                <img src="assets/img/ihud.png">
            </div>

            <!--cactus-->
            <div class="coImg">
                <img src="assets/img/cactus.png">
            </div>

            <!--mda-->
            <div class="coImg">
                <img src="assets/img/mda.png">
            </div>

            <!--bar-->
            <div class="coImg">
                <img src="assets/img/bar.png">
            </div>
            <!--shaag-->
            <div class="coImg">
                <img src="assets/img/shaag.png">
            </div>

            <!--amitai-->
            <div  class="coImg">
                <img src="assets/img/amitai.jpeg">
            </div>

            <!--easy-->
            <div class="coImg">
                <img src="assets/img/easy.png">
            </div>

            <!--hadar-->
            <div  class="coImg">
                <img src="assets/img/hadar.jpeg">
            </div>

            <!--medicguide-->
            <div class="coImg">
                <img src="assets/img/medicguide.jpg">
            </div>

            <!--angels-->
            <div class="coImg">
                <img src="assets/img/angels.png">
            </div>

            <!--elisha-->
            <div class="coImg">
                <img src="assets/img/elisha.jpeg">
            </div>

            <!--mamram-->
            <div class="coImg">
                <img src="assets/img/mamram.png">
            </div>
            <!--dakar-->
            <div class="coImg" >
                <img src="assets/img/dakar.png">
            </div>

        </div>
        <div class="whiteContainer" fxLayout.xs="column">
            <p>
                פרוייקט ״איפה דפי?״ הוא מיזם ללא מטרות רווח. כל שיתופי הפעולה המוצגים סובבים סביב מטרה אחת של הצלת חיים ע״י העלאת מודעות ושיפור הנגשתם של מכשירי הדפיברילטור המוצבים במרחב הציבורי. למיזם ״איפה דפי?״ אין זיקה מסחרית למשווקי מכשירי דפיברילטור או AED ואין באמור המלצה על מכשיר או משווק מסוג אחד או אחר.
            </p>
        </div>

        <div id="googleRights">
            <p dir="ltr">©2018 Google LLC All rights reserved. Google and the Google logo are registered trademarks of Google LLC.</p>
        </div>
    </div>
</div>
<!--<img id="close-img" src="assets/img/close.svg" alt="" (click)="close()" [class.top30]="this.globals.iphone && !this.globals.iphoneFull" [class.top76]="this.globals.iphoneFull">-->
