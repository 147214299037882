import { FirestoreActions, ActionTypes } from './firestore.actions';
import { initialState, FirestoreState } from './firestore.state';

export function firestoreReducer(state = initialState, action: FirestoreActions): FirestoreState {
  switch (action.type) {
    case ActionTypes.FETCH_DEFIS: {
      return {
        ...state,
        error: null,
        success: null,
        loading: false
      };
    }
    case ActionTypes.SET_DEFIS: {
      return {
        ...state,
        defis: action.payload.defis,
        error: null,
        success: null,
        loading: false
      };
    }
    case ActionTypes.FIRESTORE_SUCCESS: {
      return {
        ...state,
        error: null,
        success: action.payload.message,
        loading: false
      };
    }
    case ActionTypes.FIRESTORE_FAILURE: {
      return {
        ...state,
        error: action.payload.message,
        success: null,
        loading: false
      };
    }
    case ActionTypes.CLEAR_FIRESTOE: {
      return {
        ...state,
        defis: [],
        error: null,
        success: null,
        loading: false
      };
    }
    default: {
      return state;
    }
  }
}
