import { Action } from '@ngrx/store';
import {Message} from '../../../messages/message.model';
import {NavigationRoute} from '../interfaces/navigation.interfaces';
import {NavigationStep, NavigationType} from './navigation.state';
import {GoogleNavigationRoute} from '../interfaces/google-route.interfaces';

export enum ActionTypes {
  FETCH_NAVIGATION_ROUTE = '[Navigation] Fetch Navigation Route',
  SET_NAVIGATION_ROUTE = '[Navigation] Set Navigation Route',
  FETCH_GOOGLE_NAVIGATION_ROUTE = '[Navigation] Fetch Google Navigation Route',
  SET_GOOGLE_NAVIGATION_ROUTE = '[Navigation] Set Google Navigation Route',
  SET_NAVIGATION_TYPE = '[Navigation] Set Navigation Type',
  SET_NAVIGATION_STEP = '[Navigation] Set Navigation Step',
  NAVIGATION_SUCCESS = '[Navigation] Navigation Success',
  NAVIGATION_FAILURE = '[Navigation] Navigation Failure',
  CLEAR_NAVIGATION = '[Navigation] Clear Navigation',
  DUMMY = '[Navigation] Dummy'
}

export class FetchNavigationRoute implements Action {
  readonly type = ActionTypes.FETCH_NAVIGATION_ROUTE;
  constructor(public payload: {
    origin: { latitude: number; longitude: number; },
    destination: { latitude: number; longitude: number; },
    type?: NavigationType
  }) {}
}

export class SetNavigationRoute implements Action {
  readonly type = ActionTypes.SET_NAVIGATION_ROUTE;
  constructor(public payload: { route: NavigationRoute }) {}
}

export class FetchGoogleNavigationRoute implements Action {
  readonly type = ActionTypes.FETCH_GOOGLE_NAVIGATION_ROUTE;
  constructor(public payload: {
    origin: { latitude: number; longitude: number; },
    destination: { latitude: number; longitude: number; },
    type?: NavigationType
  }) {}
}

export class SetGoogleNavigationRoute implements Action {
  readonly type = ActionTypes.SET_GOOGLE_NAVIGATION_ROUTE;
  constructor(public payload: { route: GoogleNavigationRoute }) {}
}

export class SetNavigationType implements Action {
  readonly type = ActionTypes.SET_NAVIGATION_TYPE;
  constructor(public payload: { type: NavigationType }) {}
}

export class SetNavigationStep implements Action {
  readonly type = ActionTypes.SET_NAVIGATION_STEP;
  constructor(public payload: { step: NavigationStep }) {}
}

export class NavigationSuccess implements Action {
  readonly type = ActionTypes.NAVIGATION_SUCCESS;
  constructor(public payload: { message: Message }) {}
}

export class NavigationFailure implements Action {
  readonly type = ActionTypes.NAVIGATION_FAILURE;
  constructor(public payload: { message: Message }) {}
}

export class ClearNavigation implements Action {
  readonly type = ActionTypes.CLEAR_NAVIGATION;
}

export class Dummy implements Action {
  readonly type = ActionTypes.DUMMY;
}


export type NavigationActions =
    | FetchNavigationRoute
    | SetNavigationRoute
    | FetchGoogleNavigationRoute
    | SetGoogleNavigationRoute
    | SetNavigationType
    | SetNavigationStep
    | NavigationSuccess
    | NavigationFailure
    | ClearNavigation
    | Dummy;
