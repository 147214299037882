import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {Store} from '@ngrx/store';
import * as fromNavigationActions from './navigation.actions';
import * as fromAppState from '../../../store/app.state';
import {NavigationService} from '../service/navigation.service';
import {switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {Message} from '../../../messages/message.model';
import {MessageType} from '../../../messages/message-type.enum';
import {
    ActionTypes, FetchGoogleNavigationRoute,
    FetchNavigationRoute,
    NavigationFailure,
    NavigationSuccess,
    SetGoogleNavigationRoute,
    SetNavigationRoute
} from './navigation.actions';
import {selectNavigationStep, selectNavigationType} from './navigation.selectors';
import {NavigationStep, NavigationType} from './navigation.state';
import {selectMapLocation, selectSelectedDefi} from '../../../modules/map/store/map.selectors';
import {LngLat, LngLatBounds, PointLike} from 'mapbox-gl';
import {SetMapBearing, SetMapBounds, SetMapLocation, SetMapPan, SetMapPitch, SetMapZoom} from '../../../modules/map/store/map.actions';
import {FetchNearestDefi} from '../../firestore/store/firestore.actions';
import {WatchLocation} from '../../location/store/location.actions';
import {selectCurrentLocation} from '../../location/store/location.selectors';

@Injectable()
export class NavigationEffects {

    @Effect()
    fetchNavigationRoute = this.actions$.pipe(
        ofType(ActionTypes.FETCH_NAVIGATION_ROUTE),
        withLatestFrom(this.store.select(selectNavigationType)),
        switchMap(([action, navigationTypeFromStore]) => {
            const navigationType = (!!action.payload.type && action.payload.type === NavigationType.driving)
            || (!!navigationTypeFromStore && navigationTypeFromStore === NavigationType.driving) ? 'driving' : 'walking';
            return this.navigationService.getRoute(action.payload.origin, action.payload.destination, navigationType).pipe(
                switchMap( response => {
                    if (response?.routes && response?.routes[0]) {
                        return [
                            new SetNavigationRoute({ route: response?.routes[0] }),
                            new NavigationSuccess({ message: new Message(ActionTypes.FETCH_NAVIGATION_ROUTE, MessageType.SUCCESS) })
                        ];
                    } else {
                        return [
                            new NavigationFailure({ message: new Message(ActionTypes.FETCH_NAVIGATION_ROUTE, MessageType.ERROR) })
                        ];
                    }
                })
            );
        })
    );

    @Effect()
    fetchGoogleNavigationRoute = this.actions$.pipe(
        ofType(ActionTypes.FETCH_GOOGLE_NAVIGATION_ROUTE),
        withLatestFrom(this.store.select(selectNavigationType)),
        switchMap(([action, navigationTypeFromStore]) => {
            const navigationType = (!!action.payload.type && action.payload.type === NavigationType.driving)
            || (!!navigationTypeFromStore && navigationTypeFromStore === NavigationType.driving) ? 'DRIVING' : 'WALKING';
            return this.navigationService.getGoogleRoute(action.payload.origin, action.payload.destination, navigationType).pipe(
                switchMap( response => {
                    if (response?.routes && response?.routes[0]) {
                        const bounds = new LngLatBounds(
                        {
                                lng: action.payload.origin?.longitude,
                                lat: action.payload.origin?.latitude,
                            },
                        {
                                lng: action.payload.destination?.longitude,
                                lat: action.payload.destination?.latitude,
                            }
                        );
                        return [
                        new SetGoogleNavigationRoute({ route: response?.routes[0] }),
                        new NavigationSuccess({ message: new Message(ActionTypes.FETCH_GOOGLE_NAVIGATION_ROUTE, MessageType.SUCCESS) }),
                        new SetMapBounds({bounds})
                        ];
                    } else {
                        return [
                            new NavigationFailure({ message: new Message(ActionTypes.FETCH_GOOGLE_NAVIGATION_ROUTE, MessageType.ERROR) })
                        ];
                    }
                })
            );
        })
    );

    @Effect({dispatch: false})
    setNavigationType = this.actions$.pipe(
        ofType(ActionTypes.SET_NAVIGATION_TYPE),
        withLatestFrom(this.store.select(selectNavigationStep), this.store.select(selectMapLocation),
            this.store.select(selectSelectedDefi)),
        tap(([action, navigationStepFromStore, mapLocationFromStore, selectedDefiFromStore]) => {
            if (!!navigationStepFromStore && navigationStepFromStore !== NavigationStep.notNavigating
                && !!mapLocationFromStore && !!selectedDefiFromStore) {
                // this.store.dispatch(new FetchNavigationRoute({
                //     origin: mapLocationFromStore,
                //     destination: selectedDefiFromStore?.coordinates?.geopoint,
                //     type: action.payload.type
                // }));
                this.store.dispatch(new FetchGoogleNavigationRoute({
                    origin: mapLocationFromStore,
                    destination: selectedDefiFromStore?.coordinates?.geopoint,
                    type: action.payload.type
                }));
                this.store.dispatch(new FetchNavigationRoute({
                    origin: mapLocationFromStore,
                    destination: selectedDefiFromStore?.coordinates?.geopoint,
                    type: action.payload.type
                }));
            }
        })
    );

    @Effect({dispatch: false})
    setNavigationRoute = this.actions$.pipe(
        ofType(ActionTypes.SET_NAVIGATION_ROUTE),
        withLatestFrom(this.store.select(selectMapLocation), this.store.select(selectSelectedDefi)),
        tap(([action, mapLocationFromStore, selectedDefiFromStore]) => {
            if (!!action.payload?.route && !!mapLocationFromStore && !!selectedDefiFromStore) {
                // set bounds
                const coordinates = [
                    [mapLocationFromStore?.longitude, mapLocationFromStore?.latitude],
                    ...action.payload?.route?.geometry?.coordinates,
                    [selectedDefiFromStore?.coordinates?.geopoint?.longitude, selectedDefiFromStore?.coordinates?.geopoint?.latitude]
                ];
                const coordinatesLngLat = coordinates.map( coords => new LngLat(coords[0], coords[1]));
                const mapBounds = coordinatesLngLat.reduce((boundsToReduce, coordinatesToReduce) => {
                    return boundsToReduce.extend(coordinatesToReduce);
                }, new LngLatBounds(coordinatesLngLat[0], coordinatesLngLat[0]));
                this.store.dispatch(new SetMapBounds({ bounds: mapBounds}));
            }
        })
    );

    @Effect({dispatch: false})
    setNavigationStep = this.actions$.pipe(
        ofType(ActionTypes.SET_NAVIGATION_STEP),
        withLatestFrom(this.store.select(selectCurrentLocation)),
        tap(([action, currentLocationFromStore]) => {
            if (!!action?.payload?.step && action?.payload?.step === NavigationStep.navigation) {
                this.store.dispatch(new SetMapLocation({ location: {
                        latitude: currentLocationFromStore?.latitude + 0.0000001,
                        longitude: currentLocationFromStore?.longitude + 0.0000001
                } }));
                this.store.dispatch(new SetMapPitch({ pitch: 60 }));
                // this.store.dispatch(new SetMapBearing({bearing: 0}));
                // this.store.dispatch(new FetchNearestDefi({ params: { position: mapLocationFromStore } }));
            } else {
                // this.store.dispatch(new SetMapPitch({ pitch: 0 }));
                // this.store.dispatch(new SetMapZoom({ zoom: 15 }));
                // this.store.dispatch(new SetMapBearing({bearing: 0}));
                // console.log('step: not navigating');
            }
        })
    );

    constructor(
    private store: Store<fromAppState.AppState>,
    private navigationService: NavigationService,
    private actions$: Actions<fromNavigationActions.NavigationActions>
  ) {}

}
