import { Injectable } from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import { Store} from '@ngrx/store';
import * as fromAppState from '../../../store/app.state';
import {MapService} from '../service/map.service';
import {MapActions, ActionTypes, SetMapPitch, SetMapZoom, SetIsSidenavOpen} from './map.actions';
import {tap, withLatestFrom} from 'rxjs/operators';
import {FetchDefis, FetchNearestDefi} from '../../../core/firestore/store/firestore.actions';


@Injectable()
export class MapEffects {

    @Effect({dispatch: false})
    setMapLocation = this.actions$.pipe(
        ofType(ActionTypes.SET_MAP_LOCATION),
        tap(action => {
            if (action?.payload?.location) {
                // this.store.dispatch(new FetchDefis({ params: {
                //         position: action?.payload?.location
                //     }}));
                // this.store.dispatch(new FetchNearestDefi({ params: {
                //         position: action?.payload?.location
                //     }}));
            }
        })
    );

    constructor(
        private actions$: Actions<MapActions>,
        private store: Store<fromAppState.AppState>,
        private mapService: MapService
    ) {}

}
