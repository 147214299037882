<div class="defi-details-sidenav">
<!--     *ngVar="{ defi: defi$ | async, currentLocation: currentLocation$ | async} as vars"-->

    <!--        Sidenav toggle      -->
    <div id="sideTab-wrapper">
        <button id="sideTab" class="animate" type="button" (click)="this.toggleSidenav()"></button>
    </div>

    <div class="defi-image-container">
        <button *ngIf="defi$ | async" class="defi-details-share-button mat-elevation-z3"
                type="button" aria-label="Share" mat-icon-button (click)="exportNav()">
            <img
                    src="assets/img/share-icon.svg"
                    class="button-image">
        </button>
        <div *ngIf="(defi$ | async)?.imageURL && (defi$ | async)?.imageURL!==''">

            <a (click)="this.openImage()">
                <img class="defi-image" [src]="(defi$ | async)?.imageURL"
                 ></a><br>
        </div>
        <!-- <img *ngIf="!((defi$ | async)?.imageURL) || (defi$ | async)?.imageURL===''" src="assets/img/mask-img.svg"
             class="defi-image"> -->
    </div>

    <div class="defi-title-container" fxLayoutAlign="center center">
        <img src="assets/img/red-marker.svg"
             class="defi-title-icon">
        <div class="defi-title">
            {{(defi$ | async)?.locationName || ""}}
        </div>
    </div>

    <div class="defi-details-container">
        <div class="defi-details-entry" *ngIf="!!(defi$ | async)?.locationDescription && (defi$ | async)?.locationDescription != null">
            <div class="defi-details-title-text">תיאור מילולי של המיקום:</div>
            <div class="defi-details-info-text">{{(defi$ | async)?.locationDescription || ""}}</div>
        </div>
        <div class="defi-details-entry" *ngIf="!!(isAddress$ | async)">
            <div class="defi-details-title-text" >כתובת:</div>
            <div class="defi-details-info-text">{{(addressStr$ | async) || ""}}</div>
            <div class="defi-details-info-text" *ngIf="!!(floorStr$ | async) && (floorStr$ | async) !== null">קומה: {{(floorStr$ | async) || ""}}</div>
        </div>
        <div class="defi-details-entry" *ngIf="!!(contactStr$ | async) && (contactStr$ | async) != '' ">
            <div class="defi-details-title-text">איש קשר:</div>
            <div id="contact" class="defi-details-info-text">
                <a [href]="'tel:' + (defi$ | async)?.contactPhone">{{(contactStr$ | async) || ""}}</a>
            </div>
        </div>
        <div class="defi-details-entry" *ngIf="!!(locationHoursStr$ | async) && (locationHoursStr$ | async) != ''">
            <div class="defi-details-title-text">שעות פתיחת המתחם:</div>
            <div class="defi-details-info-text">{{(locationHoursStr$ | async) || ""}}</div>
        </div>
        <div class="title-text" 
        *ngIf="!!(defi$ | async)?.locationName && (defi$ | async)?.locationName != null"
            fxFlexAlign="start center">{{(idShorten$ | async) || ""}}   -   {{(defi$ | async)?.updatedAt?.seconds * 1000 | date : 'd.M.yy' }}
        </div>
    </div>

    <div class="defi-title-container" *ngIf="!!(googleNavigationRoute$ | async)" fxLayoutAlign="center center">
        <div class="defi-title">
            תיאור המסלול
        </div>
    </div>

    <div class="defi-directions-container" *ngIf="!!(googleNavigationRoute$ | async)">
        <div class="defi-source-container">
            <img src="assets/img/marker-icon-a.svg"
                 class="defi-source-title-icon">
            <div class="defi-source-title">
                {{(googleNavigationRoute$ | async)?.legs[0]?.start_address}}
            </div>
        </div>

        <div class="defi-directions-steps-container">
            <div class="defi-direction-step" *ngFor="let step of (this.googleNavigationSteps$ | async); let lastItem = last; let i = index;">
                <div class="defi-direction-step-text">{{i}}. </div>
                <div class="defi-direction-step-text" [innerHTML]="step?.instructions || step?.html_instructions"></div>
                <hr style="margin: 0px;" *ngIf="!lastItem"/>
            </div>
        </div>

        <div class="defi-destination-container">
            <img src="assets/img/marker-icon-b.svg"
                 class="defi-destination-title-icon">
            <div class="defi-destination-title">
                {{(googleNavigationRoute$ | async)?.legs[0]?.end_address}}
            </div>
        </div>
    </div>

</div>
