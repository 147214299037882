/// <reference types='@types/googlemaps'/>
import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromAppState from '../../store/app.state';
import * as fromLocationSelectors from '../../core/location/store/location.selectors';
import {Observable, Subject} from 'rxjs';
declare const google: any;
import PlaceResult = google.maps.places.PlaceResult;
import { StopWatchLocation} from '../../core/location/store/location.actions';
import {SetIsOverlayOpen, SetIsSidenavOpen, SetMapBounds, SetMapLocation, SetSelectedDefi} from '../../modules/map/store/map.actions';
import {
    SetGoogleNavigationRoute,
    SetNavigationRoute,
    SetNavigationStep,
    SetNavigationType
} from '../../core/navigation/store/navigation.actions';
import {NavigationStep, NavigationType} from '../../core/navigation/store/navigation.state';
import {selectIsMobile, selectMapLocation} from '../../modules/map/store/map.selectors';
import * as fromNavigationSelectors from '../../core/navigation/store/navigation.selectors';
import {take, takeUntil} from 'rxjs/operators';
import {NavigationService} from '../../core/navigation/service/navigation.service';
import AutocompletePrediction = google.maps.places.AutocompletePrediction;
import {FetchDefis, FetchNearestDefi} from '../../core/firestore/store/firestore.actions';
import {LngLatBounds} from 'mapbox-gl';

@Component({
    selector: 'app-google-places',
    templateUrl: './google-places.component.html',
    styleUrls: ['./google-places.component.scss']
})
export class GooglePlacesComponent implements OnInit, OnDestroy, AfterViewInit {

    @Input() addressType = false;
    @Input() isOverlay = false;
    @Output() setAddress: EventEmitter<any> = new EventEmitter();
    @ViewChild('addresstext1') addresstext1: any;
    @ViewChild('addresstext2') addresstext2: any;

    destroy$: Subject<boolean> = new Subject<boolean>();
    googleMapListener: any;
    navigationSteps = NavigationStep;
    navigationMode$: Observable<string>;
    currentLocation$: Observable<{ latitude: number; longitude: number; }>;
    isMobile$: Observable<boolean>;

    autocompleteInput: string;
    queryWait: boolean;

    constructor(
        private navigationService: NavigationService,
        private store: Store<fromAppState.AppState>)
    {
    }

    ngOnInit() {
        this.currentLocation$ = this.store.select(fromLocationSelectors.selectCurrentLocation);
        this.navigationMode$ = this.store.select(fromNavigationSelectors.selectNavigationStep);
        this.isMobile$ = this.store.select(selectIsMobile);
    }

    ngAfterViewInit() {
        this.getPlaceAutocomplete();
    }

    private getPlaceAutocomplete() {
        console.log(this.addressType);
        let autocomplete = null;
        if (this.isOverlay) {
            autocomplete = new google.maps.places.Autocomplete(this.addresstext2.nativeElement, this.addressType ?
                {
                    componentRestrictions: { country: 'IL' },
                    types: [this.addressType]  // 'establishment' / 'address' / 'geocode'
                } :
                {
                    componentRestrictions: { country: 'IL' },
                    types: []  // 'establishment' / 'address' / 'geocode'
                });
        } else {
            autocomplete = new google.maps.places.Autocomplete(this.addresstext1.nativeElement, this.addressType ?
                {
                    componentRestrictions: { country: 'IL' },
                    types: [this.addressType]  // 'establishment' / 'address' / 'geocode'
                } :
                {
                    componentRestrictions: { country: 'IL' },
                    types: []  // 'establishment' / 'address' / 'geocode'
                });
        }
        // var autocomplete = new google.maps.places.Autocomplete(input, {types: ['geocode']});
        // var autocompleteLsr = google.maps.event.addListener(autocomplete, 'place_changed', function() { ... });
        this.googleMapListener = google.maps.event.addListener(autocomplete, 'place_changed', () => {
            const place = autocomplete.getPlace();
            if (place && place.address_components) {
                this.invokeEvent({
                    latitude: place?.geometry?.viewport?.getCenter().lat(),
                    longitude: place?.geometry?.viewport?.getCenter().lng()
                });
            } else {
                const service = new google.maps.places.AutocompleteService();
                service.getPlacePredictions(this.addressType ?
                    {
                        componentRestrictions: { country: 'IL' },
                        // 'establishment' / 'address' / 'geocode'
                        types: [this.addressType],
                        input: this.autocompleteInput
                    } :
                    {
                        componentRestrictions: { country: 'IL' },
                        // 'establishment' / 'address' / 'geocode'
                        types: [],
                        input: this.autocompleteInput
                    },
                    this.displaySuggestions.bind(this)
                );
            }
        });
    }

    onEnter($event){
        const service = new google.maps.places.AutocompleteService();
        service.getPlacePredictions( this.addressType ?
            {
                componentRestrictions: { country: 'IL' },
                types: [this.addressType],  // 'establishment' / 'address' / 'geocode'
                input: this.autocompleteInput
            } :
            {
                componentRestrictions: { country: 'IL' },
                types: [],  // 'establishment' / 'address' / 'geocode'
                input: this.autocompleteInput
            },
            this.displaySuggestions.bind(this)
        );
    }

    displaySuggestions(predictions, status) {
        if (status === google.maps.places.PlacesServiceStatus.OK && predictions?.length > 0
            && (predictions[0] as AutocompletePrediction)?.place_id) {
            this.navigationService.getPlaceByPlaceId((predictions[0] as AutocompletePrediction)?.place_id)
                .pipe(take(1)).subscribe( googlePlaceResponse => {
                    if (googlePlaceResponse?.geometry?.location) {
                        this.invokeEvent({
                            latitude: googlePlaceResponse?.geometry?.location?.lat(),
                            longitude: googlePlaceResponse?.geometry?.location?.lng()
                        });
                    }
            });
        }
    }


    invokeEvent(place: { latitude: number; longitude: number}) {
        this.store.dispatch(new StopWatchLocation());
        this.store.dispatch(new SetIsOverlayOpen({isOverlayOpen: false}));
        this.store.select(selectIsMobile).pipe(take(1))
            .subscribe( isMobile => {
                if (!!isMobile || (!isMobile && !!this.isOverlay)) {
                    const bounds = new LngLatBounds(
                        {
                            lat: place?.latitude + 0.03,
                            lng: place?.longitude + 0.03
                        },
                        {
                            lat: place?.latitude - 0.03,
                            lng: place?.longitude - 0.03
                        });
                    this.store.dispatch(new SetMapBounds({ bounds }));
                    // this.store.dispatch(new FetchDefis({ params: {
                    //         position: place
                    //     }}));
                } else {
                    this.store.dispatch(new SetMapLocation({
                        location: {
                            latitude: place?.latitude,
                            longitude: place?.longitude
                        }
                    }));
                    this.store.dispatch(new FetchNearestDefi({
                        params: {
                            position: {
                                latitude: place?.latitude,
                                longitude: place?.longitude
                            }
                        }
                    }));
                    this.store.dispatch(new SetNavigationStep({ step: NavigationStep.overview }));
                    this.store.dispatch(new SetIsSidenavOpen({isSidenavOpen: true}));
                }
            });
    }

    selectAddress(place: any){
    }

    cleanNavigation(){
        this.autocompleteInput = '';
        this.store.select(selectMapLocation)
            .pipe(take(1))
            .subscribe(mapLocation => {
                // this.store.dispatch(new StopWatchLocation());
                this.store.dispatch(new SetNavigationStep({ step: NavigationStep.notNavigating }));
                this.store.dispatch(new SetNavigationType({ type: NavigationType.walking }));
                this.store.dispatch(new SetNavigationRoute({ route: null }));
                this.store.dispatch(new SetGoogleNavigationRoute({ route: null }));
                this.store.dispatch(new SetIsSidenavOpen({isSidenavOpen: false}));
            });
    }

    resetMap() {
        this.store.select(selectMapLocation)
            .pipe(take(1))
            .subscribe(mapLocation => {
                // this.store.dispatch(new StopWatchLocation());
                this.store.dispatch(new SetSelectedDefi(({ defi: null })));
                this.store.dispatch(new SetMapBounds({ bounds: null }));
                this.store.dispatch(new SetNavigationStep({ step: NavigationStep.notNavigating }));
                this.store.dispatch(new SetNavigationType({ type: NavigationType.walking }));
                this.store.dispatch(new SetNavigationRoute({ route: null }));
                this.store.dispatch(new SetGoogleNavigationRoute({ route: null }));
                this.store.dispatch(new SetIsSidenavOpen({isSidenavOpen: false}));
            });
    }

    ngOnDestroy() {
        // force unsubscribe
        this.destroy$.next(true);
        // Now let's also unsubscribe from the subject itself:
        this.destroy$.unsubscribe();
        google.maps.event.removeListener(this.googleMapListener);
    }


}
