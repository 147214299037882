import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {of} from 'rxjs';
import {switchMap, tap, withLatestFrom} from 'rxjs/operators';
import * as fromLocationActions from './location.actions';
import {
    ActionTypes,
    AddLocationWatcher,
    ClearLocationWatcher,
    FetchLocation,
    LocationFailure,
    LocationSuccess,
    SetCurrentCompass,
    SetCurrentLocation, StopWatchLocation,
    WatchLocation
} from './location.actions';
import {LocationService} from '../service/location.service';
import {Message} from '../../../messages/message.model';
import {MessageType} from '../../../messages/message-type.enum';
import * as fromAppState from '../../../store/app.state';
import * as fromLocationState from './location.state';
import {WATCH_LOCATION_FAILURE_MESSAGE} from '../consts/location.consts';
import {selectLocationWatchers} from './location.selectors';
import {SetMapBearing} from '../../../modules/map/store/map.actions';
import {selectNavigationStep} from '../../navigation/store/navigation.selectors';
import {NavigationStep} from '../../navigation/store/navigation.state';

@Injectable()
export class LocationEffects {

    @Effect()
    fetchLocation = this.actions$.pipe(
        ofType(ActionTypes.FETCH_LOCATION),
        switchMap(action => {
            this.locationService.getLocation((position: Position) => {
                if (!!position  && !!position?.coords) {
                    this.store.dispatch(new SetCurrentLocation({
                        location: {
                            latitude: position?.coords?.latitude,
                            longitude: position?.coords?.longitude
                        }
                    }));
                    if (!!action?.payload?.success){
                        action?.payload?.success({
                            latitude: position?.coords?.latitude,
                            longitude: position?.coords?.longitude
                        });
                    }
                }
                // this.store.dispatch(new SetMapLocation({
                //     location: {
                //         latitude: position?.coords?.latitude,
                //         longitude: position?.coords?.longitude
                //     }
                // }));
                // this.store.dispatch(new FetchNearestDefi({
                //     params: {
                //         position: {
                //             latitude: position?.coords?.latitude,
                //             longitude: position?.coords?.longitude
                //         }
                //     }
                // }));
            }, (error) => {
                this.locationStore.dispatch(new LocationFailure({
                    message: new Message(ActionTypes.FETCH_LOCATION, MessageType.ERROR, {
                        defaultMessage: error
                    })
                }));
            });
            return of(new LocationSuccess({
                message: new Message(ActionTypes.FETCH_LOCATION, MessageType.SUCCESS)
            }));
        })
    );

    @Effect()
    watchLocation = this.actions$.pipe(
        ofType(ActionTypes.WATCH_LOCATION),
        switchMap((watchLocation: WatchLocation) => {
            const watcherId = this.locationService.watchLocation((position: Position) => {
                this.locationStore.dispatch(new FetchLocation({}));
                if (!!position  && !!position?.coords) {
                    this.locationStore.dispatch(new SetCurrentLocation({
                        location: {
                            latitude: position?.coords?.latitude,
                            longitude: position?.coords?.longitude
                        }
                    }));
                    if (!!watchLocation?.payload?.success){
                        watchLocation?.payload?.success({
                            latitude: position?.coords?.latitude,
                            longitude: position?.coords?.longitude
                        });
                    }
                }
                // this.locationStore.dispatch(new SetMapLocation({
                //     location: {
                //         latitude: position?.coords?.latitude,
                //         longitude: position?.coords?.longitude
                //     }
                // }));
            }, (error) => {
                this.locationStore.dispatch(new LocationFailure({
                    message: new Message(ActionTypes.WATCH_LOCATION, MessageType.ERROR, {
                        defaultMessage: error
                    })
                }));
                this.store.dispatch(new StopWatchLocation());
                if (!!watchLocation?.payload?.error){
                    watchLocation?.payload?.error(null);
                }
            });

            if (watcherId > -1) {
                return [
                    new FetchLocation({}),
                    new AddLocationWatcher({ locationWatcherId: watcherId }),
                    new LocationSuccess({ message: new Message(ActionTypes.WATCH_LOCATION, MessageType.SUCCESS)})
                ];
            } else {
                return of(new LocationFailure({
                    message: new Message(ActionTypes.WATCH_LOCATION, MessageType.ERROR, {
                        defaultMessage: WATCH_LOCATION_FAILURE_MESSAGE
                    })
                }));
            }
        })
    );

    @Effect()
    stopWatchLocation = this.actions$.pipe(
        ofType(ActionTypes.STOP_WATCH_LOCATION),
        withLatestFrom(this.store.select(selectLocationWatchers)),
        switchMap(([action, locationsWatchersFromStore])  => {
            locationsWatchersFromStore?.forEach( watcherId => this.locationService.clearLocationWatcher(watcherId));
            return of(new ClearLocationWatcher());
        })
    );

    @Effect({dispatch: false})
    watchCompass = this.actions$.pipe(
        ofType(ActionTypes.WATCH_COMPASS),
        tap(action => {
            this.locationService.watchCompassHeading(compass => {
                this.locationStore.dispatch(new SetCurrentCompass({
                    compass
                }));
            }, (error) => {
                this.locationStore.dispatch(new LocationFailure({
                    message: new Message(ActionTypes.WATCH_COMPASS, MessageType.ERROR, {
                        defaultMessage: error
                    })
                }));
            });
        })
    );

    @Effect({dispatch: false})
    watchCompassIOS13 = this.actions$.pipe(
        ofType(ActionTypes.WATCH_COMPASS_IOS_13),
        tap(action => {
            this.locationService.compassPremissionIOS13(compass => {
                this.locationStore.dispatch(new SetCurrentCompass({
                    compass
                }));
            }, (error) => {
                this.locationStore.dispatch(new LocationFailure({
                    message: new Message(ActionTypes.WATCH_COMPASS, MessageType.ERROR, {
                        defaultMessage: error
                    })
                }));
            });
        })
    );

    // @Effect({dispatch: false})
    // setCompass = this.actions$.pipe(
    //     ofType(ActionTypes.SET_CURRENT_COMPASS),
    //     withLatestFrom(this.store.select(selectNavigationStep)),
    //     tap(([action, navigationStepFromStore]) => {
    //         if (!!navigationStepFromStore && navigationStepFromStore === NavigationStep.navigation) {
    //             this.store.dispatch(new SetMapBearing({
    //                 bearing: action.payload?.compass || 0
    //             }));
    //         }
    //     })
    // );

    constructor(
    private store: Store<fromAppState.AppState>,
    private locationStore: Store<fromLocationState.LocationState>,
    private locationService: LocationService,
    private actions$: Actions<fromLocationActions.LocationActions>
  ) {}

}
