import {Injectable} from '@angular/core';
import {BehaviorSubject, from, Observable, Subject} from 'rxjs';
import {AngularFirestore} from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import * as geofirex from 'geofirex';
import { get } from 'geofirex';
import {GeoFireClient, GeoQueryDocument} from 'geofirex';
import {first, map, take} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { Defi } from '../interfaces/firestore.interfaces';
import { MatSnackBar } from '@angular/material/snack-bar';
import {AngularFireStorage} from '@angular/fire/storage';
import * as localForage from 'localforage';
import { formatDate } from '@angular/common';
// import {firestore} from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {

  private geo: GeoFireClient;

  defisArrLoaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  defisArr: Defi[];
  defisInRadiosArr$: BehaviorSubject<Defi[]> = new BehaviorSubject([]);
  defisForNav$: BehaviorSubject<Defi[]> = new BehaviorSubject([]);

  constructor(public firestore: AngularFirestore,
              private httpClient: HttpClient,
              private snackBar: MatSnackBar,
              public storage: AngularFireStorage) {
    this.geo = geofirex.init(firebase);
  }

  async init(): Promise<any> {
    try {
      console.log('init defi fetch');
      const localDate = await localForage.getItem('DefisDate');
      const localDefis = await localForage.getItem('DefisCache') as Defi[];
      console.log(localDate);
      if (!localDate || !localDefis) {
        console.log('no defis in local storage');
        console.log('getting defis');
        this.getDefisJSON();
      } else {
        console.log('found local defis');
        console.log(localDefis);
        this.defisArr = localDefis;
        this.defisArrLoaded$.next(true);
        const dbDate = await this.getDefisTimestamp();
        if (dbDate > localDate) {
          console.log('date in DB is later');
          this.getDefisJSON(dbDate);
        }
      }
    } catch (err) {
        // This code runs if there were any errors.
        console.log('error fetching local storage:');
        console.log(err);
        console.log('getting defis');
        this.getDefisJSON();
    }
  }

  getDefisInRadius(radiusInKM: number, lat: number, lng: number) {
    this.getIsDefisArrLoaded$()
    .pipe().subscribe(isLoaded => {
      if (isLoaded) {
        const defisInRadius = [];
        this.defisArr.forEach( defi => {
          // tslint:disable-next-line:max-line-length no-string-literal
          const distance = this.distanceInKmBetweenEarthCoordinates(lat, lng, defi.coordinates.geopoint.latitude, defi.coordinates.geopoint.longitude);
          if (distance < radiusInKM) {
            const obj = {selected: false, hitMetadata: { distance },
              ...defi};
            if (defisInRadius.length > 0 && defisInRadius[0].hitMetadata.distance > distance) {
              defisInRadius.unshift(obj);
            } else {
              defisInRadius.push(obj);
            }
          }
        });
        this.defisForNav$.next(defisInRadius);
        // return defisInRadius;
      }
    });
    return this.defisForNav$.asObservable();
  }

async updateDefisByRadius(lat: number, lng: number, radius?: number) {
  const radiusInKM = radius ? radius : 20;
  await this.getIsDefisArrLoaded$()
    .pipe().subscribe(isLoaded => {
      if (isLoaded) {
        const defisInRadius = [];
        // console.log(this.defisArr);
        this.defisArr.forEach( defi => {
          // tslint:disable-next-line:max-line-length no-string-literal
          const distance = this.distanceInKmBetweenEarthCoordinates(lat, lng, defi.coordinates.geopoint.latitude, defi.coordinates.geopoint.longitude);
          if (distance < radiusInKM) {
            const obj: Defi = {selected: false,
              ...defi};
            defisInRadius.push(obj);
          }
        });
        this.defisInRadiosArr$.next(defisInRadius);
        // return defisInRadius;
      }
  });
}

  downloadDefis() {
    const loadingSnackBar = this.snackBar.open('טוען...');
    this.httpClient.get(
      `https://us-central1-eifo-defi.cloudfunctions.net/api/locations?download=true`
    ).pipe(take(1))
    .subscribe(defis => {
      console.log(defis);
      loadingSnackBar.dismiss();
      const res = [];
      const title = {
        id: 'id',
        index_city: 'index_city',
        location_name: 'location_name',
        location_description: 'location_description',
        coordinates_wgs_lat: 'coordinates_wgs_lat',
        coordinates_wgs_lng: 'coordinates_wgs_lng',
        coordinates_itm_x: 'coordinates_itm_x',
        coordinates_itm_y: 'coordinates_itm_y',
        contact_name: 'contact_name',
        contact_phone: 'contact_phone',
        location_city: 'location_city',
        location_street: 'location_street',
        location_street_num: 'location_street_num',
        location_floor: 'location_floor',
        location_hours: 'location_hours',
        location_open_all_day: 'location_open_all_day',
        updated_at: 'updated_at',
        created_at: 'created_at',
        img_url: 'img_url',
        origin: 'origin'
      };
      res.push(title);
      Object.keys(defis).map((key, index) => {
        const format = 'dd/MM/yyyy HH:mm';
        const locale = 'he-IL';

        const updatedAtForm = formatDate(defis[index].updatedAt.seconds * 1000, format, locale);
        const createdAtForm = formatDate(defis[index].createdAt.seconds * 1000, format, locale);

        const dd = {
          id: defis[index].id,
          index_city: defis[index].indexCity,
          location_name: this.removeBlank(defis[index].locationName),
          location_description: this.removeBlank(defis[index].locationDescription),
          coordinates_wgs_lat: defis[index].coordinates.geopoint.latitude,
          coordinates_wgs_lng: defis[index].coordinates.geopoint.longitude,
          coordinates_itm_x: defis[index].coordinates.itm.x,
          coordinates_itm_y: defis[index].coordinates.itm.y,
          contact_name: this.removeBlank(defis[index].contactName),
          contact_phone: this.removeBlank(defis[index].contactPhone),
          location_city: this.removeBlank(defis[index].locationCity),
          location_street: this.removeBlank(defis[index].locationStreet),
          location_street_num: this.removeBlank(defis[index].locationNumber),
          location_floor: this.removeBlank(defis[index].locationFloor),
          location_hours: this.removeBlank(defis[index].locationOpenHours),
          location_open_all_day: defis[index].locationOpenAllDay,
          updated_at: updatedAtForm,
          created_at: createdAtForm,
          img_url: this.removeBlank(defis[index].imageURL),
          origin: defis[index].origin
        };
        console.log(dd);
        res.push(dd);
      });
      const d = new Date();
      // tslint:disable-next-line:no-unused-expression
      new ngxCsv(res, 'Defis ' + d.getDate() + '-' + (d.getMonth() + 1) + '-' + d.getFullYear(), {showLabels: false });
      this.snackBar.open('התקבל בהצלחה!', 'תודה', {duration: 5000});
    },
    (err) => {
      loadingSnackBar.dismiss();
      this.snackBar.open('נכשל. פרטים: ' + err.statusText, 'אישור', {duration: 5000});
    });
  }

  removeBlank( f ) {
    if (f === undefined || f === null) {
      return '';
    } else {
      return f;
    }
  }

  degreesToRadians(degrees) {
    return degrees * Math.PI / 180;
  }

  distanceInKmBetweenEarthCoordinates(lat1, lon1, lat2, lon2) {
    const earthRadiusKm = 6371;

    const dLat = this.degreesToRadians(lat2 - lat1);
    const dLon = this.degreesToRadians(lon2 - lon1);

    lat1 = this.degreesToRadians(lat1);
    lat2 = this.degreesToRadians(lat2);

    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return earthRadiusKm * c;
  }

  getIsDefisArrLoaded$() {
    return this.defisArrLoaded$.asObservable();
  }

  getDefisInRadiosArr$() {
    return this.defisInRadiosArr$.asObservable();
  }

  getDefisJSON(date?) {
    console.log('getting JSON');
    const fileRef = this.storage.ref('/locations.json');
    fileRef.getDownloadURL()
          .pipe()
          .subscribe(url => {
            this.httpClient.get<[Defi]>(url)
            .subscribe(
              async result => {
                this.defisArr = result;
                this.defisArrLoaded$.next(true);
                localForage.setItem('DefisCache', result, err => {
                  console.log('Setting defis local sotrage error: ');
                  console.log(err);
                });
                if (date) {
                  localForage.setItem('DefisDate', date, err => {
                    console.log('Setting date local sotrage error: ');
                    console.log(err);
                  });
                } else {
                  this.getDefisTimestamp();
                }
              },
              error => {
                console.log(error);
              });
            });
    return;
  }

  getDefisTimestamp() {
    return new Promise<any>((resolve, reject) => {
      this.firestore.doc('meta/locationsUpdate').valueChanges().pipe(take(1)).subscribe(data => {
        // tslint:disable-next-line:no-string-literal
        const lastDate = data['last'];
        localForage.setItem('DefisDate', lastDate, err => {
          if (err) {
            console.log('Setting date local sotrage error: ');
            console.log(err);
            reject();
          }
        });
        console.log('got last timestamp: ' + lastDate);
        resolve(lastDate);
      });
    });
  }
  // getNumberOfDefis() {
  //   this.firestore.firestore.collection('publicDefis').get()
  //   .then( querySnapshot => {
  //     return querySnapshot.size;
  //   });
  // }

}
