import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Defi} from '../../core/firestore/interfaces/firestore.interfaces';
import {DefiLocation} from '../../core/location/interfaces/location.interfaces';
import {selectMapLocation, selectSelectedDefi} from '../../modules/map/store/map.selectors';
import {map, take} from 'rxjs/operators';
import {combineLatest, Observable} from 'rxjs';
import {Store} from '@ngrx/store';


@Component({
  selector: 'app-share-dialog',
  templateUrl: './share-dialog.component.html',
  styleUrls: ['./share-dialog.component.scss']
})
export class ShareDialogComponent implements OnInit {

  wazeLink$: Observable<string>;
  googleMapsLink$: Observable<string>;

  constructor(
      private store: Store,
      public dialogRef: MatDialogRef<ShareDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {

    // links
    this.wazeLink$ = this.store.select(selectSelectedDefi).pipe(
        map( defi => {
          return `https://www.waze.com/ul?ll=${defi?.coordinates.geopoint.latitude},${defi?.coordinates.geopoint.longitude}&navigate=yes&zoom=17`;
        })
    );

    this.googleMapsLink$ = combineLatest(
        [ this.store.select(selectSelectedDefi), this.store.select(selectMapLocation)]
    ).pipe(
        map( ([defi, currentLocation]) => {
          return `https://www.google.com/maps/dir/?api=1&origin=${currentLocation.latitude},${currentLocation.longitude}&destination=${defi?.coordinates.geopoint.latitude},${defi?.coordinates.geopoint.longitude}&travelmode=walking`;
        })
    );

  }

  openExternalLink(url) {
    window.open(url, '_system');
    return false;
  }

  openWaze() {
    this.wazeLink$.pipe(take(1))
        .subscribe(wazeLink => {
          this.openExternalLink(wazeLink);
        });
  }

  openGoogleMaps() {
    this.googleMapsLink$.pipe(take(1))
        .subscribe(googleMapsLink => {
          this.openExternalLink(googleMapsLink);
        });
  }

  close() {
    this.dialogRef.close();
  }

}
