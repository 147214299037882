<div class="map-wrapper">

    <!--    navigation heading-->
    <div
            (click)="toggleSidenav()"
            *ngIf="!!(isMobile$ | async) && !!(selectedDefi$ | async) && !!(googleNavigationRoute$ | async)
             && !!(navigationStep$ | async) && (navigationStep$ | async) === navigationStepAlias.navigation"
            id="nav-heading-wrapper">
        <div id="nav-type" class="mat-elevation-z3" matRipple>
            <div id="defi-name" >{{(selectedDefi$ | async)?.locationName || ''}}</div>
            <div id="nav-time">{{(googleNavigationRouteTimeStr$ | async) || ''}}</div>
        </div>
    </div>

    <!--        Sidenav toggle      -->
    <div id="sideTab-wrapper" *ngIf="!(isSidenavOpen$ | async) && !(this.isMobile$ | async)">
        <button id="sideTab" class="animate" type="button" (click)="this.toggleSidenav()"></button>
    </div>

    <mgl-map
            class="map-outline"
            [pitch]="[(mapPitch$ | async)]"
            [fitBounds]="(mapBounds$ | async)"
            [fitBoundsOptions]="(isMobile$ | async) ? { padding: {top: 200, bottom: 200, left: 120, right: 120} } : 
            (isSidenavOpen$ | async) ? { padding: {top: 200, bottom: 200, left: 200, right: 600} } : { padding: {top: 200, bottom: 200, left: 200, right: 200} }"
            (dragStart)="onDragStart($event)"
            (zoomStart)="onZoomStart($event)"
            (touchMove)="mobileCloseBottomSheet()"
            (mouseDown)="test($event)"
            [style]="(mapStyle$ | async) === mapStyleAlias.satellite ? SATELLITE_MAP_STYLE : REGULAR_MAP_STYLE"
            [zoom]="[(mapZoom$ | async)]"
            [center]="{
                lat: (mapLocation$ | async)?.latitude,
                lng:(mapLocation$ | async)?.longitude
            }"
            [centerWithPanTo]="true"
            (load)="onMapLoad($event)">

        <!--    user location-->
        <mgl-marker
                *ngIf='(isCurrentLocation)'
                [rotationAlignment]="'viewport'"
                [pitchAlignment]="'viewport'"
                [anchor]="'center'"
                [lngLat]="[(currentLocation$ | async)?.longitude, (currentLocation$ | async)?.latitude]">
            <div
                    [ngStyle] ="{ 'transform': 'rotate('+(((navigationStep$ | async) === navigationStepAlias.navigation ? 0 : (currentCompass$ | async) || 0))+'deg)',
             '-webkit-transform': 'rotate('+(((navigationStep$ | async) === navigationStepAlias.navigation ? 0 : (currentCompass$ | async) || 0))+'deg)',
              '-ms-transform': 'rotate('+(((navigationStep$ | async) === navigationStepAlias.navigation ? 0 : (currentCompass$ | async) || 0))+'deg)'}"
                    class="user-location-marker"
                    [ngClass]="{
                    'user-location-marker-with-compass': !!(currentCompass$ | async) && (navigationStep$ | async) !== navigationStepAlias.overview && (navigationStep$ | async) !== navigationStepAlias.navigation,
                    'user-location-marker-no-compass': !(currentCompass$ | async) && !((navigationStep$ | async) === navigationStepAlias.overview && (isMobile$ | async)) && (navigationStep$ | async) !== navigationStepAlias.navigation,
                    'user-location-marker-on-overview': (navigationStep$ | async) === navigationStepAlias.overview && (isMobile$ | async) && !!(currentCompass$ | async),
                    'user-location-marker-no-compass-overview': (navigationStep$ | async) === navigationStepAlias.overview && (isMobile$ | async) && !(currentCompass$ | async),
                    'user-location-marker-on-navigation': (navigationStep$ | async) === navigationStepAlias.navigation
                     }"
            ></div>
        </mgl-marker>

        <!--    map pin marker-->
        <mgl-marker
                *ngIf='(mapLocation$ | async) && !(isMobile$ | async) && (navigationStep$ | async) !== navigationStepAlias.notNavigating'
                draggable="true"
                (dragEnd)="onPinMarkerDrag($event)"
                [anchor]="'bottom'"
                [lngLat]="[(mapLocation$ | async)?.longitude, (mapLocation$ | async)?.latitude]">
            <div
                #tooltip="matTooltip"
                matTooltip="אפשר לגרור אותי"
                matTooltipPosition="above"
                matTooltipHideDelay="600000"
                class="pin-marker"
            ></div>
        </mgl-marker>

        <!--    markers - defis   -->
        <!-- <mgl-marker
                *ngFor="let feature of (defisGEO$ | async).features"
                [feature]="feature">
            <div class="marker" (click)="selectDefi(feature.properties.defi);"
                 [ngClass]="{
                                'marker-icon': !feature.properties.defi.imageURL && !feature.properties.defi.contactPhone,
                                 'marker-icon-phone': !feature.properties.defi.imageURL && !!feature.properties.defi.contactPhone,
                                'marker-icon-photo': !!feature.properties.defi.imageURL && !feature.properties.defi.contactPhone,
                                 'marker-icon-photo-and-phone': !!feature.properties.defi.imageURL && !!feature.properties.defi.contactPhone,
                                'selected-marker-icon': ((selectedDefi$ | async)?.id && (selectedDefi$ | async)?.id === feature.properties.defi?.id) && (!feature.properties.defi.imageURL && !feature.properties.defi.contactPhone),
                                 'selected-marker-icon-phone': ((selectedDefi$ | async)?.id && (selectedDefi$ | async)?.id === feature.properties.defi?.id) && (!feature.properties.defi.imageURL && !!feature.properties.defi.contactPhone),
                                'selected-marker-icon-photo': ((selectedDefi$ | async)?.id && (selectedDefi$ | async)?.id === feature.properties.defi?.id) && (!!feature.properties.defi.imageURL && !feature.properties.defi.contactPhone),
                                 'selected-marker-icon-photo-and-phone': ((selectedDefi$ | async)?.id && (selectedDefi$ | async)?.id === feature.properties.defi?.id) && (!!feature.properties.defi.imageURL && !!feature.properties.defi.contactPhone)
                                }"></div>
            </mgl-marker> -->

        <!--        Sidenav toggle      -->
<!--        <mgl-control *ngIf="!(this.isMobile$ | async)"-->
<!--                position="top-right" class="side-tab-btn">-->
<!--            <button id="sideTab" class="animate" type="button" (click)="this.toggleSidenav()"></button>-->

<!--        </mgl-control>-->

        <!--    google search   -->
        <mgl-control
                *ngIf="!(isMobile$ | async)
                || !!(navigationStep$ | async)
                && (navigationStep$ | async) === navigationStepAlias.notNavigating"
                position="top-right">
            <app-google-places [isOverlay]="false"
            ></app-google-places>
        </mgl-control>

        <!--    close button-->
        <mgl-control
                *ngIf="(isMobile$ | async) && !!(navigationStep$ | async)
                && (navigationStep$ | async) !== navigationStepAlias.notNavigating"
                position="top-right">
            <button mat-icon-button class="mat-elevation-z3" (click)="resetMap()"
                    [ngStyle]="{
                    'margin': ((isMobile$ | async)
                && !!(navigationStep$ | async)
                && (navigationStep$ | async) !== navigationStepAlias.notNavigating) ?
                '50px 10px 0 0!important' : '0px 10px 0 0!important'
                    }">
                <img src="assets/img/close.svg" class="button-image">
            </button>
        </mgl-control>

        <!--    Whatsapp    -->
        <!-- <mgl-control *ngIf="!(this.isMobile$ | async)"
                     position="top-left">
                <img src="assets/img/whatsapp.svg" class="button-image-whatsapp" (click)="whatsappBtn()">
        </mgl-control> -->

        <!--    Change Map Style   -->
        <mgl-control *ngIf="true"
                position="top-left">
            <button mat-icon-button class="mat-elevation-z3"
                    [ngClass]="{'mobile-buttons':
                    (this.isMobile$ | async) && (this.navigationStep$ | async) === navigationStepAlias.notNavigating}"
                    (click)="changeStyle()">
                <img src="assets/img/layers.svg" class="button-image">
            </button>
        </mgl-control>

        <!--    watch location button-->
        <mgl-control *ngIf="true"
                position="top-left">
            <button mat-icon-button class="mat-elevation-z3" (click)="centralizeCurrentLocation()">
                <img *ngIf="(centralizeCurrentLocation$ | async)" src="assets/img/find-self-on.svg" class="button-image">
                <img *ngIf="!(centralizeCurrentLocation$ | async)" src="assets/img/find-self.svg" class="button-image">
            </button>
        </mgl-control>

        <!-- blank placeholder for whatsapp button -->
        <mgl-control *ngIf="!(isMobile$ | async)"
                position="top-left">
            <button  mat-icon-button class="mat-elevation-z3">
            </button>
        </mgl-control>

        <!-- stright map to north -->
        <mgl-control *ngIf="!isMapNorth && (navigationStep$ | async) !== navigationStepAlias.navigation"
            position="top-left">
            <button mat-icon-button class="mat-elevation-z3" (click)="toTheNorth()">
                <img src="assets/img/to-the-north.svg" class="button-image">
            </button>
        </mgl-control>

        <!-- No GPS service icon -->
        <mgl-control *ngIf="(isMobile$ | async) && !(isCurrentLocation)"
            position="top-left">
            <div id="no-gps-icon" class="blink_me" 
            [ngClass]="{'no-gps-NotNavigating': (navigationStep$ | async) === navigationStepAlias.notNavigating}"></div>
        </mgl-control>    

        <!--    navigation nearest defi-->
        <mgl-control
                *ngIf="!!(isMobile$ | async) && (!(navigationStep$ | async) || (navigationStep$ | async) === navigationStepAlias.notNavigating)"
                position="bottom-right">
            <button mat-icon-button id="nav-icon" class="mat-elevation-z3" (click)="navToNearestDefi()"
                    matTooltip="מצא את הדפי הקרוב ביותר"
                    matTooltipPosition="left"
                    matTooltipClass="pxwidth77">
                <img src="assets/img/nav-icon.svg" class="button-image">
            </button>
        </mgl-control>

        <!--    call button    -->
        <mgl-control
                position="bottom-left" *ngIf="!!(selectedDefi$ | async) && !!(isMobile$ | async) && !!(selectedDefi$ | async)?.contactPhone
                 && !!(navigationStep$ | async) && (navigationStep$ | async) === navigationStepAlias.navigation">
                    <img (click)="call()" src="assets/img/call-button.svg" class="button-image mat-elevation-z3 call-btn-m">
        </mgl-control>

        <!-- NEW NAV1 BOTTOM BAR -->
        <mgl-control
            position="bottom-right" *ngIf="!!(navigationStep$ | async) && (navigationStep$ | async) === navigationStepAlias.overview && !!(isMobile$ | async)">
            <app-map-overview-header 
            (callContact)="call()" (detailes)="toggleSidenav()" (startNav)="startNavigation()" 
            [timeStr]="(googleNavigationRouteTimeStr$ | async)"
            [locationName]="(selectedDefi$ | async)?.locationName"
            [contact]="(selectedDefi$ | async)?.contactPhone"
            ></app-map-overview-header>
        </mgl-control>

        <!--    Change Navigation Type   -->
        <mgl-control
                position="bottom-right" *ngIf="!!(navigationStep$ | async) && (navigationStep$ | async) === navigationStepAlias.overview">
            <button *ngIf="(navigationType$ | async) as navigationType" mat-icon-button class="mat-elevation-z3"
                    (click)="changeNavigationType(
                navigationType === navigationTypeAlias.walking ? navigationTypeAlias.driving : navigationTypeAlias.walking)">
                <img
                        [src]="navigationType === navigationTypeAlias.walking ? 'assets/img/drive.svg' : 'assets/img/walking.svg'"
                        class="button-image">
            </button>
        </mgl-control>

        <!--    export button    -->
        <!-- <mgl-control
                position="bottom-right" *ngIf="!!(selectedDefi$ | async) && !!(isMobile$ | async)
                 && !!(navigationStep$ | async) && (navigationStep$ | async) != navigationStepAlias.notNavigating
                 && (navigationStep$ | async) != navigationStepAlias.navigation ">
</!--            <button mat-icon-button class="mat-elevation-z3"--/>
</!--                    matTooltip="נווט עם waze"--/>
</!--                    matTooltipPosition="left"--/>
</!--                    (click)="exportNav()">--/>
                <img src="assets/img/exportNav@3x.png" class="mat-elevation-z3 button-image export-nav"
                     matTooltip="נווט עם waze"
                     matTooltipPosition="left"
                     (click)="exportNav()">
</!--            </button>--/>
        </mgl-control> -->

        <!--    navigation route-->
        <mgl-geojson-source *ngIf="(googleNavigationRoute$ | async)"
                            id="navigationRoute" [data]="(navigationRouteData$ | async)"> </mgl-geojson-source>
        <mgl-layer
                *ngIf="(googleNavigationRoute$ | async)"
                id="navigationRouteLine"
                type="line"
                source="navigationRoute"
                [layout]="{
            'line-join': 'round',
            'line-cap': 'round'}"
                [paint]="{
            'line-color': '#ff685c',
            'line-width': 4}">
        </mgl-layer>

    </mgl-map>

</div>
<app-links-fab *ngIf="!(isMobile$ | async)"></app-links-fab>
<div #license *ngIf="!(isMobile$ | async)" id="licensedUse" fxLayoutAlign="center center" dir="rtl" fxLayoutGap="16px">
    <mat-icon></mat-icon>
        <p>השימוש ב-״איפה דפי?״ מהווה הסכמה לתנאי השימוש</p>
    <button mat-raised-button color="basic" (click)="closeLicense()">סגור</button>
</div>

