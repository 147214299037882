import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {AboutComponent} from './components/about/about.component';
import {GuideComponent} from './components/guide/guide.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { TermsComponent } from './components/terms/terms.component';
import {MapPageComponent} from './modules/map/components/map-page/map-page.component';

@NgModule({
  imports: [
    RouterModule.forRoot([
          { path: '', redirectTo: 'map', pathMatch: 'full' },
          { path: 'map', component: MapPageComponent },
          { path: 'about', component: AboutComponent},
          { path: 'usage', component: GuideComponent},
          { path: 'terms', component: TermsComponent},
          { path: 'privacy', component: PrivacyComponent},
        ],
        {onSameUrlNavigation: 'reload',
          useHash: true}
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
