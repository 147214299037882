<div id="wrapper" fxLayout="column" fxLayoutAlign="center center" >
    <div id="main-card" class="milky" fxLayout="column" fxLayoutAlign="start center">
      <!-- header-->
      <div id="card-header" fxFlex="40px" fxLayout fxLayoutAlign="start center">
        <div id="title" fxFlex="fxFill" fxLayout="row" fxLayoutAlign="start center">
          <img id="ambulight" src="assets/img/ambulight.gif">
          <div id="title-text">מקרה אמת?</div>
        </div>
      </div>
  
      <div id="card">
        <div id="description">יש להתקשר קודם 101</div>
      </div>
  
      <div id="buttons" fxLayout fxLayoutAlign="end center">
        <button id="btn-cancel" matRipple fxFlexOffset="10px" (click)="close()">הבנתי
          <div id="navProgress" #navProgress></div>
        </button>
      </div>
    </div>
  </div>
  